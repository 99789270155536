import PageBox from "../../../../components/box/PageBox";
import RegularContainer from "../../../../components/container/RegularContainer";
import { useSocialContext } from "../../context";
import usePieChart from "../../hooks/usePieChart";
import useSocialMessageAnalysis from "../../hooks/useSocialMessageAnalysis";
import useWordCloud from "../../hooks/useWordCloud";
import ChartContainer from "../chartContainer/ChartContainer";
import ChartsLayout from "../chartsLayout/ChartsLayout";

const SocialMessageAnalysis = () => {
    const { category } = useSocialContext();

    const { selectedPieData, selectedWordData } = useSocialMessageAnalysis({ category });
    const socialPieChart = usePieChart({
        pieData: selectedPieData,
        category,
    });

    const wordCloudChart = useWordCloud({
        wordCloudData: selectedWordData,
        category,
    });

    return (
        <RegularContainer sx={{ mt: 0, pt: 0 }}>
            <PageBox hasChatBox={false}>
                <ChartsLayout>
                    <ChartContainer isBigChart={false} title='ANALYSIS.Proportion_Lang'>
                        <div ref={socialPieChart} style={{ height: "100%", width: "100%" }} />
                    </ChartContainer>
                    <ChartContainer isBigChart={false} title='ANALYSIS.Word_Cloud'>
                        <div ref={wordCloudChart} style={{ height: "100%", width: "100%" }} />
                    </ChartContainer>
                </ChartsLayout>
            </PageBox>
        </RegularContainer>
    );
};
export default SocialMessageAnalysis;
