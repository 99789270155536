import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services";
import type {
    ICategoryReq,
    ICreateOrgCategoryReq,
    ICreateOrgCategoryRes,
    IGetCategoriesRes,
    IGetCategoryReq,
    IGetCategoryRes,
    IUpdateCategoryOrderReq,
    IUpdateCategoryOrderRes,
    IUpdateCategoryReq,
    IUpdateCategoryRes,
} from "../types";

export const categoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query<IGetCategoriesRes, ICategoryReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/org/${reqBody.org_id}/categories`,
                method: "GET",
            }),
            providesTags: ["SalesCategories"],
        }),
        getCategory: builder.query<IGetCategoryRes, IGetCategoryReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/categories/${reqBody.category_id}`,
                method: "GET",
            }),
            providesTags: ["SalesCategory"],
        }),
        deleteCategory: builder.mutation<void, { id: string }>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/categories/${reqBody.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["SalesCategories"],
        }),
        updateCategoryOrder: builder.mutation<IUpdateCategoryOrderRes<null>, IUpdateCategoryOrderReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/org/${reqBody.ord_id}/categories_order`,
                method: "PUT",
                body: { new_order: reqBody.order },
            }),
            invalidatesTags: ["SalesCategories"],
        }),
        updateCategory: builder.mutation<IUpdateCategoryRes<null>, IUpdateCategoryReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/categories/${reqBody.category_id}`,
                method: "PATCH",
                body: { name: reqBody.name, show_type: reqBody.show_type },
            }),
            invalidatesTags: ["SalesCategories", "SalesCategory"],
        }),
        createOrgCategory: builder.mutation<ICreateOrgCategoryRes, ICreateOrgCategoryReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/org/${reqBody.org_id}/categories`,
                method: "POST",
                body: { name: reqBody.name, show_type: reqBody.show_type },
            }),
            invalidatesTags: ["SalesCategories"],
        }),
    }),
});

export const {
    useGetCategoriesQuery,
    useDeleteCategoryMutation,
    useUpdateCategoryOrderMutation,
    useGetCategoryQuery,
    useUpdateCategoryMutation,
    useCreateOrgCategoryMutation,
} = categoryApiSlice;
