import { Check, ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { customToast } from "../notify/NotifyContainer";

interface IProps {
    value: string;
    disabled?: boolean;
}
const ClipboardCopyBtn = ({ value, disabled }: IProps) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleCopyClick = async () => {
        try {
            setIsCopied(true);
            await navigator.clipboard.writeText(value);
            customToast.success(t("MESSAGE.COPY_SUSS"));
        } catch (err) {
            customToast.error(t("MESSAGE.COPY_FAILED"));
        } finally {
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        }
    };
    return (
        <IconButton disabled={disabled} onClick={handleCopyClick} color='primary'>
            {isCopied ? <Check /> : <ContentCopy />}
        </IconButton>
    );
};
export default ClipboardCopyBtn;
