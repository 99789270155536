import React from "react";
import { useNavigate } from "react-router-dom";

import { MButton } from "../../../components/button";

const ToLoginAmazeBtn = () => {
    const navigate = useNavigate();

    const clearAllCookies = () => {
        const cookies = document.cookie.split(";");

        cookies.forEach((cookie) => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

            document.cookie = `${name}=; Max-Age=-99999999; path=/;`;
        });

        // 導向目標頁面
        navigate("/login");
    };

    return (
        <MButton
            btnText='導覽後台'
            color='search'
            onClick={clearAllCookies}
            style={{ padding: "10px", cursor: "pointer", color: "white" }}
        ></MButton>
    );
};

export default ToLoginAmazeBtn;
