import * as echarts from "echarts";
import { useEffect, useRef } from "react";

import { IGaugeProps } from "../types";

// import useEchartResizer from "./useEchartResizer";

const useGaugeChart = ({ usedData, limitData, selectedBarColor, category }: IGaugeProps) => {
    const gaugeChart = useRef(null);
    // useEchartResizer({ ref: gaugeChart });

    const gaugeValue = parseFloat(((usedData / limitData) * 100).toFixed(1));

    useEffect(() => {
        const MCircleProgress = echarts.init(gaugeChart.current);
        const option = {
            tooltip: {
                trigger: "item",
            },

            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },

            series: [
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -268,
                    pointer: {
                        show: false,
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: false,
                        clip: true,
                        itemStyle: {
                            color: selectedBarColor,
                            borderWidth: 4,
                            borderColor: selectedBarColor,
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            width: 30,
                        },
                    },
                    splitLine: {
                        show: false,
                        distance: 2,
                        length: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                        distance: 50,
                    },
                    data: [
                        {
                            value: gaugeValue,
                            name: category,
                            title: {
                                offsetCenter: ["0%", "-25%"],
                            },
                            detail: {
                                valueAnimation: true,
                                offsetCenter: ["0%", "20%"],
                            },
                        },
                    ],
                    title: {
                        fontSize: 30,
                    },
                    detail: {
                        width: 135,
                        height: 100,
                        fontSize: 40,
                        color: selectedBarColor,
                        borderColor: selectedBarColor,
                        borderRadius: 10,
                        borderWidth: 0,
                        formatter: "{value}%",
                    },
                },
            ],
        };
        MCircleProgress.setOption(option);
        return () => {
            MCircleProgress.dispose();
        };
    }, [limitData, category, usedData, selectedBarColor, gaugeValue]);

    return gaugeChart;
};
export default useGaugeChart;
