import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ChatWidgetRoom } from "../../../../components/chatroom";
import { DropdownBox } from "../../../../components/dropDownBox";
import { BouncingDots } from "../../../../components/loader";
import { AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP, AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES } from "../../../../constants";
import { CHAT_WIDGET_VERSION } from "../../../../types/enum";
import { IChatWidgetConfig } from "../../../../utils";
import { useGenerateChatWidgetConfig } from "../../hooks";
import { useGetAllProjectCharactersQuery } from "../../services";
import styles from "./Chatroom.module.scss";

interface IProps {
    chatBoxClass: CSSProperties;
    autoFocus?: boolean;
}

const Chatroom = ({ chatBoxClass, autoFocus = false }: IProps) => {
    const { characterId, projectId } = useParams();
    const allowSelectCharacter = !characterId;

    const { data: projectCharacters } = useGetAllProjectCharactersQuery(
        { page: 1, limit: 100, project_id: projectId as string },
        { skip: !(allowSelectCharacter && projectId) }
    );
    const characterOptions = projectCharacters?.data?.records?.map((record) => ({
        label: record.name,
        value: record.id,
    }));
    // const defaultCharacterId = characterOptions?.[0]?.value || "";
    const [selectedCharacter, setSelectedCharacter] = useState<string>("");
    const selectedCharacterId = allowSelectCharacter ? selectedCharacter : characterId;

    useEffect(() => {
        if (projectCharacters) {
            setSelectedCharacter(projectCharacters?.data?.records?.[0]?.id || "");
        }
    }, [projectCharacters]);

    const widgetConfig = useGenerateChatWidgetConfig({
        characterId: selectedCharacterId,
        customStyleOverrides: AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES,
        selectorId: "chat-widget-mobile",
        baseConfigForApp: AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP,
    });

    if (!selectedCharacterId) {
        return (
            <div className={styles.widgetLoader}>
                <p>Cannot get character id.</p>
            </div>
        );
    }

    return (
        <div className={styles.chatBoxWrapper}>
            {allowSelectCharacter && (
                // <CharacterSelector />
                <DropdownBox
                    category={selectedCharacterId}
                    options={characterOptions || []}
                    onChange={(e) => {
                        setSelectedCharacter(e.target.value);
                    }}
                    dropdownTitle='CH.Selector'
                    classes={{ wrapper: styles.dropdownWrapper, select: styles.dropdownSelect }}
                />
            )}
            {widgetConfig && selectedCharacterId ? (
                <ChatWidgetRoom
                    chatWidgetVersion={CHAT_WIDGET_VERSION.MOBILE}
                    chatWidgetConfig={
                        {
                            ...widgetConfig,
                            base: { ...widgetConfig.base, voice_enabled: true, openVoiceInput: true },
                            ui: { ...widgetConfig.ui, autoFocus: autoFocus },
                        } as IChatWidgetConfig
                    }
                    chatBoxClass={clsx(styles.chatWidgetMobile, chatBoxClass)}
                />
            ) : (
                <div className={clsx(styles.widgetLoader)}>
                    <BouncingDots />
                </div>
            )}
        </div>
    );
};
export default Chatroom;
