import { useParams } from "react-router-dom";

import { isProduction } from "../../../utils";
import { useDateContext } from "../context";
import { useGetMessageCountQuery } from "../services";
import { LOCAL_PROJECT_ID_FOR_STATISTICS, SOCIAL_CATEGORY_TYPE } from "../types";
interface IProps {
    category: string;
}

const useSocialMessageAmount = ({ category }: IProps) => {
    const { date } = useDateContext();
    const yearMonth = date?.format("YYYYMM").valueOf();
    const { projectId } = useParams();
    const currentProjectId = isProduction ? projectId : LOCAL_PROJECT_ID_FOR_STATISTICS;
    const { data } = useGetMessageCountQuery({ date: yearMonth, project_id: currentProjectId });

    const messageData = data?.data;
    const dateData = messageData?.date;
    const mediaData = messageData?.data_list;

    const facebookAmountData = mediaData?.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Facebook)
        ? mediaData.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Facebook)?.value
        : [];
    const lineAmountData = mediaData?.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Line)
        ? mediaData.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Line)?.value
        : [];
    const allAmountData = mediaData?.find((item) => item.name === SOCIAL_CATEGORY_TYPE.All)
        ? mediaData.find((item) => item.name === SOCIAL_CATEGORY_TYPE.All)?.value
        : [];
    const webAmountData = mediaData?.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Web)
        ? mediaData.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Web)?.value
        : [];
    const kioskAmountData = mediaData?.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Kiosk)
        ? mediaData.find((item) => item.name === SOCIAL_CATEGORY_TYPE.Kiosk)?.value
        : [];
    return { facebookAmountData, lineAmountData, allAmountData, webAmountData, kioskAmountData, dateData };
};
export default useSocialMessageAmount;
