import { yupResolver } from "@hookform/resolvers/yup";
import { Done } from "@mui/icons-material";
import { Box, Card, Grid } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MButton } from "../../../../components/button";
import { FormInput } from "../../../../components/form";
import { CATEGORY_ASSETS } from "../../constants";
import { useInitProject } from "../../hooks";
import { IInitProjectInput } from "../../types";
import { projectNameSchema } from "../../validations";
import styles from "./InitProject.module.scss";

interface IProps {
    isInit: boolean;
}

const defaultValue = {
    project_title: "",
};

const InitProject = ({ isInit }: IProps) => {
    const { t } = useTranslation();
    const { currentUserEmail, knowledgesList, handleSelect, selectedK, isLoading, handleSubmit } = useInitProject({
        isInit,
    });

    // ======= form =======
    const methods = useForm<IInitProjectInput>({
        defaultValues: { ...defaultValue },
        resolver: yupResolver(projectNameSchema(isInit)),
        mode: "all",
    });
    const formIsValid = isInit ? selectedK : methods?.formState?.isValid && selectedK;

    const handleFormSubmit: SubmitHandler<IInitProjectInput> = async (data) => await handleSubmit(data);

    return (
        <div>
            <div>
                <div className='headingPrimary--main'>
                    {currentUserEmail} {isInit ? "," + t("COMMON.WELCOME") : ""}！
                    <br /> {isInit ? t("PROJECT.INIT_PROJECT") : t("PROJECT.CREATE_PROJECT")}！
                </div>
            </div>
            {/* knowledgeList */}
            <div className={styles.knowledgesSection}>
                <div className='headingTertiary'>{t("PROJECT.SELECTKNOWLEDGES")} </div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                        {!isInit && (
                            <FormInput
                                sx={{ mt: 2 }}
                                name='project_title'
                                label={t("PROJECT.Create_Project_Label")}
                                variant='standard'
                                fullWidth={false}
                                autoFocus
                            />
                        )}

                        <Grid container spacing={2} mt={1}>
                            {knowledgesList &&
                                knowledgesList.data.records.map((item, index) => {
                                    const idx = index % CATEGORY_ASSETS.length;
                                    return (
                                        <Grid item xs={6} sm={4} key={item.category}>
                                            <Card
                                                className={styles.card}
                                                sx={{
                                                    background: CATEGORY_ASSETS[idx].color,
                                                }}
                                                onClick={() => {
                                                    handleSelect(item);
                                                }}
                                            >
                                                <div className={styles.knowledge}>
                                                    <div className={styles.imgWrapper}>
                                                        <img src={CATEGORY_ASSETS[idx].img} alt='category' />
                                                    </div>
                                                    <div className={styles.info}>
                                                        <div className={styles.text}>{item.category}</div>
                                                    </div>
                                                </div>
                                                {selectedK?.category === item.category && (
                                                    <div className={styles.selectedCard}>
                                                        <Done color='secondary' sx={{ fontSize: "7.6rem" }} />
                                                    </div>
                                                )}
                                            </Card>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                        <Box mt={4}>
                            <MButton
                                loading={isLoading}
                                btnText={t("COMMON.SUBMIT")}
                                sx={{ px: 4 }}
                                disabled={!formIsValid}
                                type='submit'
                            />
                        </Box>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};
export default InitProject;
