import { Backdrop, CircularProgress } from "@mui/material";

import styles from "./PageLoader.module.scss";

interface IProps {
    loading: boolean;
    value?: string;
}
const PageLoader = ({ loading, value }: IProps) => {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            data-testid='loader'
        >
            <div className={styles.loaderBox}>
                <CircularProgress
                    color='inherit'
                    style={{ width: "56px", height: "56px" }}
                    data-testid='circular-progress'
                />
                {value && (
                    <div className={styles.value} data-testid='status'>
                        {value}
                    </div>
                )}
            </div>
        </Backdrop>
    );
};
export default PageLoader;
