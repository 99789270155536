import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { customToast } from "../../../components/notify";
import { useUpdateProjectMutation } from "../services";
import { IProjectInput, IProjectTypes } from "../types";
import { ProjectInfoSchema } from "../validations";

interface IProps {
    projectInfo: Partial<IProjectTypes>;
}
const useEditProjectInfo = ({ projectInfo }: IProps) => {
    const [updateProject, { isLoading, error, isSuccess }] = useUpdateProjectMutation();
    const methods = useForm<IProjectInput>({
        defaultValues: projectInfo,
        resolver: yupResolver(ProjectInfoSchema()),
        mode: "all",
    });

    const handleFormSubmit: SubmitHandler<IProjectInput> = async (data) => {
        await updateProject(data);
    };

    const handleCancel = () => {
        methods.reset();
    };
    const handleEdit = () => {
        methods.handleSubmit(handleFormSubmit)();
    };

    useEffect(() => {
        if (isSuccess) {
            customToast.success("Successfully.");
            methods.reset(methods.getValues());
        }
        if (error) {
            customToast.error("Update project information failed.");
        }
    }, [isSuccess, methods, error]);
    return {
        methods,
        handleEdit,
        handleCancel,
        isLoading,
    };
};
export default useEditProjectInfo;
