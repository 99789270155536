import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { customToast } from "../../../components/notify";
export type PickerDate = Dayjs | null;

export type DateRange = {
    started_at: PickerDate;
    ended_at: PickerDate;
};
export interface IDateProps {
    date: PickerDate;
    setDate: Dispatch<SetStateAction<PickerDate>>;
    dateRange: DateRange;
    handleDateRange: (date: DateRange) => void;
    handleStartDate: (date: PickerDate) => void;
    handleEndDate: (date: PickerDate) => void;
}

const DateContext = createContext({} as IDateProps);

dayjs.extend(utc);

const initialDateRange = {
    started_at: dayjs.utc().startOf("month"),
    ended_at: dayjs.utc(),
};

export const useDateContext = () => useContext(DateContext);

export const DateProvider = ({ children }: { children?: ReactNode }) => {
    const [date, setDate] = useState<PickerDate>(dayjs.utc());
    const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);
    const handleStartDate = (date: PickerDate) => {
        const endedDate = dateRange.ended_at?.unix();
        const startedDate = date?.unix();
        if (!startedDate || !endedDate) return;
        if (startedDate > endedDate) {
            setDateRange({ ...dateRange, started_at: dayjs.utc() });
            customToast.error("Start date is greater than end date");
            return;
        }
        setDateRange({ ...dateRange, started_at: date });
    };
    const handleEndDate = (date: PickerDate) => {
        const startedDate = dateRange.started_at?.unix();
        const endedDate = date?.unix();
        if (!startedDate || !endedDate) return;
        if (endedDate < startedDate) {
            setDateRange({ ...dateRange, ended_at: dayjs.utc() });
            customToast.error("End date is less than start date");
            return;
        }
        setDateRange({ ...dateRange, ended_at: date });
    };
    const handleDateRange = ({ started_at, ended_at }: DateRange) => {
        setDateRange({
            started_at,
            ended_at,
        });
    };
    return (
        <DateContext.Provider value={{ date, setDate, dateRange, handleDateRange, handleStartDate, handleEndDate }}>
            {children}
            <Outlet />
        </DateContext.Provider>
    );
};
