import { useTranslation } from "react-i18next";
import { boolean, number, object, string } from "yup";

export const CharacterSettingSchema = () => {
    const { t } = useTranslation();
    const schema = {
        gpt_model_id: string(),
        rag_model_id: string().required(),
        enable_context: boolean().required(),
        max_context_words: number().when("enable_context", {
            is: true,
            then: (schema) =>
                schema
                    .transform((value, originalValue) =>
                        typeof originalValue === "string" && originalValue.trim() === "" ? undefined : value
                    )
                    .test("no-leading-zero", `${t("CH.Context_LeaderZero")} `, (value) => {
                        if (value !== undefined && value !== null) {
                            const strValue = value.toString();
                            return !/^0/.test(strValue);
                        }
                        return true;
                    })
                    .min(1, `${t("CH.Context_Word_Limit")} `)
                    .max(2000, `${t("CH.Context_Word_Limit")} `)
                    .required(`${t("COMMON.Required_Input")} `),
            otherwise: (schema) => schema.strip(),
        }),
        enable_hint: boolean().required(),
        task_type: string(), //單選
        service_type: string(),
    };
    return object().shape(schema);
};
