import clsx from "clsx";
import { Navigate, Outlet } from "react-router-dom";

import { selectCurrentUser, selectIsAuth, selectIsLogin } from "../../features/authentication/services";
import { useAppSelector } from "../../hooks";
import PageLoader from "../loader/PageLoader";
import TopNav from "../nav/TopNav";
import styles from "./PrivateLayout.module.scss";

const drawerWidth = 240;

const PrivateLayout = () => {
    const isLogin = useAppSelector(selectIsLogin);
    const isAuth = useAppSelector(selectIsAuth);
    const isSaleMode = !!useAppSelector(selectCurrentUser)?.salesAccountId;

    if (!isLogin) {
        return isAuth === "loading" ? <PageLoader loading={true} /> : <Navigate to='/' replace />;
    }
    return (
        <>
            <div className={clsx(styles.box, { [styles.saleMode]: isSaleMode })}>
                <TopNav drawerWidth={drawerWidth} />
                <main className={styles.main}>
                    {/* <Toolbar /> */}
                    <Outlet />
                </main>
            </div>
        </>
    );
};
export default PrivateLayout;
