import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { FORM_STATE_OPTIONS } from "../../../constants";
import { AMAZE_WEBSITE_URL } from "../../../types/enum/enum";
import { useGetVoicesByLanguageQuery } from "../../voice/services";
import {
    useCreateCharacterMutation,
    useGetCharacterQuery,
    usePromptListQuery,
    useUpdateCharacterMutation,
    useUploadAvatarMutation,
} from "../services/charactersApiSlice";
import { ICharacterInput, IUpdateCharacterReq } from "../types/interfaces";
import { CharacterInfoSchema } from "../validations";
import useAiGeneratePrompt from "./useAiGeneratePrompt";
interface IPrompt {
    promptAi: string;
}
export const DEFAULT_VOICE_QUERY = {
    language: "zh-TW",
    gender: "male",
    azure_voice_short_name: "",
};
export const PROMPT_WORD_LIMIT = 500;

const defaultValues = {
    name: "",
    greeting: "哈囉，你好",
    prompt: "",
    memory_type: 0,
    description: "",
};
interface IProps {
    isCreate: boolean;
}
const useCharacterForm = ({ isCreate }: IProps) => {
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const { projectId, characterId } = useParams();
    const navigate = useNavigate();

    // ============== API ==============
    const [uploadAvatar] = useUploadAvatarMutation();
    const [createCharacter, { isLoading: cCLoading, isSuccess: cCSuccess }] = useCreateCharacterMutation();
    const [updateCharacter, { isLoading: uCLoading, isSuccess: uCSuccess }] = useUpdateCharacterMutation();
    const { data: promptsListData } = usePromptListQuery();
    const { data: cInfo } = useGetCharacterQuery({ id: characterId as string }, { skip: !characterId });
    const [prompt, setPrompt] = useState<string>("");

    //------
    const promptsOptions =
        promptsListData?.data?.map((p) => ({
            label: p.template_name,
            value: p.id,
            description: p.template_text,
        })) || [];

    const defaultPromptValue = "";
    const defaultPrompt = promptsOptions[0]?.description;
    const promptMethods = useForm<IPrompt>({
        defaultValues: {
            promptAi: "",
        },
        mode: "all",
    });
    const methods = useForm<ICharacterInput>({
        defaultValues: {
            ...defaultValues,
            ...(isCreate && { language: "zh-TW" }),
        },
        resolver: yupResolver(CharacterInfoSchema(isCreate)),
        mode: "all",
    });

    const { data: voices } = useGetVoicesByLanguageQuery(
        { ...DEFAULT_VOICE_QUERY, language: methods.watch("language") },
        { skip: !isCreate || !methods.watch("language") }
    );

    // ============== Data Processing ==============

    const descWordCount = methods.watch("prompt")?.length || 0;
    const isDescWordCountError = methods.formState.errors?.prompt;
    const characterInfo = cInfo?.data?.character;
    const defaultVoiceSetting = voices?.data?.[0];

    // ============== handler ==============

    const fetchUpdate = async (reqData: IUpdateCharacterReq) => await updateCharacter(reqData);

    const fetchUploadAvatar = async () => {
        if (!avatarFile) return;

        try {
            const formData = new FormData();
            formData.append("file", avatarFile);

            // 如果是 update，要帶 character_id，後端會幫忙更改 character 的 avatar_file_name
            if (!isCreate && characterId) {
                formData.append("character_id", characterId);
            }
            return await uploadAvatar(formData).unwrap();
        } catch (err) {
            customToast.error("failed");
        }
    };
    const handleSetAvatarFile = (avatarFile: File) => {
        setAvatarFile(avatarFile);
        methods.setValue("avatarFile", avatarFile, FORM_STATE_OPTIONS);
    };

    const handleFormSubmit: SubmitHandler<ICharacterInput> = async (data) => {
        if (!projectId) return;

        const reqData = {
            ...data,
            project_id: projectId,
            copyable: false,
            img_gen_enabled: false,
            restrict_response: false,
            pitch: 1,
            speed: 1,
        };
        const result = await fetchUploadAvatar();

        if (isCreate && defaultVoiceSetting) {
            try {
                const createCharacterRes = await createCharacter({
                    ...reqData,
                    voice_id: defaultVoiceSetting.id,
                    pitch: defaultVoiceSetting.pitch,
                    speed: defaultVoiceSetting.speed,
                    //description: "",
                    avatar_file_name: result ? result.s3_url : "",
                }).unwrap();
                const createdCharacterId = createCharacterRes?.data?.id;
                navigate(`/projects/${projectId}/characters/${createdCharacterId}/character-info`, {
                    state: projectId,
                });
                customToast.success("successfully created");
            } catch (err) {
                customToast.error("failed");
            }
        }

        if (characterId && characterInfo) {
            // 因為 update 不需要帶 language，所以這邊先把 language 拿掉
            const { language, ...temp } = reqData;
            const updateReqData = {
                ...characterInfo,
                ...temp,
                ...(result && { avatar_file_name: result.s3_url }),
                id: characterId,
            };

            await fetchUpdate(updateReqData);
        }
    };

    const handleUpgrade = () => {
        window.open(AMAZE_WEBSITE_URL, "_blank");
    };

    const watchPrompt = promptMethods.watch("promptAi");
    const { generatePrompt, promptLoading, isSuccess: generatePromptSuccess } = useAiGeneratePrompt({ setPrompt });

    const handleCreatePrompt = () => {
        if (watchPrompt) {
            generatePrompt(watchPrompt);
            methods.setValue("prompt", prompt, FORM_STATE_OPTIONS);
        }
    };

    //>  ============== useEffect ==============

    useEffect(() => {
        if (generatePromptSuccess) {
            promptMethods.reset();
        }
    }, [generatePromptSuccess, promptMethods]);

    useEffect(() => {
        methods.setValue("prompt", prompt, {
            shouldDirty: true,
            shouldValidate: false,
        });
    }, [prompt, methods]);

    useEffect(() => {
        if (!isCreate) {
            return;
        }
        methods.setValue("prompt", defaultPrompt, {
            shouldDirty: true,
            shouldValidate: false,
        });
    }, [isCreate, defaultPrompt, methods]);
    useEffect(() => {
        if (characterInfo) {
            methods.reset({
                name: characterInfo.name,
                greeting: characterInfo.greeting,
                prompt: characterInfo.prompt,
                memory_type: characterInfo.memory_type,
                description: characterInfo?.description,
            });
        }
    }, [characterInfo, methods]);

    useEffect(() => {
        if (uCSuccess) {
            customToast.success("successfully created");
            methods.reset();
        }
    }, [uCSuccess, methods]);

    return {
        handleSetAvatarFile,
        avatarFile,
        methods,
        handleFormSubmit,
        submitBtnLoading: cCLoading || uCLoading,
        descWordCount,
        isDescWordCountError,
        handleUpgrade,
        promptsOptions,
        defaultPromptValue,
        cCSuccess,
        uCSuccess,
        promptLoading,
        promptMethods,
        handleCreatePrompt,
    };
};

export default useCharacterForm;
