import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import logoBlack from "../../../assets/logos/logo-b-01.png";
import LogoBtn from "../../../components/button/LogoBtn";
import MButton from "../../../components/button/MButton";
import FormInput from "../../../components/form/FormInput";
import Greeting from "../../../components/greeting/Greeting";
import { MAX_COUNT_SEND_FORGOT_PWD } from "../constants/constants";
import useForgotPwd from "../hooks/useForgotPwd";
import styles from "./ForgetPasswordForm.module.scss";

const ForgotPassword = () => {
    const { t } = useTranslation();

    const { methods, isSentCode, handleSubmit, quota, getResetPasswordCode } = useForgotPwd();

    return (
        <div className={styles.background}>
            <div className={styles.logoWrapper}>
                <LogoBtn logo={logoBlack} />
            </div>
            <div className={styles.formWrap}>
                <Greeting greetL='' greetM='COMMON.Password_Forget' />

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)} className={styles.form}>
                        <FormInput name='email' label={t("COMMON.Email")} required={true} className={styles.input} />
                        {isSentCode && (
                            <>
                                <FormInput
                                    name='newPassword'
                                    label={t("COMMON.Password")}
                                    required={true}
                                    className={styles.input}
                                    type='password'
                                />
                                <FormInput
                                    name='okPassword'
                                    label={t("COMMON.Ok_Password")}
                                    required={true}
                                    className={styles.input}
                                    type='password'
                                />
                                <FormInput
                                    name='confirmationCode'
                                    label={t("COMMON.Confirm_Code")}
                                    required={true}
                                    className={styles.input}
                                />
                                <p className={styles.resendTxt}>{t("COMMON.Forgot_Pwd_Code")}</p>
                                <MButton
                                    btnText={
                                        t("COMMON.Resend_Code") +
                                        `(${quota?.count || 0} / ${MAX_COUNT_SEND_FORGOT_PWD})`
                                    }
                                    disabled={(quota?.count ?? 0) >= MAX_COUNT_SEND_FORGOT_PWD}
                                    className={styles.resendBtn}
                                    fullWidth
                                    color='secondary'
                                    handleClick={() => getResetPasswordCode()}
                                />
                            </>
                        )}
                        <div className={styles.submitBox}>
                            <MButton
                                btnText={
                                    isSentCode
                                        ? t("COMMON.SUBMIT")
                                        : t("COMMON.Password_SentCode") +
                                          `(${quota?.count || 0} / ${MAX_COUNT_SEND_FORGOT_PWD})`
                                }
                                type='submit'
                                disabled={(quota?.count ?? 0) >= MAX_COUNT_SEND_FORGOT_PWD}
                                className={styles.submitBtn}
                            />
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default ForgotPassword;
