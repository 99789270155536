import { ArrowUpward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { scrollToTop } from "../../utils";
import MButton from "./MButton";
import styles from "./ScrollButton.module.scss";

const ScrollButton = () => {
    const [isBtnTopVisible, setIsBtnTopVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const currentY = window.scrollY;
            const windowHeight = window.innerHeight * 0.4;

            if (currentY > windowHeight) {
                setIsBtnTopVisible(true);
            } else {
                setIsBtnTopVisible(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return;
    }, []);
    return (
        <div data-testid='scroll-btn-container'>
            {isBtnTopVisible && (
                <MButton
                    variant='contained'
                    className={styles.sideBtn}
                    btnText=''
                    icon={<ArrowUpward />}
                    onClick={scrollToTop}
                    color='info'
                ></MButton>
            )}
        </div>
    );
};

export default ScrollButton;
