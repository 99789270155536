import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmResetPasswordInput } from "aws-amplify/auth";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { customToast } from "../../../components/notify/NotifyContainer";
import { useAppSelector } from "../../../hooks";
import { awsConfirmResetPassword, awsResetPassword } from "../../../lib/aws/awsAuth";
import { selectCurrentUser } from "../services/authSlice";
import { IPasswordsInputs } from "../types/interfaces";
import { updatePasswordSchema } from "../utils/validation";

const useResetPassword = () => {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);

    const [isReadyResetPassword, setIsReadyResetPassword] = useState(false);

    const methods = useForm<IPasswordsInputs>({
        defaultValues: {
            //oldPassword: "",
            newPassword: "",
            okPassword: "",
            confirmationCode: "",
        },
        resolver: yupResolver(updatePasswordSchema()),
        mode: "all",
    });
    const { isDirty } = methods.formState;

    const onPreResetPassword = async () => {
        if (!currentUser?.email) return;
        try {
            const output = await awsResetPassword(currentUser?.email);
            if (output?.nextStep?.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
                customToast.success(t("COMMON.Forgot_Pwd_Code"));
                setIsReadyResetPassword(true);
            }
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    const fetchAwsResetPassword = async (data: ConfirmResetPasswordInput) => {
        try {
            await awsConfirmResetPassword(data);
            methods.reset();
            setIsReadyResetPassword(false);
            customToast.success(t("ACCOUNT.ChangePWD_Success"));
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    const onResetPassword: SubmitHandler<IPasswordsInputs> = async (data) => {
        try {
            if (currentUser?.email && data?.confirmationCode) {
                await fetchAwsResetPassword({
                    username: currentUser.email,
                    newPassword: data.newPassword,
                    confirmationCode: data.confirmationCode,
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            methods.reset();
            return;
        }
        setIsReadyResetPassword(false);
    };
    return { handleCancel, onResetPassword, isReadyResetPassword, onPreResetPassword, methods };
};
export default useResetPassword;
