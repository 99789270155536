import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { awsConfirmSignUp, awsEmailSignup, awsResendSignupCode } from "../../../lib/aws/awsAuth";
import { IRegisterInput } from "../types/interfaces";
import { registerSchema } from "../utils/validation";
import useQuotaLimit from "./useQuotaLimit";

const defaultValues = {
    email: "",
    password: "",
    okPassword: "",
    //username: "",
    registerCode: "",
};

const useRegister = () => {
    const navigate = useNavigate();

    const [isWaitConfirmCode, setIsWaitConfirmCode] = useState(false);
    const { quota, updateQuota } = useQuotaLimit({ expirationMs: 60 * 60 * 1000, type: "REGISTER" });

    const methods = useForm<IRegisterInput>({
        defaultValues: { ...defaultValues },
        resolver: yupResolver(registerSchema(isWaitConfirmCode)),
        mode: "all",
    });

    const handleResendSignUpCode = async () => {
        updateQuota();
        const email = methods.getValues("email");
        if (email) {
            try {
                await awsResendSignupCode({ username: email });
                customToast.success("Register Code Email sent!");
            } catch (error) {
                if (error instanceof Error) {
                    customToast.error(error?.message as string);
                }
            }
        }
    };

    const submitConfirmCode = async () => {
        if (!methods?.getValues("registerCode")) return;
        try {
            const { isSignUpComplete } = await awsConfirmSignUp({
                username: methods?.getValues("email"),
                confirmationCode: methods?.getValues("registerCode") as string,
            });

            if (isSignUpComplete) {
                setIsWaitConfirmCode(false);
                methods.reset();
                customToast.success("You can use your username or email to login now");
                navigate("/signin");
            }
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    const onSubmit: SubmitHandler<IRegisterInput> = async (data) => {
        try {
            await awsEmailSignup({
                username: data.email,
                password: data.password,
            });
            updateQuota();
            setIsWaitConfirmCode(true);
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };
    return {
        methods,
        onSubmit,
        submitConfirmCode,
        handleResendSignUpCode,
        isWaitConfirmCode,
        quota,
    };
};

export default useRegister;
