import { InputProps, MenuItem, OutlinedInputProps, TextField, TextFieldProps } from "@mui/material";
import { ComponentPropsWithoutRef } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";

import styles from "./FormSelect.module.scss";
type IFormInputProps = {
    name: string;
    label?: string;
    options: { label: string; value: string | number }[];
    otherInputProps?: TextFieldProps;
    InputProps?: Partial<OutlinedInputProps | InputProps>;
} & ComponentPropsWithoutRef<typeof TextField>;

export const MSelect = (inputProps: IFormInputProps) => {
    const { options, onChange, required = true, value, variant, ...otherInputProps } = inputProps;
    return (
        <TextField
            onChange={onChange}
            value={value}
            fullWidth
            required={required}
            variant={variant}
            {...(variant === "filled" && {
                InputProps: { disableUnderline: true },
            })}
            select
            className={styles.customSelect}
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        style: {
                            maxHeight: "268px",
                        },
                    },
                },
            }}
            {...otherInputProps}
        >
            {options.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

const FormSelect = (inputProps: IFormInputProps) => {
    const { name, options, defaultValue, error, ...otherInputProps } = inputProps;

    const { control } = useFormContext<FieldValues>();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : options[0]?.value}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
                return (
                    <MSelect
                        variant='filled'
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={options}
                        error={!!error}
                        helperText={error ? error.message : null}
                        {...otherInputProps}
                    />
                );
            }}
        />
    );
};
export default FormSelect;
