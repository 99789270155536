import { apiSlice } from "../../../services/api/apiSlice";
import {
    IAllButtonClickCountReq,
    IAllButtonClickCountRes,
    IButtonClickCountReq,
    IButtonClickCountRes,
    ICustomerCountReq,
    ICustomerCountRes,
    IDownloadDataReq,
    IDownloadDataRes,
    IGetDataMessageCountRes,
    IGetDataMessageLangRes,
    IGetDataReq,
    IGetDeviceReq,
    IGetDeviceRes,
    IGetTrendingReq,
    IHumanTrafficStatisticReq,
    IHumanTrafficStatisticRes,
} from "../types/interfaces";

const DATA_API_URL = process.env.REACT_APP_BACKEND_PRODATA_URL;

export const dataAnalyzeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //> 取得訊息數量
        getMessageCount: builder.query<IGetDataMessageCountRes, IGetDataReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/message_count`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //> 取得訊息語言數量
        getMessageLangCount: builder.query<IGetDataMessageLangRes, IGetDataReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/message_lang`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //取得訊熱門關鍵字
        getMessageTrending: builder.query<IGetDataMessageLangRes, IGetTrendingReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/trending_keyword_month`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //取得人流時間分布
        getHumanTraffic: builder.query<IHumanTrafficStatisticRes, IHumanTrafficStatisticReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/human_traffic`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //取得點擊總計
        getAllButtonClickCount: builder.query<IAllButtonClickCountRes, IAllButtonClickCountReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/click_count`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //取得點擊分析
        getButtonClickCount: builder.query<IButtonClickCountRes, IButtonClickCountReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/button_click_count`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //取得客群分析
        getKioskCustomerGender: builder.query<ICustomerCountRes, ICustomerCountReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/gender`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        //取得kiosk device_id
        getDevice: builder.query<IGetDeviceRes, IGetDeviceReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/query_device`,
                params,
            }),
            providesTags: ["ProjectData"],
        }),
        downloadProjectData: builder.mutation<IDownloadDataRes, IDownloadDataReq>({
            query: (params) => ({
                url: `${DATA_API_URL}/download`,
                params,
            }),
            invalidatesTags: ["ProjectData"],
        }),
    }),
});

export const {
    useGetMessageCountQuery,
    useGetMessageLangCountQuery,
    useGetMessageTrendingQuery,
    useGetDeviceQuery,
    useGetHumanTrafficQuery,
    useGetButtonClickCountQuery,
    useGetAllButtonClickCountQuery,
    useGetKioskCustomerGenderQuery,
    useDownloadProjectDataMutation,
} = dataAnalyzeApiSlice;
