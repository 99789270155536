import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import MButton from "./MButton";

interface IProps {
    handleClick: () => void;
    btnText: string;
    bgColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
    isWiteText?: boolean;
    isDisabled?: boolean;
}

const DownloadButton = ({ handleClick, btnText, bgColor = "info", isWiteText = false, isDisabled = false }: IProps) => {
    return (
        <MButton
            btnText={btnText}
            icon={<DownloadRoundedIcon viewBox='4 0 24 24' sx={{ fontSize: "20px" }} />}
            type='submit'
            color={bgColor}
            sx={{ color: isWiteText ? "#fbfbfb" : "#1b0063" }}
            onClick={handleClick}
            disabled={isDisabled}
        />
    );
};

export default DownloadButton;
