import { useParams } from "react-router-dom";

import { isProduction } from "../../../utils";
import { TRENDING_TOP_AMOUNT } from "../constants";
import { useDateContext } from "../context";
import { useGetMessageLangCountQuery, useGetMessageTrendingQuery } from "../services";
import { LOCAL_PROJECT_ID_FOR_STATISTICS } from "../types";
import { getData } from "../utils";
interface IProps {
    category: string;
}

const useSocialMessageAnalysis = ({ category }: IProps) => {
    const { date } = useDateContext();
    const yearMonth = date?.format("YYYYMM").valueOf();
    const { projectId } = useParams();
    const currentProjectId = isProduction ? projectId : LOCAL_PROJECT_ID_FOR_STATISTICS;

    const { data: langData } = useGetMessageLangCountQuery({
        date: yearMonth,
        project_id: currentProjectId,
    });
    const { data: trendingData } = useGetMessageTrendingQuery({
        date: yearMonth,
        project_id: currentProjectId,
        top: TRENDING_TOP_AMOUNT,
    });

    const messageLangData = langData?.data;
    const messageTrendingData = trendingData?.data;

    const selectedPieData = getData(category, messageLangData);
    const selectedWordData = getData(category, messageTrendingData);
    return { selectedPieData, selectedWordData };
};
export default useSocialMessageAnalysis;
