import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

import { SOCIAL_CATEGORY_TYPE } from "../types/enum";

interface IProps {
    category: SOCIAL_CATEGORY_TYPE | string;
    setCategory: Dispatch<SetStateAction<SOCIAL_CATEGORY_TYPE | string>>;
}
const SocialContext = createContext({} as IProps);

export const useSocialContext = () => useContext(SocialContext);

export const SocialProvider = ({ children }: { children: ReactNode }) => {
    const [category, setCategory] = useState<SOCIAL_CATEGORY_TYPE | string>("all");

    return (
        <SocialContext.Provider value={{ category: category.toLowerCase(), setCategory }}>
            {children}
        </SocialContext.Provider>
    );
};
