import { useTranslation } from "react-i18next";

import { useTabTitle } from "../../hooks";
import styles from "./Forbidden.module.scss";
const Forbidden = () => {
    useTabTitle("Forbidden");
    const { t } = useTranslation();
    return (
        <section className={styles.wrapper}>
            <div className={styles.content}>
                <h1 className={styles.title}>
                    <span className={styles.httpStatus}>403</span>
                </h1>
                <p className={styles.description}>{t("COMMON.Forbidden")}</p>
            </div>
        </section>
    );
};
export default Forbidden;
