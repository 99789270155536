import * as echarts from "echarts";
import { useEffect, useRef } from "react";

// import useEchartResizer from "./useEchartResizer";

interface IProps {
    xDateData: string[] | undefined;
    yBarDataPrimary: number[] | undefined;
    yBarDataSecond: number[] | undefined;
    category: string;
}

const useBarChartStack = ({ xDateData, yBarDataPrimary, yBarDataSecond, category }: IProps) => {
    const barChartSingle = useRef(null);

    // useEchartResizer({ ref: barChartSingle });
    useEffect(() => {
        const barChart = echarts.init(barChartSingle.current);
        const option = {
            title: {
                text: "GPT",
                left: "left",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {},
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    data: xDateData,
                },
            ],
            yAxis: [
                {
                    type: "value",
                },
            ],
            series: [
                {
                    name: "input",
                    type: "bar",
                    stack: "GPT",
                    emphasis: {
                        focus: "series",
                    },
                    data: yBarDataPrimary,
                },
                {
                    name: "output",
                    type: "bar",
                    stack: "GPT",
                    emphasis: {
                        focus: "series",
                    },
                    data: yBarDataSecond,
                },
            ],
        };
        barChart.setOption(option);

        return () => {
            barChart.dispose();
        };
    }, [category, xDateData, yBarDataPrimary, yBarDataSecond]);

    return barChartSingle;
};
export default useBarChartStack;
