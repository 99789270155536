import "echarts-wordcloud";

import * as echarts from "echarts";
import { useEffect, useRef } from "react";

import { IAnalyzeState } from "../types";
import useEchartResizer from "./useEchartResizer";

interface IProps {
    wordCloudData: IAnalyzeState[];
    category: string;
}

const useWordCloud = ({ category, wordCloudData }: IProps) => {
    const MWordCloud = useRef(null);
    useEchartResizer({ ref: MWordCloud });

    useEffect(() => {
        const wordCloud = echarts.init(MWordCloud.current);
        const option = {
            title: {
                text: category,
                left: "left",
            },
            grid: {
                top: "2%",
                left: "2%",
                right: "2%",
                bottom: "2%",
                containLabel: true,
            },
            // color: ["#59344F", "#F6D47D", "#99B1DD", "#E1B6A3", "#50F1DD", "#C984FF", "#5FCA88"],
            label: {
                fontSize: 16,
            },
            tooltip: {
                trigger: "item",
            },
            itemStyle: {
                borderRadius: 2,
            },
            series: [
                {
                    type: "wordCloud",

                    shape: "circle",

                    keepAspect: false,

                    // maskImage: maskImage,

                    left: "center",
                    top: "center",
                    width: "90%",
                    height: "70%",
                    right: null,
                    bottom: null,
                    sizeRange: [12, 52],
                    rotationRange: [-90, 90],
                    rotationStep: 45,
                    gridSize: 8,
                    drawOutOfBound: false,
                    shrinkToFit: true,
                    layoutAnimation: true,

                    textStyle: {
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        color: function () {
                            return (
                                "rgb(" +
                                [
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                ].join(",") +
                                ")"
                            );
                        },
                    },
                    emphasis: {
                        focus: "self",
                        textStyle: {
                            textShadowBlur: 10,
                            textShadowColor: "#333",
                        },
                    },

                    data: wordCloudData,
                },
            ],
        };
        wordCloud.setOption(option);
        return () => {
            wordCloud.dispose();
        };
    }, [category, wordCloudData]);

    return MWordCloud;
};

export default useWordCloud;
