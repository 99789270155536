import * as echarts from "echarts";
import { useEffect, useRef } from "react";

import { IBarProps } from "../types";
// import useEchartResizer from "./useEchartResizer";

interface IProps extends IBarProps {
    selectedBarColor?: string;
}

const useBarChart = ({ xData, yData, selectedBarColor, category }: IProps) => {
    const barChartSingle = useRef(null);

    // useEchartResizer({ ref: barChartSingle });
    useEffect(() => {
        const barChart = echarts.init(barChartSingle.current);
        const option = {
            title: {
                text: category,
                left: "left",
            },
            tooltip: {
                trigger: "axis",
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: {
                type: "category",
                boundaryGap: true,
                data: xData,
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    name: category,
                    data: yData,
                    type: "bar",
                    emphasis: {
                        focus: "series",
                    },
                    itemStyle: {
                        color: selectedBarColor,
                    },
                },
            ],
        };
        barChart.setOption(option);

        return () => {
            barChart.dispose();
        };
    }, [yData, category, xData, selectedBarColor]);

    return barChartSingle;
};
export default useBarChart;
