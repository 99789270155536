import * as echarts from "echarts";
import { MutableRefObject, useEffect } from "react";

import useComponentSize from "./useComponentSize";

interface IProps {
    ref: MutableRefObject<HTMLElement | null>;
}

const useEchartResizer = ({ ref }: IProps) => {
    const size = useComponentSize({ ref });

    useEffect(() => {
        const chart = ref.current && echarts.getInstanceByDom(ref.current);
        if (chart) {
            chart.resize();
        }
    }, [ref, size]);
};
export default useEchartResizer;
