import { apiSlice } from "../../../services/api/apiSlice";
import { IGetAllVoicesReq, IGetAllVoicesRes, IGetVoiceReq, IGetVoiceRes, IUpsertVoicesReq } from "../types/interfaces";

export const voicesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //> 編輯語音
        updateCVoice: builder.mutation<IGetVoiceRes, IUpsertVoicesReq>({
            query: (reqData) => ({
                url: "/character/upsert_voice",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["CharacterVoices"],
        }),
        //> 取得所有已儲存的語音資料
        getAllCVoices: builder.query<IGetAllVoicesRes, IGetAllVoicesReq>({
            query: (reqData) => ({
                url: "/character/list_voices",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["CharacterVoices"],
        }),
        //> 取得單一語言的語音資料
        getCVoice: builder.query<IGetVoiceRes, IGetVoiceReq>({
            query: (reqData) => ({
                url: "/character/get_voice",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["CharacterVoices"],
        }),
    }),
});

export const { useGetCVoiceQuery, useGetAllCVoicesQuery, useUpdateCVoiceMutation } = voicesApiSlice;
