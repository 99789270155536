import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services/api/apiSlice";
import {
    ICheckSaleAccountRes,
    IGetLangReq,
    IGetLangRes,
    IGetSaleAccountRes,
    ILoginSaleAccountReq,
} from "../types/interfaces";

export const salesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginSaleAccount: builder.mutation<ICheckSaleAccountRes, ILoginSaleAccountReq>({
            query: (reqData) => ({
                url: `${SALE_API_URL}/panel_login`,
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Auth"],
        }),
        checkSaleAccount: builder.mutation<IGetSaleAccountRes, void>({
            query: () => ({
                url: `${SALE_API_URL}/account/me`,
            }),
            invalidatesTags: ["Auth"],
        }),
        getAllLang: builder.query<IGetLangRes, IGetLangReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/orgs/${reqBody.org_id}/languages`,
                method: "GET",
                providesTags: ["SalesOrgs"],
            }),
        }),
    }),
});

export const { useLoginSaleAccountMutation, useCheckSaleAccountMutation, useGetAllLangQuery } = salesApiSlice;
