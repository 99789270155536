import { useGetAmazeKeyQuery } from "../services/botsApiSlice";
import { SOCIAL_TYPES } from "../types/enum";

interface IProps {
    socialMedia: SOCIAL_TYPES;
    projectId: string | undefined;
    characterId: string | undefined;
}

const useGetAmazeKey = ({ socialMedia, projectId, characterId }: IProps) => {
    const isValid = socialMedia && projectId && characterId;
    const { data, isLoading } = useGetAmazeKeyQuery(
        {
            project_id: projectId,
            character_id: characterId,
            social_media: socialMedia,
        } as {
            project_id: string;
            character_id: string;
            social_media: SOCIAL_TYPES;
        },
        {
            skip: !isValid,
        }
    );

    // keep for development
    //useEffect(() => {
    //    if (isSuccess && !data?.key) {
    //        console.log(`%c AmazeKey => `, "color: #4990E2", "cannot get amazeKey from API [check_gen_amaze_key] ");
    //    }
    //}, [data, isSuccess]);
    return {
        amazeKey: data?.key,
        isLoading,
    };
};

export default useGetAmazeKey;
