import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { customToast } from "../../../components/notify";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { RES_STATUS } from "../../../types/enum";
import { selectCurrentUser, setCurrentUser } from "../../authentication/services";
import { useGetOfficialKnowledgesQuery } from "../../knowledges/services";
import { IDefaultK } from "../../knowledges/types";
import { useInitFirstProjectMutation } from "../services";
import { IInitProjectInput } from "../types";

interface IProps {
    isInit: boolean;
}

const useInitProject = ({ isInit }: IProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);

    const [selectedK, setSelectedK] = useState<IDefaultK | null>(null);

    //  ========= api =========
    const { data: knowledgesList } = useGetOfficialKnowledgesQuery({ page: 1, limit: 10, filter: [] });
    const [initProject, { isLoading }] = useInitFirstProjectMutation();

    //  ========= handler =========
    const handleSubmit = async (data: IInitProjectInput) => {
        if (!selectedK) {
            return;
        }
        const selectedKIds = selectedK?.KnowledgeInfo?.map((item) => item.id);

        const reqData = isInit ? { knowledge_list: selectedKIds } : { ...data, knowledge_list: selectedKIds };

        try {
            const result = await initProject(reqData).unwrap();
            if (result.status === RES_STATUS.SUCCESS) {
                customToast.success(t("PROJECT.CREATE_SUCCESS"));
                if (isInit) {
                    dispatch(setCurrentUser({ ...currentUser, isFirstTimeLogin: false }));
                    navigate("/projects");
                    return;
                }
                navigate(`/projects/${result.data}`);
            }
        } catch (err) {
            customToast.error(t("PROJECT.CREATE_FAIL"));
        }
    };

    const handleSelect = (item: IDefaultK) => {
        setSelectedK(item);
    };

    return {
        currentUserEmail: currentUser?.email,
        knowledgesList,
        selectedK,
        isLoading,
        handleSubmit,
        handleSelect,
    };
};
export default useInitProject;
