import "echarts-gl";

import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import useEchartResizer from "./useEchartResizer";

interface IProps {
    bar3Ddata:
        | {
              hours: string[];
              days: string[];
              value: number[][];
          }
        | undefined;
    category?: string;
}

const useBarChart3D = ({ category, bar3Ddata }: IProps) => {
    const { t } = useTranslation();
    const useBar3D = useRef(null);
    const barData = bar3Ddata?.value || [[]];

    const xData = bar3Ddata?.hours;
    const yData = bar3Ddata?.days;
    const maxValue = Math.max(...(barData?.map((item) => item[item.length - 1]) || [60]));

    useEffect(() => {
        const barChart = echarts.init(useBar3D.current);
        const option = {
            tooltip: {
                trigger: "item",
                formatter: function (params: { value: number[] }) {
                    const dayName = yData?.[params.value[1]];
                    const hourName = xData?.[params.value[0]];
                    return (
                        dayName +
                        "<br/>" +
                        hourName +
                        " (UTC+0) <br/>" +
                        t("ANALYSIS.Number_Kiosk_HumanCount") +
                        " : " +
                        params.value[2]
                    );
                },
            },
            title: {
                text: category,
                left: "left",
            },
            visualMap: {
                max: maxValue || 20,
                inRange: {
                    color: ["#012ABD", "#05DF69", "#E1D90E", "#F98E37", "#CB1D19"],
                },
            },
            xAxis3D: {
                type: "category",
                data: xData,
            },
            yAxis3D: {
                type: "category",
                data: yData,
            },
            zAxis3D: {
                type: "value",
            },
            grid3D: {
                boxWidth: 200,
                boxDepth: 80,
                viewControl: {
                    projection: "orthographic",
                },
                light: {
                    main: {
                        intensity: 1.1,
                        shadow: true,
                    },
                    ambient: {
                        intensity: 0.4,
                    },
                },
            },
            series: [
                {
                    name: category,
                    type: "bar3D",
                    data: barData.map(function (item) {
                        return {
                            value: [item[1], item[0], item[2]],
                        };
                    }),
                    shading: "lambert",
                    label: {
                        fontSize: 14,
                        borderWidth: 2,
                    },
                    itemStyle: {
                        opacity: 0.7,
                    },
                    emphasis: {
                        label: {
                            fontSize: 32,
                            color: "#090e12",
                        },
                        itemStyle: {
                            opacity: 1,
                            color: "#3D5668",
                        },
                    },
                },
            ],
        };
        barChart.setOption(option);
        return () => {
            barChart.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, barData]);

    return useBar3D;
};
export default useBarChart3D;
