import MainWrapper from "../../components/layout/MainWrapper";
import { InitProject } from "../../features/projects/components/InitProject";
import useTabTitle from "../../hooks/useTabTitle";

const InitProjectPage = () => {
    useTabTitle("Create First Project");
    return (
        <MainWrapper>
            <InitProject isInit={true} />
        </MainWrapper>
    );
};
export default InitProjectPage;
