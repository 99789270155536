import React from "react";

const SharedSpecPage = React.lazy(() => import("../../pages/sales/item/SharedSpecPage"));
const SalesItemManagePage = React.lazy(() => import("../../pages/sales/item/ManagePage"));
const SharedSpecCategoryPage = React.lazy(() => import("../../pages/sales/item/SharedSpecCategoryPage"));
const ExtraInfoPage = React.lazy(() => import("../../pages/sales/item/ExtraInfoPage"));
const CategoryManagePage = React.lazy(() => import("../../pages/sales/category/ManagePage"));
const CategoryCreatePage = React.lazy(() => import("../../pages/sales/category/CreatePage"));
const ItemCreatePage = React.lazy(() => import("../../pages/sales/item/ItemCreatePage"));
const ItemRoutes = [
    {
        path: "",
        children: [
            { path: "", element: <SalesItemManagePage /> },

            {
                path: "create",
                element: <ItemCreatePage />,
            },
            {
                path: ":itemId", //:itemId
                element: <ItemCreatePage />,
            },
        ],
    },
    {
        path: "category",
        children: [
            {
                path: "",
                element: <CategoryManagePage />,
            },
            {
                path: "create",
                element: <CategoryCreatePage />,
            },
            {
                path: ":categoryId",
                element: <CategoryCreatePage />,
            },
        ],
    },
    {
        path: "shared-spec",
        element: <SharedSpecPage />,
    },
    {
        path: "shared-spec-category",
        children: [
            { path: "", element: <SharedSpecCategoryPage /> },
            { path: ":sharedSpecCategoryId", element: <SharedSpecCategoryPage /> },
        ],
    },
    { path: "extra-info", element: <ExtraInfoPage /> },
];

export default ItemRoutes;
