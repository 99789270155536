import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmResetPasswordInput } from "aws-amplify/auth";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { awsConfirmResetPassword, awsResetPassword } from "../../../lib/aws/awsAuth";
import { IForgotPassword } from "../types/interfaces";
import { forgotPwdSchema } from "../utils/validation";
import useQuotaLimit from "./useQuotaLimit";

const defaultValues = {
    email: "",
    confirmationCode: "",
    newPassword: "",
    okPassword: "",
};

const useForgotPwd = () => {
    const navigate = useNavigate();

    const [isSentCode, setIsSentCode] = useState(false);
    const { quota, updateQuota } = useQuotaLimit({ expirationMs: 60 * 60 * 1000, type: "FORGOT_PWD" });
    const methods = useForm<IForgotPassword>({
        defaultValues,
        resolver: yupResolver(forgotPwdSchema(isSentCode)),
        mode: "all",
    });

    const getResetPasswordCode = async (email?: string) => {
        try {
            const output = await awsResetPassword(email || methods?.getValues("email"));
            if (output?.nextStep?.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
                customToast.success("Check your email box to reset password");
            }
            updateQuota();
            setIsSentCode(true);
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    const handleConfirmResetPassword = async ({
        username,
        confirmationCode,
        newPassword,
    }: ConfirmResetPasswordInput) => {
        try {
            await awsConfirmResetPassword({ username, confirmationCode, newPassword });
            customToast.success("Successfully reset password.");
            navigate("/signin");
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    const handleSubmit: SubmitHandler<IForgotPassword> = async (data) => {
        if (!isSentCode && data?.email) {
            await getResetPasswordCode(data.email);
            return;
        }
        if (data?.email && data?.confirmationCode && data?.newPassword) {
            await handleConfirmResetPassword({
                username: data.email,
                confirmationCode: data.confirmationCode,
                newPassword: data.newPassword,
            });
        }
    };
    return { methods, isSentCode, handleSubmit, quota, getResetPasswordCode };
};

export default useForgotPwd;
