export const PITCH_RANGE_MARK = [
    {
        label: -1,
        value: -1,
    },
    {
        label: 1,
        value: 1,
    },
    {
        label: 3,
        value: 3,
    },
];
export const SPEED_RANGE_MARK = [
    {
        label: -1,
        value: -1,
    },
    {
        label: 1,
        value: 1,
    },
    {
        label: 3,
        value: 3,
    },
];

export const GENDER_OPTIONS = [
    {
        label: "Male",
        value: "male",
    },
    {
        label: "Female",
        value: "female",
    },
];

// 新增前需要先確認是否 azure 有對應的語音檔
export const LOCALE_OPTIONS: { label: string; value: string }[] = [
    {
        label: "zh-TW",
        value: "zh-TW",
    },
    {
        label: "zh-CN",
        value: "zh-CN",
    },
    {
        label: "en-US",
        value: "en-US",
    },
    {
        label: "en-GB",
        value: "en-GB",
    },
    {
        label: "ja-JP",
        value: "ja-JP",
    },
    {
        label: "fr-FR",
        value: "fr-FR",
    },
    {
        label: "zh-HK",
        value: "zh-HK",
    },

    {
        label: "ko-KR",
        value: "ko-KR",
    },
    {
        label: "th-TH",
        value: "th-TH",
    },
    {
        label: "vi-VN",
        value: "vi-VN",
    },
    {
        label: "es-ES",
        value: "es-ES",
    },
    {
        label: "ca-ES",
        value: "ca-ES",
    },
    //暫時關閉，azure 沒有對應的語音檔
    // {
    //     label: "yue-CN",
    //     value: "yue-CN",
    // },
];

// for kiosk voice
export const LANGUAGE_OPTIONS = [
    { value: "auto", label: "Auto" },
    { value: "zh-TW", label: "繁體中文" },
    { value: "zh-CN", label: "简体中文" },
    { value: "en-US", label: "English" },
    { value: "en-UK", label: "English(UK)" },
    { value: "ja-JP", label: "日本語" },
    { value: "fr-FR", label: "Français" },
    { value: "zh-HK", label: "繁體中文(香港)" },
    { value: "ko-KR", label: "한국어" },
    { value: "th-TH", label: "อักษรไทย" },
    { value: "vi-VN", label: "Tiếng Việt" },
];

// for chat-widget, line, fb
export const LANGUAGE_OPTIONS_AUTO = [{ value: "auto", label: "Auto" }, ...LOCALE_OPTIONS];
