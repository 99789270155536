import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

export interface IDeviceIdProps {
    deviceId: string;
    setDeviceId: Dispatch<SetStateAction<string>>;
}
const KioskContext = createContext({} as IDeviceIdProps);

export const useKioskContext = () => useContext(KioskContext);

export const KioskProvider = ({ children }: { children: ReactNode }) => {
    const [deviceId, setDeviceId] = useState<string>("");

    return <KioskContext.Provider value={{ deviceId, setDeviceId }}>{children}</KioskContext.Provider>;
};
