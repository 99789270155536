import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";

import { RefactorRegularModal } from "../../../../components/dialog";
import styles from "./PreviewWithDelete.module.scss";
interface PreviewWithDeleteProps {
    imgUrl: string;
    handleRemove: () => void;
}
const PreviewWithDelete = ({ imgUrl, handleRemove }: PreviewWithDeleteProps) => {
    const { LoadingBtn, Container, Title, Actions, Confirm, Cancel } = RefactorRegularModal;
    return (
        <div className={styles.wrapper}>
            <picture className={styles.imgBox}>
                <img src={imgUrl} alt='preview' className={styles.previewImg} />
            </picture>
            <RefactorRegularModal>
                <LoadingBtn startIcon={<CloseIcon />} classes={{ root: styles.deleteBtn }} />
                <Container>
                    <Title>{t("KNOWLEDGE.Delete_Media")}</Title>
                    <Actions>
                        <Cancel />
                        <Confirm onClick={() => handleRemove()} color='warning' />
                    </Actions>
                </Container>
            </RefactorRegularModal>
        </div>
    );
};
export default PreviewWithDelete;
