import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
    attachClosestEdge,
    type Edge,
    extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { useEffect, useState } from "react";

type TaskState =
    | {
          type: "idle";
      }
    | {
          type: "preview";
          container: HTMLElement;
      }
    | {
          type: "is-dragging";
      }
    | {
          type: "is-dragging-over";
          closestEdge: Edge | null;
      };
interface IProps {
    // Record<string, unknown> 是因為 getInitialData 的回傳型別
    item: Record<string, unknown>;
    canDrag: boolean;
    el: HTMLElement | null;
}
const idle: TaskState = { type: "idle" };
const useDrag = ({ item, canDrag, el }: IProps) => {
    const [dragState, setDragState] = useState<TaskState>(idle);
    useEffect(() => {
        // 協助開發用的 throw Error
        // if (!el) throw new Error("ref not set correctly");
        if (!el) return;
        return combine(
            draggable({
                element: el,
                getInitialData: () => item,
                onDragStart: () => {
                    setDragState({ type: "is-dragging" });
                },
                onDrop: () => {
                    setDragState(idle);
                },
                canDrag: () => canDrag,
            }),
            dropTargetForElements({
                element: el,
                getData: ({ input }) => {
                    return attachClosestEdge(item, {
                        element: el,
                        input,
                        allowedEdges: ["top", "bottom"],
                    });
                },
                onDragEnter: ({ self }) => {
                    const closestEdge = extractClosestEdge(self.data);
                    setDragState({ type: "is-dragging-over", closestEdge });
                },
                onDrag({ self }) {
                    const closestEdge = extractClosestEdge(self.data);

                    // Only need to update react state if nothing has changed.
                    // Prevents re-rendering.
                    setDragState((current) => {
                        if (current.type === "is-dragging-over" && current.closestEdge === closestEdge) {
                            return current;
                        }
                        return { type: "is-dragging-over", closestEdge };
                    });
                },
                onDragLeave: () => {
                    setDragState(idle);
                },
                onDrop: () => {
                    setDragState(idle);
                },
            })
        );
    }, [canDrag, item, el]);

    return { dragState };
};
export default useDrag;
