export const TEMPERATURE_OPTIONS = [
    {
        label: "Cold",
        value: 0,
    },
    {
        label: "Hot",
        value: 1,
    },
];
