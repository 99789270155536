import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import MainTabs from "../../components/tab/MainTabs";
import { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";
import styles from "./SaleTabs.module.scss";
const prodRoute = [
    { labelKey: "SALES.ITEM", href: "item" }, // initHref 是為了要保留 href 為該 tab 頁面路徑去匹配，initHref 才是真正要去的頁面
    // { labelKey: "SALES.Equipment", href: "equipment" },
    // { labelKey: "SALES.LANGUAGE", href: "admin-language" },
    { labelKey: "SALES.LANGUAGE", href: "language" },
];
export const tabRoutes: ILinkTabProps[] = isProduction ? prodRoute : [...prodRoute];

const SaleTabs = ({ children }: { children: React.ReactNode }) => {
    const { orgId } = useParams();

    const navigate = useNavigate();

    const { pathname } = useLocation();
    const pathnameSplit = pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";
    const [currentTab, setCurrentTab] = useState(0);

    const findIdx = tabRoutes.findIndex((r) => r.href === currentRoute);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);
    return (
        <>
            <MainTabs
                currentTab={currentTab}
                handleChange={handleChange}
                tabsOnClick={(tabRoute) =>
                    tabRoute.initHref
                        ? navigate(`/sale/org/${orgId}/${tabRoute.initHref}`)
                        : navigate(`/sale/org/${orgId}/${tabRoute.href}`)
                }
                tabRoutes={tabRoutes}
                className={styles.tab}
            />

            {children}
        </>
    );
};

export default SaleTabs;
