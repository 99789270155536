export const KIOSK_DEFAULT_VOICE = [
    { code: "zh-TW", label: "zh-TW Female", voice: "zh-TW-HsiaoChenNeural", language: "繁中", gender: "Female" },
    { code: "zh-TW", label: "zh-TW Male", voice: "zh-TW-YunJheNeural", language: "繁中", gender: "Male" },
    { code: "zh-CN", label: "zh-CN Female", voice: "zh-CN-XiaoyiNeural", language: "簡中", gender: "Female" },
    { code: "zh-CN", label: "zh-CN Male", voice: "zh-CN-YunxiNeural", language: "簡中", gender: "Male" },
    { code: "zh-HK", label: "zh-HK Female", voice: "zh-HK-HiuMaanNeural", language: "中文(香港)", gender: "Female" },
    { code: "zh-HK", label: "zh-HK Male", voice: "zh-HK-WanLungNeural", language: "中文(香港)", gender: "Male" },
    {
        code: "yue-CN",
        label: "yue-CN Female",
        voice: "yue-CN-XiaoMinNeural",
        language: "粵語(廣東話)",
        gender: "Female",
    },
    { code: "yue-CN", label: "yue-CN Male", voice: "yue-CN-YunSongNeural", language: "粵語(廣東話)", gender: "Male" },
    { code: "en-US", label: "en-US Female", voice: "en-US-AmberNeural", language: "英語(美式)", gender: "Female" },
    { code: "en-US", label: "en-US Male", voice: "en-US-GuyNeural", language: "英語(美式)", gender: "Male" },
    { code: "en-GB", label: "en-GB Female", voice: "en-GB-SoniaNeural", language: "英語(英式)", gender: "Female" },
    { code: "en-GB", label: "en-GB Male", voice: "en-GB-RyanNeural", language: "英語(英式)", gender: "Male" },
    { code: "ja-JP", label: "ja-JP Female", voice: "ja-JP-MayuNeural", language: "日語", gender: "Female" },
    { code: "ja-JP", label: "ja-JP Male", voice: "ja-JP-KeitaNeural", language: "日語", gender: "Male" },
    { code: "ko-KR", label: "ko-KR Female", voice: "ko-KR-SookBokNeural", language: "韓語", gender: "Female" },
    { code: "ko-KR", label: "ko-KR Male", voice: "ko-KR-BongJinNeural", language: "韓語", gender: "Male" },
    { code: "fr-FR", label: "fr-FR Female", voice: "fr-FR-YvetteNeural", language: "法語", gender: "Female" },
    { code: "fr-FR", label: "fr-FR Male", voice: "fr-FR-HenriNeural", language: "法語", gender: "Male" },
    { code: "es-ES", label: "es-ES Female", voice: "es-ES-AbrilNeural", language: "西班牙語", gender: "Female" },
    { code: "es-ES", label: "es-ES Male", voice: "es-ES-AlvaroNeural", language: "西班牙語", gender: "Male" },
    { code: "ca-ES", label: "ca-ES Female", voice: "ca-ES-JoanaNeural", language: "加泰隆尼亞語", gender: "Female" },
    { code: "ca-ES", label: "ca-ES Male", voice: "ca-ES-EnricNeural", language: "加泰隆尼亞語", gender: "Male" },
    { code: "vi-VN", label: "vi-VN Female", voice: "vi-VN-HoaiMyNeural", language: "越南語", gender: "Female" },
    { code: "vi-VN", label: "vi-VN Male", voice: "vi-VN-NamMinhNeural", language: "越南語", gender: "Male" },
    { code: "th-TH", label: "th-TH Female", voice: "th-TH-PremwadeeNeural", language: "泰文", gender: "Female" },
    { code: "th-TH", label: "th-TH Male", voice: "th-TH-NiwatNeural", language: "泰文", gender: "Male" },
];
