import { MutableRefObject, useLayoutEffect, useState } from "react";

import { Size } from "../types";
import getSize from "../utils/getSize";

interface IProps {
    ref: MutableRefObject<HTMLElement | null>;
}

const useComponentSize = ({ ref }: IProps) => {
    const [size, setSize] = useState<Size>(() => getSize(ref.current));

    const handleResize = () => {
        if (ref && ref.current) {
            setSize(getSize(ref.current));
        }
    };

    useLayoutEffect(() => {
        handleResize();

        let resizeObserver = new ResizeObserver(() => handleResize());
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            resizeObserver = null as any;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return size;
};

export default useComponentSize;
