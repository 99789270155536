import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import BackPage from "../../components/button/BackPage";
import RegularContainer from "../../components/container/RegularContainer";
import InnerTabs from "../../components/tab/InnerTabs";
import type { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";

const tabRoutes: ILinkTabProps[] = isProduction
    ? [
          { labelKey: "CH.Info", href: "character-info" },
          { labelKey: "CH.Voice_Set", href: "voice-setting" },
          { labelKey: "CH.ChatRoom", href: "chatroom-setting" },
          { labelKey: "CH.Setting", href: "character-setting" },
          { labelKey: "CH.Chat_History", href: "character-chat-history" },
      ]
    : [
          { labelKey: "CH.Info", href: "character-info" },
          { labelKey: "CH.Voice_Set", href: "voice-setting" },
          { labelKey: "CH.ChatRoom", href: "chatroom-setting" },
          { labelKey: "CH.Setting", href: "character-setting" },
          { labelKey: "CH.Chat_History", href: "character-chat-history" },
      ];

const CharacterTabs = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const { state } = location;
    const { projectId, characterId } = useParams();

    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    const pathnameSplit = location?.pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";

    const findIdx = tabRoutes.findIndex((r) => {
        return r.href === currentRoute;
    });

    const backUrl = state ? `/projects/${state}` : "/projects";

    useEffect(() => {
        // if findIdx is not -1, means it's project dashboard index page, show current tab === Dashboard
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);

    return (
        <>
            <BackPage path={backUrl} />
            <RegularContainer sx={{ pb: 0 }}>
                <RegularContainer.Heading title={t("CH.Edit") || ""} />
                <RegularContainer.Content>
                    <InnerTabs
                        currentTab={currentTab}
                        handleChange={handleChange}
                        tabRoutes={tabRoutes}
                        tabsOnClick={(tabRoute) => {
                            navigate(`/projects/${projectId}/characters/${characterId}/${tabRoute.href}`, {
                                state: projectId,
                            });
                        }}
                    />
                </RegularContainer.Content>
            </RegularContainer>
        </>
    );
};

export default CharacterTabs;
