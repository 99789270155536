import { ArrowBackRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./BackPage.module.scss";

interface IProps {
    path?: string;
}
const BackPage = ({ path }: IProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => {
        if (path) {
            navigate(path);
            return;
        }
        if (document.referrer === "") {
            navigate("/");
            return;
        }
        navigate(-1);
    };
    return (
        <Button onClick={handleClick} startIcon={<ArrowBackRounded />} className={styles.backBtn}>
            {t("COMMON.Back")}
        </Button>
    );
};
export default BackPage;
