import { useAppSelector } from "../../../hooks";
import { selectCurrentUser } from "../../authentication/services";
import { useGetProjectQuery } from "../services";

const useIsProjectOwner = ({ projectId }: { projectId: string | undefined }) => {
    const { data: projectData } = useGetProjectQuery({ id: projectId }, { refetchOnMountOrArgChange: true });
    const currentUser = useAppSelector(selectCurrentUser);
    const projectOwnerId = projectData?.data?.creator_id;
    const isOwner = projectOwnerId === currentUser?.accountId;

    return isOwner;
};

export default useIsProjectOwner;
