import { Add } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemButton, ListItemText, SvgIcon } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question.svg";
import { MButton } from "../../../../components/button";
import { MInput } from "../../../../components/form";
import { MAlert } from "../../../../components/notify";
import { useProjectList } from "../../hooks";
import styles from "./ProjectList.module.scss";

interface IProps {
    handleVideoToggle: () => void;
}
const ProjectList = ({ handleVideoToggle }: IProps) => {
    const { t } = useTranslation();
    const url = useLocation();
    const paramProjectId = url?.pathname?.split("/")[2];
    const { searchVal, handleSearchInput, projectsList, searchArr, handleClick, handleCreate, isFirstTimeLogin } =
        useProjectList();

    return (
        <>
            <div className={styles.searchWrapper}>
                <MInput
                    className={styles.searchInput}
                    //title='search input'
                    type='text'
                    value={searchVal}
                    label={t("PROJECT.Search_My_Project")}
                    onChange={handleSearchInput}
                />
            </div>

            <List sx={{ py: 2 }}>
                {projectsList ? (
                    searchArr?.length > 0 ? (
                        searchArr
                            ?.sort((a, b) => {
                                if (a.id === paramProjectId) return -1;
                                if (b.id === paramProjectId) return 1;
                                return 0;
                            })
                            .map((item) => {
                                const isCurrentPath = item.id === paramProjectId;
                                return (
                                    <ListItemButton
                                        className={clsx(styles.listBtn, {
                                            [styles.active]: isCurrentPath,
                                        })}
                                        key={item.id}
                                        onClick={() => handleClick(item.id)}
                                    >
                                        <ListItem disablePadding>
                                            <ListItemText
                                                primary={item.title}
                                                className={clsx(styles.itemText, { [styles.active]: isCurrentPath })}
                                            />
                                        </ListItem>
                                    </ListItemButton>
                                );
                            })
                    ) : (
                        <MAlert severity='info'>{t("PROJECT.No_Found_Project")}</MAlert>
                    )
                ) : (
                    <MAlert severity='info'>{t("COMMON.NO_PROJECT")}</MAlert>
                )}
            </List>
            <div className={clsx(styles.actionWrapper, { [styles.justifyEnd]: isFirstTimeLogin })}>
                {!isFirstTimeLogin && (
                    <MButton
                        handleClick={handleCreate}
                        btnText={t("PROJECT.Create_Project")}
                        icon={<Add />}
                        variant='contained'
                        className={styles.linkBtn}
                        fullWidth
                    />
                )}

                <div className={styles.linkBtnWrap}>
                    <IconButton>
                        <SvgIcon component={QuestionIcon} inheritViewBox sx={{ fontSize: 20 }} />
                    </IconButton>
                    <div className={styles.linkBtn} onClick={handleVideoToggle}>
                        {t("AMAZE.Tutorial_Video")}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProjectList;
