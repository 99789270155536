import { Outlet } from "react-router-dom";

import { CharacterTabs } from "../../../../components/tab";

const CharactersEditLayout = () => {
    return (
        <>
            <CharacterTabs />
            <Outlet />
        </>
    );
};
export default CharactersEditLayout;
