import "./assets/styles/global.scss";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { HttpClient } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { LiffProvider } from "react-liff";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import theme from "./assets/styles/theme";
import store from "./services/store";
import { isProduction, isStaging } from "./utils";

if (isProduction) {
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost"],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [window.location.origin, "api.stage.ai-amaze.com", "api.ai-amaze.com"],
                networkRequestHeaders: ["X-Custom-Header"],
                networkResponseHeaders: ["X-Custom-Header"],
            }),
            new HttpClient(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: isStaging ? 1 : 0.1,
        replaysOnErrorSampleRate: 1.0,
        sendDefaultPii: true,
    });
}

const liffId = process.env.REACT_APP_LINE_LIFF_ID as string;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Provider store={store}>
                <LiffProvider {...{ liffId }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path='/*' element={<App />} />
                        </Routes>
                    </BrowserRouter>
                </LiffProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);
