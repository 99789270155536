import { ReactNode } from "react";

import { useAppSelector } from "../../../../hooks";
import { selectCurrentUser } from "../../services";
import { AMAZE_ACTION, AMAZE_RESOURCE } from "../../types";
import { checkHasPermission } from "../../utils";
interface IProps {
    resource: AMAZE_RESOURCE;
    action: AMAZE_ACTION;
    children: ReactNode;
    fallback?: ReactNode;
}

const PermissionControl = ({ resource, action, children, fallback }: IProps) => {
    const currentRole = useAppSelector(selectCurrentUser)?.role;
    const hasPermission = checkHasPermission({ role: currentRole, resource, action });

    if (!hasPermission) {
        return fallback ? <>{fallback}</> : null;
    }

    return <>{children}</>;
};

export default PermissionControl;
