import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import BackPage from "../../components/button/BackPage";
import { MainTabs } from "../../components/tab";
import { useGetSaleLangOption } from "../../features/sales/hooks/useGetSaleLang";

const DEFAULT_SALE_LANG = "zh-TW";
const SaleLanguageTabs = () => {
    const navigate = useNavigate();
    const { orgId } = useParams();

    const { state, pathname } = useLocation();

    const [currentTab, setCurrentTab] = useState(0);
    const [searchParams] = useSearchParams();
    const { names } = useGetSaleLangOption();

    const tabRoutes =
        names?.map((row) => ({
            labelKey: row.name,
            href: row.lang,
        })) || [];
    const selectedLang = searchParams.get("lang") || DEFAULT_SALE_LANG;

    const findIdx = tabRoutes.findIndex((r) => r.href === searchParams.get("lang"));

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, selectedLang]);

    const backUrl = state ? `/sale/${state}` : `/sale/org/${orgId}/item`;

    return (
        <>
            <BackPage path={backUrl} />
            <MainTabs
                currentTab={currentTab}
                handleChange={handleChange}
                tabsOnClick={(tabRoute) => navigate(`${pathname}?lang=${tabRoute.href}`)}
                tabRoutes={tabRoutes}
                isPill={true}
            />
        </>
    );
};

export default SaleLanguageTabs;
