import { Size } from "../types";

const getSize = (el: HTMLElement | null): Size => {
    if (!el) {
        return { width: 0, height: 0 };
    }
    return {
        width: el.offsetWidth,
        height: el.offsetHeight,
    };
};
export default getSize;
