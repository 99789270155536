import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";

import styles from "./DemoVideo.module.scss";

interface IProps {
    handleVideoToggle: () => void;
    src_url?: string;
    video_title?: string;
}
const DemoVideo = ({ handleVideoToggle, src_url, video_title }: IProps) => {
    const [loading, setLoading] = useState(true);
    return (
        <div className={styles.demoVideoContainer}>
            <div className={styles.closeBtnWrap} onClick={handleVideoToggle}>
                <CancelIcon fontSize='large' className={styles.btnClose} />
            </div>
            {loading ? <div className={styles.loadingFrame}></div> : <></>}

            <iframe
                src={src_url}
                title={video_title}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                onLoad={() => setLoading(false)}
            ></iframe>
        </div>
    );
};

export default DemoVideo;
