import { useCreateLineAccMutation } from "../services/lineApiSlice";
import { ICreateLineAccInput } from "../types/interfaces";

interface IProps {
    projectId: string | undefined;
    characterId: string | undefined;
}
const useOfficialLineMutation = ({ projectId, characterId }: IProps) => {
    const [createLineAcc, { isLoading: createLoading, isSuccess: createSuccess, isError: createError }] =
        useCreateLineAccMutation();

    const handleCreate = async (data: ICreateLineAccInput) => {
        if (projectId && characterId) {
            const reqData = {
                ...data,
                project_id: projectId,
                character_id: characterId,
            };
            await createLineAcc(reqData);
        }
    };

    return {
        handleCreate,
        createLoading,
        createSuccess,
        createError,
    };
};
export default useOfficialLineMutation;
