export enum UPLOAD_STATUS {
    SUCCESS_100 = "100",
}

export enum UPLOAD_POLL_TIME {
    M_SEC_0 = 0,
    M_SEC_1000 = 1000,
}

export enum KNOWLEDGE_PAGE_MODE {
    PREVIEW = "preview",
    EDIT = "edit",
    CREATE = "create",
}

export enum RES_STATUS {
    "SUCCESS" = 1,
    "FAIL" = -1,
    "CLIENT_ERROR" = 0,
}

export type AuthProviderType = "Amazon" | "Apple" | "Facebook" | "Google";

export const AMAZE_WEBSITE_URL = "https://www.ai-amaze.com";

export enum ICON_TYPES {
    VIDEO = "video",
    DOCUMENT = "document",
    IMAGE = "image",
    LINK = "link",
}

export enum CHAT_WIDGET_VERSION {
    MOBILE = "mobile",
    MESSENGER = "messenger",
    AVATAR = "avatar",
}
