import { customToast } from "../../../components/notify/NotifyContainer";
import { awsSocialLogin } from "../../../lib/aws/awsAuth";
import { AuthProviderType } from "../../../types/enum/enum";

const useQuickLogin = () => {
    const handleSocialLogin = async (provider: AuthProviderType) => {
        try {
            await awsSocialLogin(provider);
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };
    return { handleSocialLogin };
};

export default useQuickLogin;
