import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { useGetCharacterQuery } from "../services";
import { DYNAMIC_TASK_TYPE, GPT_MODEL_TYPES, ICharacterSettingInput, RAG_MODEL_TYPES } from "../types";
import { CharacterSettingSchema } from "../validations/character";
import useCharacterSettingSelectInput from "./useCharacterSettingSelectInput";

interface IProps {
    characterId: string | undefined;
    projectId: string | undefined;
}

const useCharacterSettingForm = ({ characterId, projectId }: IProps) => {
    const { data: characters, isLoading: cIsLoading } = useGetCharacterQuery(
        { id: characterId as string },
        { skip: !characterId }
    );
    const preUpdateCharacter = characters?.data?.character;

    const defaultRef = useRef<ICharacterSettingInput>({
        rag_model_id: preUpdateCharacter?.rag_model.toString() as RAG_MODEL_TYPES | null,
        gpt_model_id: preUpdateCharacter?.gpt_model.toString() as GPT_MODEL_TYPES | null,
        service_type: preUpdateCharacter?.service_type.toString() ?? "1",
        enable_context: preUpdateCharacter?.enable_context || false,
        max_context_words: preUpdateCharacter?.max_context_words ?? 500,
        enable_hint: preUpdateCharacter?.enable_hint || false,
        task_type: preUpdateCharacter?.task_type
            ? (preUpdateCharacter.task_type.toString() as DYNAMIC_TASK_TYPE)
            : ("0" as DYNAMIC_TASK_TYPE),
        hint_number: preUpdateCharacter?.hint_number ?? 2,
        temperature: preUpdateCharacter?.temperature ?? 0,
        primary_language: preUpdateCharacter?.primary_language ?? "auto",
    });

    const defaultValues = defaultRef.current;
    const methods = useForm<ICharacterSettingInput>({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(CharacterSettingSchema()),
    });
    const { handleFormSubmit, getCharacterLoading } = useCharacterSettingSelectInput({
        characterId: characterId as string,
        projectId: projectId as string,
        methods,
    });
    //重整後須帶入來自 api 的 characters 值
    useEffect(() => {
        if (characters !== undefined) {
            defaultRef.current = {
                gpt_model_id: preUpdateCharacter?.gpt_model.toString() as GPT_MODEL_TYPES,
                rag_model_id: preUpdateCharacter?.rag_model.toString() as RAG_MODEL_TYPES,
                enable_context: preUpdateCharacter?.enable_context || false,
                max_context_words: preUpdateCharacter?.max_context_words ?? 500,
                service_type: preUpdateCharacter?.service_type.toString() ?? "0",
                enable_hint: preUpdateCharacter?.enable_hint || false,
                task_type: preUpdateCharacter?.task_type
                    ? (preUpdateCharacter.task_type.toString() as DYNAMIC_TASK_TYPE)
                    : ("0" as DYNAMIC_TASK_TYPE),
                hint_number: preUpdateCharacter?.hint_number ?? 2,
                temperature: preUpdateCharacter?.temperature ?? 0,
                primary_language: preUpdateCharacter?.primary_language ?? "auto",
            };
            methods.reset(defaultRef.current);
        }
    }, [
        characters,
        methods,
        preUpdateCharacter?.enable_context,
        preUpdateCharacter?.gpt_model,
        preUpdateCharacter?.max_context_words,
        preUpdateCharacter?.rag_model,
        preUpdateCharacter?.service_type,
        preUpdateCharacter?.enable_hint,
        preUpdateCharacter?.task_type,
        preUpdateCharacter?.hint_number,
        preUpdateCharacter?.temperature,
        preUpdateCharacter?.primary_language,
    ]);

    return {
        methods,
        defaultValues,
        cIsLoading,
        handleFormSubmit,
        getCharacterLoading,
    };
};

export default useCharacterSettingForm;
