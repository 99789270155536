import categoryImg01 from "../../../assets/images/category01.png";
import categoryImg02 from "../../../assets/images/category02.png";
import categoryImg03 from "../../../assets/images/category03.png";

export const CATEGORY_ASSETS = [
    { img: categoryImg01, color: "#A47EFB" },
    { img: categoryImg02, color: "#F263A6" },
    { img: categoryImg03, color: "#5DCFC3" },
];

export const PROJECT_DESC_LIMIT = 100;
export const TOP_K_RANGE_MARK = [
    {
        label: "",
        value: 1,
    },
];
export const MIN_EMBED_SCORE_RANGE_MARK = [
    {
        label: "",
        value: 1,
    },
];
export const MAX_CONTEXT_TOKEN_RANGE_MARK = [
    {
        label: 1,
        value: 1,
    },
];
export const MAX_HISTORY_TOKEN_RANGE_MARK = [
    {
        label: 1,
        value: 1,
    },
];
export const MAX_RESP_TOKEN_RANGE_MARK = [
    {
        label: 1,
        value: 1,
    },
];
