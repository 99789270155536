import { SlowMotionVideoRounded } from "@mui/icons-material";
import { useState } from "react";

import { DemoVideo } from "../../features/demovideo";
import RefactorRegularModal from "./RefactorRegularModal";

interface IProps {
    src_url: string;
    video_title?: string;
}

const VideoModal = ({ src_url, video_title }: IProps) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const { LoadingBtn, Container } = RefactorRegularModal;

    return (
        <RefactorRegularModal>
            <LoadingBtn startIcon={<SlowMotionVideoRounded />} onClick={() => setPopupOpen(true)}></LoadingBtn>
            <Container props={{ open: popupOpen, onClose: () => setPopupOpen(false) }}>
                <DemoVideo handleVideoToggle={() => setPopupOpen(false)} src_url={src_url} video_title={video_title} />
            </Container>
        </RefactorRegularModal>
    );
};

export default VideoModal;
