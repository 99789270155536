import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { DEFAULT_INPUT_PLACEHOLDER, DEFAULT_MESSENGER_POPUP_ICON } from "../../../utils/generateChatWidgetConfig";
import { LANGUAGE_OPTIONS } from "../constants";
import { useGetCharacterQuery } from "../services/charactersApiSlice";
import { useGetChatWidgetSettingQuery, useUpdateChatWidgetSettingMutation } from "../services/chatWidgetApiSlice";
import { IChatRoomSettingInput, IChatRoomSettingSubmitData, IUpdateChatWidgetSettingReq } from "../types/interfaces";
import { ChatRoomSchema } from "../validations";

const defaultValues = {
    primary: "#5d2efb",
    voice_enabled: false,
    openVoiceInput: false,
    language: "auto",
    openLanguageSelector: false,
    showHeader: true,
    headerTitle: "ai amaze",
    headerSubtitle: "chat with me",
    urlInput: "",
    imagesUrl: [],
    //mobileAvatarUrl: "",
    popupIconUrl: DEFAULT_MESSENGER_POPUP_ICON,
    idleAvatarURL: "",
    mobileAvatarImgUrl: DEFAULT_MESSENGER_POPUP_ICON,
    talkingAvatarURL: "",
    languageOptions: [],
    feedback_type: "reaction" as "reaction" | "rating",
    showMobileAvatar: true,
    autoFocus: true,
    inputPlaceholder: DEFAULT_INPUT_PLACEHOLDER,
    showHeaderAvatar: false,
    headerAvatarUrl: "",
    isMicManualControl: false,
    autoCloseMicSeconds: 5,
};

const useChatroomSetting = () => {
    const { characterId } = useParams();
    const { data: characterData } = useGetCharacterQuery({ id: characterId as string }, { skip: !characterId });
    const { data: chatWidgetConfigData } = useGetChatWidgetSettingQuery(
        { character_id: characterId as string },
        { skip: !characterId }
    );
    const [updateChatWidgetSetting, { isLoading: isResetLoading }] = useUpdateChatWidgetSettingMutation();

    const methods = useForm<IChatRoomSettingInput>({
        defaultValues,
        resolver: yupResolver(ChatRoomSchema()),
        mode: "all",
    });
    const { control, handleSubmit, getValues, register } = methods;
    const isMicManualControl = methods.watch("isMicManualControl");
    const headerAvatarUrlDisabled = !methods.watch("showHeaderAvatar");
    const {
        fields: urlFields,
        append: urlAppend,
        remove: removeUrlField,
    } = useFieldArray({
        control,
        name: "imagesUrl",
    });
    const handleAddUrl = () => {
        const input = getValues("urlInput")?.trim();
        const repeatItem = urlFields.find((field) => field.url === input);
        if (methods.getFieldState("urlInput").invalid) return;
        if (!repeatItem && input.length > 0) {
            urlAppend({ url: input });
            methods.resetField("urlInput", { keepDirty: true });
            return;
        }
    };

    const handleCancel = () => {
        removeUrlField();
        methods.reset();
    };

    const handleFormSubmit = (data: IChatRoomSettingSubmitData) => {
        if (!characterId || !characterData?.data?.character) return;
        const groupLangs = LANGUAGE_OPTIONS.filter((item) => data.languageOptions?.includes(item.value));
        const reqData: IUpdateChatWidgetSettingReq = {
            character_id: characterId,
            script_content: {
                ...data,
                imagesUrl: data?.imagesUrl?.map((item) => item.url) || [],
                mobileAvatarUrl: characterData?.data?.character?.avatar_file_name,
                languageOptions: groupLangs,
            },
        };
        updateChatWidgetSetting(reqData);
        methods.reset();
    };

    const handleReset = () => {
        if (!characterId || !characterData?.data?.character) return;
        const reqData: IUpdateChatWidgetSettingReq = {
            character_id: characterId,
            script_content: {
                ...defaultValues,
                imagesUrl: [],
                mobileAvatarUrl: characterData?.data?.character?.avatar_file_name,
            },
        };
        updateChatWidgetSetting(reqData);
        methods.reset();
    };
    useEffect(() => {
        if (chatWidgetConfigData?.data?.script_content) {
            const processedData = {
                ...chatWidgetConfigData?.data?.script_content,
                imagesUrl: chatWidgetConfigData?.data?.script_content?.imagesUrl?.map((url) => ({ url })),
                languageOptions:
                    chatWidgetConfigData?.data?.script_content?.languageOptions?.map((lang) => lang.value) || [],
            };
            methods.reset(processedData);
        }
    }, [chatWidgetConfigData?.data?.script_content, methods]);

    return {
        handleSubmit,
        handleCancel,
        methods,
        urlFields,
        handleAddUrl,
        removeUrlField,
        handleFormSubmit,
        register,
        defaultValues,
        handleReset,
        isResetLoading,
        apiConfigScriptContent: chatWidgetConfigData?.data?.script_content,
        isMicManualControl,
        headerAvatarUrlDisabled,
    };
};

export default useChatroomSetting;
