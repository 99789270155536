import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ChartContainer.module.scss";

interface ChartContainerProps {
    isBigChart: boolean;
    title?: string | undefined;
    children: ReactNode;
}

const ChartContainer = ({ isBigChart, title, children }: ChartContainerProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.chartGroup}>
            {isBigChart ? (
                <div className={styles.chartContainerLg}>{children}</div>
            ) : (
                <div className={styles.chartContainerSm}>{children}</div>
            )}

            <div>{t(title || "chart-name")}</div>
        </div>
    );
};

export default ChartContainer;
