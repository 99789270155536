import { useDelLineAccMutation } from "../services/lineApiSlice";

interface IProps {
    line_bot_id: string | undefined;
}
const useDeleteBot = ({ line_bot_id }: IProps) => {
    const [deleteLineAcc, { isLoading: deleteLoading, isSuccess: deleteSuccess }] = useDelLineAccMutation();
    const handleDelete = async () => {
        if (line_bot_id) {
            const reqData = {
                id: line_bot_id,
            };
            await deleteLineAcc(reqData);
        }
    };
    return { handleDelete, deleteLoading, deleteSuccess };
};
export default useDeleteBot;
