import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { flushSync } from "react-dom";

interface IProps<T> {
    dndItems: T[];
    el: HTMLElement | null;
    fetchState?: [boolean, Dispatch<SetStateAction<boolean>>];
    reset?: boolean;
    setReset?: Dispatch<SetStateAction<boolean>>;
}
const useDragMonitor = <T extends { id: string }>({ el, dndItems, fetchState, reset, setReset }: IProps<T>) => {
    const [items, setItems] = useState<typeof dndItems>(dndItems);
    const [isDropped, setIsDropped] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        const isArraysEqual =
            items.length === dndItems.length && items.every((value, index) => value.id === dndItems[index].id);
        setIsDirty(!isArraysEqual);
    }, [dndItems, items]);
    useEffect(() => {
        if (!fetchState) return;
        const [isFetched, setIsFetched] = fetchState;
        if (!isFetched) return;
        setItems(dndItems);
        setIsFetched(false);
    }, [fetchState, dndItems]);
    useEffect(() => {
        if (!reset) return;
        setItems(dndItems);
        setReset && setReset(false);
    }, [reset, dndItems, setReset]);

    useEffect(() => {
        if (!el) return;
        // 協助開發用的 throw Error
        // if (!el) throw new Error("ref not set correctly");
        return monitorForElements({
            onDrop: ({ location, source }) => {
                const target = location.current.dropTargets[0];
                if (!target) {
                    return;
                }

                const sourceData = source.data;
                const targetData = target.data;

                if (!sourceData || !targetData) {
                    return;
                }

                const indexOfSource = items.findIndex((category) => category.id === sourceData.id);
                const indexOfTarget = items.findIndex((category) => category.id === targetData.id);

                if (indexOfTarget < 0 || indexOfSource < 0) {
                    return;
                }

                const closestEdgeOfTarget = extractClosestEdge(targetData);

                flushSync(() => {
                    setItems(
                        reorderWithEdge({
                            list: items,
                            startIndex: indexOfSource,
                            indexOfTarget,
                            closestEdgeOfTarget,
                            axis: "vertical",
                        })
                    );
                });
                const element = document.querySelector(`[data-task-id="${sourceData.taskId}"]`);
                if (element instanceof HTMLElement) {
                    triggerPostMoveFlash(element);
                }
                setIsDropped(true);
            },
        });
    }, [items, el]);
    return { items, isDropped, setIsDropped, isDirty };
};
export default useDragMonitor;
