import { USER_PERMISSION_CONFIG } from "../../authentication/data";
import { OtherRolePermission, ROLE } from "../../authentication/types";

const DEFAULT_PROJECT_LIMIT = 1;
export const getUserProjectLimit = (email: string, role: ROLE): number | null => {
    const userConfig = USER_PERMISSION_CONFIG.find((user) => user.email === email);
    // only admin can create project without limit
    if (userConfig?.role === "admin") {
        return null;
    }
    if (userConfig?.role === "user") {
        return (userConfig as OtherRolePermission)?.projectLimits || DEFAULT_PROJECT_LIMIT;
    }
    // other role, eg, enterprise 登入一律都是 enterprise_user
    // 目前沒有針對 enterprise 處理 專案數量 限制, 先使用 DEFAULT_PROJECT_LIMIT(1)
    return (userConfig as OtherRolePermission)?.projectLimits || DEFAULT_PROJECT_LIMIT;
};

export const checkProjectLimitNotExceed = (currentProjectNum: number, projectLimit: number | null) => {
    if (!projectLimit) return true;
    if (currentProjectNum >= projectLimit) return false;
    return true;
};
