const WIDGET_CDN_URL = process.env.REACT_APP_WIDGET_CDN_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const HINT_Q_LIMIT = 20;
const HINT_A_LIMIT = 100;
const AUTO_CLOSE_MIC_SECONDS_RANGE_MARK = [
    { value: 5, label: "5" },
    { value: 30, label: "30" },
];

export { AUTO_CLOSE_MIC_SECONDS_RANGE_MARK, BACKEND_URL, HINT_A_LIMIT, HINT_Q_LIMIT, WIDGET_CDN_URL };
