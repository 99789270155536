import { useTranslation } from "react-i18next";
import { mixed, number, object, string } from "yup";

export const CharacterInfoSchema = (isCreate: boolean) => {
    const { t } = useTranslation();

    const schema = {
        name: string().required(`${t("COMMON.Required_Input")}`),
        prompt: string(),
        greeting: string().required(`${t("COMMON.Required_Input")}`),
        avatarFile: mixed(),
        memory_type: number().required(),
        ...(isCreate && { language: string().required(`${t("COMMON.Required_Input")}`) }),
    };

    return object().shape(schema);
};
