import { AMAZE_ACTION, ENTERPRISE_RESOURCE, ENTERPRISE_ROLE_ID, IRoleResourceRestriction } from "../types";

/**
   {
        roles: 輸入需要被限制的角色
        resource: 輸入需要被限制的資源
        action: 輸入需要被限制的動作
    },
 */
export const ROLE_RESOURCE_RESTRICTIONS: IRoleResourceRestriction[] = [
    {
        roles: ["user", "enterprise_user"],
        resource: "project",
        action: "create",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "query_params_on_project_setting",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "gptModel",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "serviceType",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "context",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "ragModel",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "dynamic_hint",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "chatWidgetSetting",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "temperature",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "primary_language",
        action: "update",
    },
];

export const ENTERPRISE_ROLE_PERMISSIONS: Record<ENTERPRISE_ROLE_ID, Record<ENTERPRISE_RESOURCE, AMAZE_ACTION[]>> = {
    SUPER_USER: {
        project: ["create", "update", "view", "delete"],
        character: ["create", "update", "view", "delete"],
        knowledge: ["create", "update", "view", "delete"],
        billing: ["view"],
    },
    ORG_ADMIN: {
        project: ["create", "update", "view", "delete"],
        character: ["create", "update", "view", "delete"],
        knowledge: ["create", "update", "view", "delete"],
        billing: ["view"],
    },
    ORG_EDITOR: {
        project: [],
        character: [],
        knowledge: ["create", "update", "view", "delete"],
        billing: [],
    },
    ORG_READER: {
        project: [],
        character: [],
        knowledge: [],
        billing: [],
    },
};
