import { number, object, string } from "yup";

export const VoiceSettingSchema = () => {
    const schema = {
        voice_id: string().required(),
        pitch: number().required(),
        speed: number().required(),
    };
    return object().shape(schema);
};
