import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { ImageCrop } from "../../../../components/cropImage";
import { RefactorRegularModal } from "../../../../components/dialog";
import { useToggle } from "../../../../hooks";
// import { FieldArrayWithId } from "react-hook-form";
import styles from "./ImageRender.module.scss";
import PreviewWithDelete from "./PreviewWithDelete";
type StyleProps = {
    wrapper?: string;
};
interface IProps {
    currentImage?: File;
    defaultImageUrl?: string;
    defaultImageName?: string;
    handleRemove: () => void;
    showFixPreview?: boolean;
    showFreePreview?: boolean;
    fixCropTitle?: string;
    freeCropTitle?: string;
    fixAspect?: string;
    showImageName?: boolean;
    classes?: StyleProps;
}

const ImageRenderer = ({
    currentImage,
    defaultImageUrl,
    handleRemove,
    showFixPreview,
    showFreePreview,
    fixCropTitle,
    freeCropTitle,
    fixAspect,
    showImageName = true,
    classes,
}: IProps) => {
    const { LoadingBtn, Container, Title, Actions, Confirm, Cancel } = RefactorRegularModal;
    const [openCrop, setOpenCrop] = useToggle(false);
    const [currentImageUrl, setCurrentImageUrl] = useState<string | undefined>(defaultImageUrl);

    useEffect(() => {
        if (!currentImage) return;
        if (currentImage) {
            setCurrentImageUrl(URL.createObjectURL(currentImage));
        }
    }, [currentImage, setCurrentImageUrl]);
    const handlePreviewAsset = (imageUrl: string) => {
        setCurrentImageUrl(imageUrl);
        setOpenCrop();
    };
    return (
        <>
            <div className={clsx(styles.fileRow, classes?.wrapper)}>
                {showImageName ? (
                    <>
                        <div
                            className={styles.previewAssetBox}
                            onClick={() => handlePreviewAsset(currentImageUrl ?? "")}
                        >
                            <picture className={styles.imgWrap}>
                                <img src={currentImageUrl} alt='preview' className={styles.previewImg} />
                            </picture>
                            <div className={styles.textWrap}>
                                <p>{currentImage?.name}</p>
                            </div>
                        </div>
                        <RefactorRegularModal>
                            <LoadingBtn startIcon={<CloseIcon />} classes={{ root: styles.deleteBtn }} />
                            <Container>
                                <Title>{t("KNOWLEDGE.Delete_Media")}</Title>
                                <Actions>
                                    <Cancel />
                                    <Confirm onClick={() => handleRemove()} color='warning' />
                                </Actions>
                            </Container>
                        </RefactorRegularModal>
                    </>
                ) : (
                    <PreviewWithDelete imgUrl={currentImageUrl ?? ""} handleRemove={handleRemove} />
                )}
            </div>
            {openCrop && (
                <ImageCrop
                    handleToggle={setOpenCrop}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    handleCancel={setOpenCrop}
                    currentImageUrl={currentImageUrl}
                    showFixPreview={showFixPreview}
                    showFreePreview={showFreePreview}
                    fixCropTitle={fixCropTitle}
                    freeCropTitle={freeCropTitle}
                    fixAspect={fixAspect}
                />
            )}
        </>
    );
};
export default ImageRenderer;
