import { Controller, useFormContext } from "react-hook-form";

import styles from "./FormCheckbox.module.scss";

interface IProps {
    name: string;
    label?: string;
    checked: boolean;
}

const FormCheckbox = ({ name, checked, label }: IProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value: currentValue, onBlur, name }, fieldState }) => {
                return (
                    <div className={styles.checkboxInput}>
                        <input
                            type='checkbox'
                            onBlur={onBlur}
                            checked={currentValue ?? checked}
                            id={String(name)}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    </div>
                );
            }}
        />
    );
};
export default FormCheckbox;
