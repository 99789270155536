import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import useDeleteBot from "./useDeleteBot";
import useOfficialLineQuery from "./useOfficialLineQuery";

const useBindOfficialLine = () => {
    const { projectId } = useParams();
    const { officialLineBot, isLoading } = useOfficialLineQuery({ projectId });
    const line_bot_id = officialLineBot?.id;
    const { handleDelete, deleteSuccess } = useDeleteBot({ line_bot_id });

    useEffect(() => {
        if (deleteSuccess) {
            customToast.success("Successful");
        }
    }, [deleteSuccess]);
    return { officialLineBot, handleDelete, pageLoading: isLoading, hasOfficialLineBot: !!officialLineBot };
};
export default useBindOfficialLine;
