import { useParams } from "react-router-dom";

import { isProduction } from "../../../utils";
import { useDateContext } from "../context";
import {
    useGetAllButtonClickCountQuery,
    useGetButtonClickCountQuery,
    useGetHumanTrafficQuery,
    useGetKioskCustomerGenderQuery,
} from "../services";
import { LOCAL_PROJECT_ID_FOR_STATISTICS } from "../types";

interface IKioskAnalyze {
    deviceId: string;
}
const hasDeviceId = ({
    deviceId,
    yearMonth,
    projectId,
}: {
    deviceId: string;
    yearMonth: string | undefined;
    projectId: string | undefined;
}) => {
    return deviceId.length > 0
        ? {
              project_id: projectId,
              date: yearMonth,
              device_id: deviceId,
          }
        : {
              project_id: projectId,
              date: yearMonth,
          };
};

const useKioskAnalyze = ({ deviceId }: IKioskAnalyze) => {
    const { projectId } = useParams();
    const currentProjectId = isProduction ? projectId : LOCAL_PROJECT_ID_FOR_STATISTICS;
    const { date } = useDateContext();
    const yearMonth = date?.format("YYYYMM").valueOf();

    const { data: dataHumanTraffic } = useGetHumanTrafficQuery(
        hasDeviceId({ deviceId, yearMonth, projectId: currentProjectId })
    );
    const { data: dataButtonClickCount } = useGetButtonClickCountQuery(
        hasDeviceId({ deviceId, yearMonth, projectId: currentProjectId })
    );
    const { data: dataAllButtonClickCount } = useGetAllButtonClickCountQuery(
        hasDeviceId({ deviceId, yearMonth, projectId: currentProjectId })
    );
    const { data: dataKioskCustomerGender } = useGetKioskCustomerGenderQuery(
        hasDeviceId({ deviceId, yearMonth, projectId: currentProjectId })
    );

    return {
        data3DBar: dataHumanTraffic?.data,
        dataClickCount: dataButtonClickCount?.data,
        clickDateData: dataAllButtonClickCount?.data?.date,
        kioskClickData: dataAllButtonClickCount?.data?.value,
        dataKioskCustomer: dataKioskCustomerGender?.data,
    };
};

export default useKioskAnalyze;
