import { Container } from "@mui/material";
import { ReactNode } from "react";

import ScrollButton from "../button/ScrollButton";
import styles from "./MainWrapper.module.scss";

interface IProps {
    children: ReactNode;
    hasBtn?: boolean;
}
const MainWrapper = ({ children, hasBtn = true }: IProps) => {
    return (
        <Container className={styles.container} sx={{ pt: 7.3, mb: 8 }}>
            {children}
            <div className={styles.btnContainer}>{hasBtn && <ScrollButton />}</div>
        </Container>
    );
};
export default MainWrapper;
