import { Dispatch, SetStateAction } from "react";

import { CHAT_WIDGET_VERSION } from "../../../types/enum/enum";
import styles from "./FormRadio.module.scss";

interface IProps {
    radioValue: {
        value: CHAT_WIDGET_VERSION;
        label: string;
    }[];
    selectedValue: CHAT_WIDGET_VERSION;
    setSelectedValue: Dispatch<SetStateAction<CHAT_WIDGET_VERSION>>;
}
const FormRadioUnControl = ({ radioValue, selectedValue, setSelectedValue }: IProps) => {
    return (
        <>
            <div className={styles.radioGroup}>
                {radioValue.map((value) => (
                    <div key={value.value}>
                        <input
                            type='radio'
                            className={styles.radioInput}
                            checked={selectedValue === value.value}
                            onChange={() => {
                                setSelectedValue(value.value);
                            }}
                            value={value.value}
                            id={String(value.value)}
                        />
                        <label htmlFor={String(value.value)} className={styles.radioLabel}>
                            {value.label}
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};
export default FormRadioUnControl;
