import { useParams, useSearchParams } from "react-router-dom";

import { ChatWidgetRawData, IStylesOverrides } from "../../../utils/generateChatWidgetConfig";
import { useGetAmazeKey } from "../../botSettings/hooks";
import { SOCIAL_TYPES } from "../../botSettings/types/enum";
import { BACKEND_URL } from "../constants";
import { useGetCharacterQuery } from "../services/charactersApiSlice";
import { useGetChatWidgetSettingQuery } from "../services/chatWidgetApiSlice";

interface IProps {
    customStyleOverrides?: IStylesOverrides;
    selectorId?: string;
    characterId?: string;
    baseConfigForApp?: Partial<ChatWidgetRawData>;
    socialMedia?: SOCIAL_TYPES;
}
// * Restructure the parameters into the format required by chat-widget
const useGenerateChatWidgetConfig = ({
    customStyleOverrides,
    selectorId,
    characterId,
    baseConfigForApp,
    socialMedia = SOCIAL_TYPES.SMAmazePlatformChat,
}: IProps) => {
    const { projectId } = useParams();
    const [searchParams] = useSearchParams();
    const selectedHintLang = searchParams.get("lang");
    const { data: characterData } = useGetCharacterQuery({ id: characterId as string }, { skip: !characterId });

    const { data: chatWidgetConfigData } = useGetChatWidgetSettingQuery(
        { character_id: characterId as string },
        { skip: !characterId }
    );
    const { amazeKey } = useGetAmazeKey({
        projectId,
        characterId,
        socialMedia: socialMedia,
    });
    if (!characterData?.data?.character || !amazeKey || !BACKEND_URL) return;

    const { primary } = chatWidgetConfigData?.data?.script_content || {};

    return {
        base: { amaze_key: amazeKey, ...(selectedHintLang && { hintLanguage: selectedHintLang }), ...baseConfigForApp },
        ...(selectorId && { selectorId }),
        ui: {
            styleOverrides: primary ? { ...customStyleOverrides, primary } : { ...customStyleOverrides },
            autoFocus: false,
        },
        // mobile:{
        //     showAvatar: showAvatar,
        // }
    };
};
export default useGenerateChatWidgetConfig;
