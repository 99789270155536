import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";

import styles from "./MButton.module.scss";

interface IProps extends ButtonProps {
    handleClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    btnText: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
    icon?: ReactNode;
    to?: string;
    loading?: boolean;
}

const MButton = (props: IProps) => {
    const { handleClick, btnText, icon, ...otherBtnProps } = props;
    return (
        <LoadingButton
            variant='contained'
            onClick={handleClick}
            disableElevation
            color='primary'
            className={styles.btn}
            {...otherBtnProps}
        >
            {icon && icon} {btnText}
        </LoadingButton>
    );
};
export default MButton;
