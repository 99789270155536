import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { isProduction } from "../../../utils";
import { MOCK_DEVICE } from "../constants";
import { useKioskContext } from "../context";
import { useGetDeviceQuery } from "../services";
import { LOCAL_PROJECT_ID_FOR_STATISTICS } from "../types";
const useKioskDevice = () => {
    const { deviceId, setDeviceId } = useKioskContext();
    // for production
    const { projectId } = useParams();
    const currentProjectId = isProduction ? projectId : LOCAL_PROJECT_ID_FOR_STATISTICS;
    const { data: kioskDevice } = useGetDeviceQuery({
        project_id: currentProjectId,
    });

    const deviceData = isProduction ? kioskDevice?.data : MOCK_DEVICE;

    const kioskOptions = useMemo(() => {
        const initialOptions = [{ label: "all", value: "" }];

        const deviceOptions =
            deviceData?.map((device, index) => ({
                label: (index + 1).toString().padStart(3, "0"),
                value: device,
            })) || [];

        return [...initialOptions, ...deviceOptions];
    }, [deviceData]);

    const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value as string | null;
        setDeviceId(selectedValue || "");
    };

    return { kioskOptions, deviceId, handleFilterChange };
};

export default useKioskDevice;
