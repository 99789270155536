import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { ICreateLineAccInput } from "../types/interfaces";
import { lineAccSchema } from "../validations/validations";
import useBindOfficialLine from "./useBindOfficialLine";
import useOfficialLineMutation from "./useOfficialLineMutation";

const defaultValues = {
    bot_basic_id: "",
    channel_secret: "",
    channel_access_token: "",
    // id:"",
};

const useBindOfficialLineForm = () => {
    const { projectId, characterId } = useParams();
    const { t } = useTranslation();
    const { hasOfficialLineBot, officialLineBot } = useBindOfficialLine();
    const { handleCreate, createLoading, createSuccess, createError } = useOfficialLineMutation({
        projectId,
        characterId,
    });

    const methods = useForm<ICreateLineAccInput>({
        defaultValues: {
            ...defaultValues,
        },
        resolver: yupResolver(lineAccSchema()),
        mode: "all",
    });

    const handleSubmit: SubmitHandler<ICreateLineAccInput> = async (data) => await handleCreate(data);

    useEffect(() => {
        if (createSuccess) {
            customToast.success("Successful");
            return;
        }
        if (createError) {
            customToast.error(t("LINESETTING.Official_Line_hasBinded"));
            return;
        }
    }, [createSuccess, createError, t]);

    useEffect(() => {
        if (officialLineBot) {
            methods.reset({
                bot_basic_id: officialLineBot?.bot_basic_id,
                channel_secret: officialLineBot?.channel_secret,
                channel_access_token: officialLineBot?.channel_access_token,
            });
            return;
        }
        methods.reset({
            ...defaultValues,
        });
    }, [methods, officialLineBot]);

    return { methods, handleSubmit, createLoading, hasOfficialLineBot };
};
export default useBindOfficialLineForm;
