import PageBox from "../../../../components/box/PageBox";
import RegularContainer from "../../../../components/container/RegularContainer";
import { useSocialContext } from "../../context";
import useBarChart from "../../hooks/useBarChart";
import useBarChartDouble from "../../hooks/useBarChartDouble";
import useSocialMessageAmount from "../../hooks/useSocialMessageAmount";
import { SOCIAL_CATEGORY_TYPE } from "../../types/enum";
import { IBarProps } from "../../types/interfaces";
import { getBarColor } from "../../utils";
import ChartContainer from "../chartContainer/ChartContainer";
import ChartsLayout from "../chartsLayout/ChartsLayout";

const BarChart = ({ xData, yData, category }: IBarProps) => {
    const barColor = getBarColor(category);
    return useBarChart({
        xData,
        yData,
        selectedBarColor: barColor,
        category,
    });
};

const SocialMessageAmount = () => {
    const { category } = useSocialContext();

    const { facebookAmountData, lineAmountData, allAmountData, webAmountData, kioskAmountData, dateData } =
        useSocialMessageAmount({ category });

    const getSocialData = (category: string) => {
        const mappingObj: Record<string, number[] | undefined> = {
            all: [],
            line: lineAmountData,
            facebook: facebookAmountData,
            web: webAmountData,
            kiosk: kioskAmountData,
        };
        return mappingObj[category] ?? [];
    };

    const selectedAmountData = getSocialData(category);
    const selectedBarColor = getBarColor(category);

    const kioskBarChart = BarChart({ xData: dateData, yData: kioskAmountData, category: SOCIAL_CATEGORY_TYPE.Kiosk });
    const facebookBarChart = BarChart({
        xData: dateData,
        yData: facebookAmountData,
        category: SOCIAL_CATEGORY_TYPE.Facebook,
    });
    const webBarChart = BarChart({ xData: dateData, yData: webAmountData, category: SOCIAL_CATEGORY_TYPE.Web });
    const lineBarChart = BarChart({ xData: dateData, yData: lineAmountData, category: SOCIAL_CATEGORY_TYPE.Line });

    const barChartDouble = useBarChartDouble({
        xBarData: dateData,
        yBarDataPrimary: allAmountData,
        category: category,
        yBarDataSecond: selectedAmountData,
        selectedBarColor: selectedBarColor,
    });

    return (
        <RegularContainer sx={{ mt: 0, pt: 0 }}>
            <RegularContainer.Content>
                <PageBox hasChatBox={false}>
                    <ChartsLayout isRwdNone={false}>
                        <ChartContainer isBigChart={true} title='ANALYSIS.Number_Message_Social'>
                            <div ref={barChartDouble} style={{ height: "100%", width: "100%" }} />
                        </ChartContainer>
                    </ChartsLayout>
                    <ChartsLayout isRwdNone={true}>
                        <ChartContainer isBigChart={false} title='ANALYSIS.Number_Message_Line'>
                            <div ref={lineBarChart} style={{ height: "100%", width: "100%" }} />
                        </ChartContainer>
                        <ChartContainer isBigChart={false} title='ANALYSIS.Number_Message_Web'>
                            <div ref={webBarChart} style={{ height: "100%", width: "100%" }} />
                        </ChartContainer>
                    </ChartsLayout>
                    <ChartsLayout isRwdNone={true} isLastMB0={true}>
                        <ChartContainer isBigChart={false} title='ANALYSIS.Number_Message_Facebook'>
                            <div ref={facebookBarChart} style={{ height: "100%", width: "100%" }} />
                        </ChartContainer>
                        <ChartContainer isBigChart={false} title='ANALYSIS.Number_Message_Kiosk'>
                            <div ref={kioskBarChart} style={{ height: "100%", width: "100%" }} />
                        </ChartContainer>
                    </ChartsLayout>
                </PageBox>
            </RegularContainer.Content>
        </RegularContainer>
    );
};
export default SocialMessageAmount;
