import * as echarts from "echarts";
import { useEffect, useRef } from "react";

import useEchartResizer from "./useEchartResizer";

interface IProps {
    xBarData: string[] | undefined;
    yBarDataPrimary: number[] | undefined;
    yBarDataSecond?: number[] | undefined;
    category?: string;
    selectedBarColor: string;
}

const useBarChartDouble = ({ xBarData, yBarDataPrimary, yBarDataSecond, category, selectedBarColor }: IProps) => {
    const barChartDouble = useRef(null);

    useEchartResizer({ ref: barChartDouble });
    useEffect(() => {
        const barChart = echarts.init(barChartDouble.current);
        const option = {
            tooltip: {
                trigger: "item",
            },
            legend: {
                data: ["all", category],
            },
            grid: {
                left: "2%",
                right: "2%",
                bottom: "2%",
                containLabel: true,
            },
            xAxis: {
                type: "category",
                boundaryGap: true,
                data: xBarData,
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    name: "all",
                    data: yBarDataPrimary,
                    type: "bar",
                    emphasis: {
                        focus: "series",
                    },
                    itemStyle: {
                        color: "#3D5668",
                    },
                },
                {
                    name: category || "",
                    data: yBarDataSecond || [],
                    type: "bar",
                    emphasis: {
                        focus: "series",
                    },
                    itemStyle: {
                        color: selectedBarColor,
                    },
                },
            ],
        };
        barChart.setOption(option);
        return () => {
            barChart.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yBarDataPrimary, category, yBarDataSecond]);

    return barChartDouble;
};

export default useBarChartDouble;
