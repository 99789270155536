import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageLoader from "../components/loader/PageLoader";
import { selectCurrentUser, selectIsAuth, selectIsLogin } from "../features/authentication/services";
import { useAppSelector } from "../hooks";

const HomePage = () => {
    const navigate = useNavigate();
    const isLogin = useAppSelector(selectIsLogin);
    const isAuth = useAppSelector(selectIsAuth);
    const currentUser = useAppSelector(selectCurrentUser);
    const isAmzUser = !currentUser?.enterpriseAccountId;
    useEffect(() => {
        if (!isLogin && !isAuth) {
            navigate("/login");
        } else if (currentUser) {
            if (currentUser?.isFirstTimeLogin && isAmzUser) {
                navigate("/init-project");
                return;
            }
            if (currentUser?.salesAccountId) {
                navigate(`/sale/org/${currentUser.org_id}/item`);
                return;
            }
            navigate("/projects");
        }
    }, [isLogin, navigate, isAuth, currentUser, isAmzUser]);
    return <PageLoader loading={true} />;
};
export default HomePage;
