import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { MButton } from "../../../components/button";
import { FormInput } from "../../../components/form";
import { isProduction } from "../../../utils";
import { useLogin } from "../hooks";
import styles from "./LoginForm.module.scss";

const LoginForm = () => {
    const { t } = useTranslation();

    const { methods, onSubmit } = useLogin();

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.signInform}>
                    <div className={styles.signInArea}>
                        <div className={styles.inputBox}>
                            <FormInput
                                name='email'
                                label={t("COMMON.Email")}
                                required={true}
                                className={styles.input}
                            />
                            <FormInput
                                name='password'
                                label={t("COMMON.Password")}
                                required={true}
                                className={styles.input}
                                type='password'
                            />
                        </div>
                    </div>
                    <div className={styles.actionBox}>
                        <div className={styles.submitBox}>
                            <MButton btnText={t("COMMON.Login")} disabled={!methods.formState.isValid} type='submit' />
                        </div>
                        <div className={styles.hintBox}>
                            <Link to={"/forgot-password"}>{t("COMMON.Password_Forget")}</Link> |{" "}
                            <Link to={"/enterprise-login"} className={styles.enterpriseLoginLink}>
                                {t("COMMON.Enterprise_Login")}
                            </Link>
                            {/* 暫時在 prod 先拿掉註冊 */}
                            {!isProduction && (
                                <div className={styles.textLink}>
                                    <p className={styles.hintBoxText}>{t("COMMON.Please_Register")}</p>
                                    <Link to={"/register"}>{t("COMMON.Register")}</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default LoginForm;
