import clsx from "clsx";
import { ReactNode } from "react";

import { Chatroom } from "../../features/characters/components/chatroomSetting";
import styles from "./PageBox.module.scss";

interface IProps {
    hasChatBox?: boolean;
    children: ReactNode;
    styleName?: string;
}

const PageBox = ({ hasChatBox = true, children, styleName }: IProps) => {
    return (
        <div data-testid='page-box' className={styleName}>
            <div className={styles.pageBox}>
                <div className={clsx(styles.contentBox, { [styles.maxWidth]: hasChatBox })}>{children}</div>

                {hasChatBox && (
                    <div className={styles.chatBox}>
                        <Chatroom chatBoxClass={styles.chatWidgetMobile} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PageBox;
