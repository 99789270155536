import { useEffect } from "react";

const useTabTitle = (title: string) => {
    const prefix = "AI AMAZE";
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title ? `${title}` : prefix;
        return () => {
            document.title = prevTitle;
        };
    }, [title, prefix]);
};
export default useTabTitle;
