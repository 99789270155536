import { Outlet } from "react-router-dom";

import MainWrapper from "../../components/layout/MainWrapper";
import useTabTitle from "../../hooks/useTabTitle";
import { AccountTabs } from "../tabs";

const MyAccountPage = () => {
    useTabTitle("My Account");

    return (
        <MainWrapper>
            <AccountTabs />
            <Outlet />
        </MainWrapper>
    );
};
export default MyAccountPage;
