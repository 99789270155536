import { apiSlice } from "../../../services/api/apiSlice";
import { IGetListRequest } from "../../../types/interfaces";
import { IGetChatHistoryRecordsReq, IGetChatHistoryRecordsRes, IHistoryRes } from "../types/interfaces";

export const historyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //get all history id
        getAllChatHistoryId: builder.query<IHistoryRes, IGetListRequest>({
            query: (reqData) => ({
                url: "/chat_history/list_records",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["chatHistoryId"],
        }),
        //get history records
        getChatHistoryRecords: builder.query<IGetChatHistoryRecordsRes, IGetChatHistoryRecordsReq>({
            query: (reqData) => ({
                url: "/chat_history/messages",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["chatRecord"],
        }),
    }),
});

export const { useGetAllChatHistoryIdQuery, useGetChatHistoryRecordsQuery } = historyApiSlice;
