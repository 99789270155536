import { apiSlice } from "../../../services/api";
import { IApiQueryParams } from "../../../types/interfaces";
import {
    ICreateVoiceReq,
    IDeleteVoiceReq,
    IGetAzureAudioReq,
    IGetAzureAudioRes,
    IGetVoicesByLanguageReq,
    IUpdateVoiceReq,
    IVoiceRes,
    IVoicesRes,
} from "../types";

// 目前只會用到getVoicesByLanguage, 其他voice api 目前不會用到, 後端還沒完整測試
export const voicesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createVoice: builder.mutation<{ success: boolean; projects: string }, ICreateVoiceReq>({
            query: (reqData) => ({
                url: "/create_voice",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Voice"],
        }),

        updateVoice: builder.mutation<{ success: boolean; projects: string }, IUpdateVoiceReq>({
            query: (reqData) => ({
                url: "/update_voice",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Voice"],
        }),
        getAllVoices: builder.query<IVoicesRes, IApiQueryParams>({
            query: (params) => ({
                url: "/voices",
                params,
            }),
            providesTags: ["Voice"],
        }),
        // 取得目前所有可以用的聲音
        getVoicesByLanguage: builder.query<IVoicesRes, IGetVoicesByLanguageReq>({
            query: (params) => ({
                url: "/get_voices_by_language",
                params,
            }),
            providesTags: ["Voice"],
        }),
        // 取得Azure audio
        getVoiceAudio: builder.mutation<IGetAzureAudioRes, IGetAzureAudioReq>({
            query: (reqData) => ({
                url: "/get_azure_audio_by_text",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["AzureAudio"],
        }),

        getVoice: builder.query<IVoiceRes, IApiQueryParams>({
            query: (params) => ({
                url: "/voice",
                params,
            }),
            providesTags: ["Voice"],
        }),
        deleteVoice: builder.mutation<{ success: boolean }, IDeleteVoiceReq>({
            query: (reqData) => ({
                url: "/delete_Voice",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["Voice"],
        }),
    }),
});

export const {
    useCreateVoiceMutation,
    useUpdateVoiceMutation,
    useDeleteVoiceMutation,
    useGetAllVoicesQuery,
    useGetVoicesByLanguageQuery,
    useGetVoiceQuery,
    useGetVoiceAudioMutation,
} = voicesApiSlice;
