import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import MainTabs from "../../components/tab/MainTabs";
import { EnterprisePermissionControl } from "../../features/authentication/components/permissionControl";
import { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";
const prodRoute = [
    { labelKey: "PROJECT.Overview", href: "" },
    { labelKey: "PROJECT.Settings", href: "settings" },
    { labelKey: "VOICEKEYWORD.VoiceKeywords", href: "voice-keywords" },
    { labelKey: "HOMOPHONE.HomophoneFix", href: "homophone-fix", initHref: "homophone-fix" },
    { labelKey: "ANALYSIS.Data_ANALYSIS", href: "data-analysis", initHref: "data-analysis/message-number" },
    { labelKey: "SCRIPTTEST.ScriptTestAI", href: "script-test", initHref: "script-test" },
    { labelKey: "PROJECT.Project_Records", href: "project-records", initHref: "project-records" },
];
export const tabRoutes: ILinkTabProps[] = isProduction ? prodRoute : [...prodRoute];

const ProjectTabs = () => {
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const { projectId } = useParams();

    const currentRoute = pathname?.split("/")[3] || "";

    const [currentTab, setCurrentTab] = useState(0);

    const findIdx = tabRoutes.findIndex((r) => r.href === currentRoute);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        // if findIdx is not -1, means it's project dashboard index page, show current tab === Dashboard
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);
    return (
        <>
            <EnterprisePermissionControl resource='project' action='view' fallback={<></>}>
                <MainTabs
                    currentTab={currentTab}
                    handleChange={handleChange}
                    tabsOnClick={(tabRoute) =>
                        tabRoute.initHref
                            ? navigate(`/projects/${projectId}/${tabRoute.initHref}`)
                            : navigate(`/projects/${projectId}/${tabRoute.href}`)
                    }
                    tabRoutes={tabRoutes}
                />
            </EnterprisePermissionControl>

            <Outlet />
        </>
    );
};

export default ProjectTabs;
