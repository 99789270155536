import { useTranslation } from "react-i18next";
import { exportToExcel } from "react-json-to-excel";
import { useParams } from "react-router-dom";

import { isProduction } from "../../../utils";
import { useGetProjectQuery } from "../../projects/services";
import { useDateContext } from "../context";
import { useDownloadProjectDataMutation } from "../services";
import { DATA_HOUR_TITLE, LOCAL_PROJECT_ID_FOR_STATISTICS } from "../types/enum";
import {
    ICustomerGroupItem,
    IHotWordTop20Item,
    IKiosk_Pedestrian,
    IPedestrianCountItem,
    KeysOfIKiosk_Pedestrian,
} from "../types/interfaces";
const hotWordProcess = (rawData: IHotWordTop20Item[]) => {
    const processedData = rawData.map((item) => {
        const temp: { [key: string]: string | number } = {
            name: item.name,
            year_month: item.year_month,
            date_week: item.date_week,
            source: item.source,
        };
        item.word_count.forEach((wordItem, index) => {
            temp[`word_top${index + 1}`] = wordItem.name;
            temp[`word_top${index + 1}_count`] = wordItem.Count;
        });
        return temp;
    });
    return processedData;
};

const kioskPedestrianCountProcess = (
    pedestrianCountRawData: IPedestrianCountItem[],
    customerGroupRawData: ICustomerGroupItem[]
) => {
    const combined = [...pedestrianCountRawData, ...customerGroupRawData];
    const result: IKiosk_Pedestrian[] = [];
    combined.forEach((item) => {
        const { name, date, hour, ...rest } = item;
        const index = result.findIndex((i) => i.date === date && i[DATA_HOUR_TITLE] === hour && i.name === name);
        if (index === -1) {
            result.push({
                name,
                date,
                [DATA_HOUR_TITLE]: hour,
                pedestrian_total: 0,
                interaction_count: 0,
                take_photo_count: 0,
                frameIn_count: 0,
                new_customer_count: 0,
                children_male: 0,
                children_female: 0,
                youth_male: 0,
                youth_female: 0,
                adult_male: 0,
                adult_female: 0,
                elder_male: 0,
                elder_female: 0,
                ...rest,
            });
        } else {
            const currentItem = result[index];
            Object.keys(rest).forEach((key: string) => {
                const typedKey = key as KeysOfIKiosk_Pedestrian;
                (currentItem[typedKey] as number) += rest[key as keyof typeof rest];
            });
        }
    });
    return result;
};

const useProjectDataDownload = () => {
    const { t } = useTranslation();

    const { date } = useDateContext();
    const yearMonth = date?.format("YYYYMM").valueOf();

    const { projectId } = useParams();
    const currentProjectId = isProduction ? projectId : LOCAL_PROJECT_ID_FOR_STATISTICS;

    const [fetchProjectData] = useDownloadProjectDataMutation();

    const { data: projectData } = useGetProjectQuery(
        { id: projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );
    const projectName = projectData?.data.title || "Project Name";
    const downloadFilename = `${yearMonth}_${t("ANALYSIS.Data_Btn_Download")}_${projectName}`;

    const handleDownloadProjectData = async () => {
        const projectDataRes = await fetchProjectData({ date: yearMonth, project_id: currentProjectId }).unwrap();
        const langCountData = projectDataRes?.data?.lang_count;
        const pedestrianCountData = projectDataRes?.data?.pedestrian_count;
        const customerGroupData = projectDataRes?.data?.customer_group;
        const hotWordTop20Data = projectDataRes?.data?.hot_word_top20;

        const processedHotWordTop20Data = hotWordProcess(hotWordTop20Data);
        const processedKioskPedestrianCountData = kioskPedestrianCountProcess(pedestrianCountData, customerGroupData);

        const dataToExcel = [
            ...langCountData.map((item) => {
                return {
                    sheetName: item.source,
                    details: item.data_list.map((langCountItem) => {
                        const { name, date, count, lang, hour } = langCountItem;
                        return { name, date, [DATA_HOUR_TITLE]: hour, lang, count };
                    }),
                };
            }),
            { sheetName: "kiosk_pedestrian_count", details: processedKioskPedestrianCountData },
            { sheetName: "hot_word_top20", details: processedHotWordTop20Data },
        ];

        exportToExcel(dataToExcel, downloadFilename, true);
    };
    return { handleDownloadProjectData };
};
export default useProjectDataDownload;
