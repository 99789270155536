import { createSlice, Middleware } from "@reduxjs/toolkit";

import { RootState } from "../../../services/store";
import { IAuthState } from "../types/interfaces";

const initialState: IAuthState = {
    currentUser: null,
    isLogin: false,
    isAuth: "loading",
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        postLogin: (state) => {
            state.isLogin = true;
            state.isAuth = true;
        },
        postEnterpriseLogin: (state, action) => {
            state.isLogin = true;
            state.isAuth = true;
        },
        postSalesLogin: (state, action) => {
            state.isLogin = true;
            state.isAuth = true;
        },
        postLogout: (state) => {
            state.isLogin = false;
            state.currentUser = null;
            state.isAuth = false;
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
    },
});
export const { postLogin, postLogout, setCurrentUser, postEnterpriseLogin, postSalesLogin } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;
export const selectIsLogin = (state: RootState) => state.auth.isLogin;
export const selectIsAuth = (state: RootState) => state.auth.isAuth;

export const tokenMiddleware: Middleware = (store) => (next) => (action) => {
    if (authSlice.actions.postEnterpriseLogin.type.match(action.type)) {
        const { accessToken, refreshToken, enterpriseAccountId, enterpriseRoles } = action.payload;

        if (accessToken && refreshToken && enterpriseAccountId && enterpriseRoles) {
            localStorage.setItem("amaze_accessToken", accessToken);
            localStorage.setItem("amaze_refreshToken", refreshToken);

            // ref: 因為重新整理頁面，沒有 api 可以再拿到 account_id 和 enterprise_role_id, 所以需要存在 localstorage
            localStorage.setItem("enterprise_account_id", enterpriseAccountId);
            localStorage.setItem("enterprise_roles", JSON.stringify(enterpriseRoles));
        }
    }
    if (authSlice.actions.postSalesLogin.type.match(action.type)) {
        const { accessToken, refreshToken } = action.payload;
        if (accessToken && refreshToken) {
            localStorage.setItem("sale_accessToken", accessToken);
            localStorage.setItem("sale_refreshToken", refreshToken);
        }
    }
    if (authSlice.actions.postLogout.type.match(action.type)) {
        localStorage.removeItem("amaze_accessToken");
        localStorage.removeItem("amaze_refreshToken");
        localStorage.removeItem("enterprise_account_id");
        localStorage.removeItem("enterprise_roles");
        localStorage.removeItem("sale_accessToken");
        localStorage.removeItem("sale_refreshToken");
    }
    return next(action);
};
