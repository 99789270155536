import { Amplify } from "aws-amplify";
import {
    autoSignIn,
    confirmResetPassword,
    type ConfirmResetPasswordInput,
    confirmSignIn,
    type ConfirmSignInInput,
    confirmSignUp,
    type ConfirmSignUpInput,
    fetchAuthSession,
    fetchUserAttributes,
    getCurrentUser,
    resendSignUpCode,
    resetPassword,
    signIn,
    signInWithRedirect,
    signOut,
    signUp,
    type SignUpInput,
    updatePassword,
    type UpdatePasswordInput,
} from "aws-amplify/auth";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";

import { AuthProviderType } from "../../types/enum/enum";
import awsConfig from "./awsConfig";

Amplify.configure(awsConfig);

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

interface IAwsSignup {
    username: string; // username === email in this project
    email: string;
    password: string;
}

export const awsResendSignupCode = async ({ username }: { username: string }) => {
    return await resendSignUpCode({ username });
};
export const awsEmailSignup = async ({ username, password }: SignUpInput) => {
    return await signUp({
        username,
        password,
    });
};

export const awsEmailSignIn = async ({ username, password }: Omit<IAwsSignup, "email">) => {
    return await signIn({
        username,
        password,
    });
};

export const awsGetCurrentUser = async () => {
    return await getCurrentUser();
};

export const currentSession = async () => {
    return (await fetchAuthSession()).tokens ?? null;
};

export const awsAutoSignIn = async () => await autoSignIn();

export const awsResetPassword = async (username: string) => await resetPassword({ username });

//export const awsResendConfirmationCode = async () => {};

export const awsConfirmResetPassword = async ({
    username,
    confirmationCode,
    newPassword,
}: ConfirmResetPasswordInput) => {
    return await confirmResetPassword({ username, confirmationCode, newPassword });
};

export const awsConfirmSignUp = async ({ username, confirmationCode }: ConfirmSignUpInput) => {
    return await confirmSignUp({ username, confirmationCode });
};

export const awsSignOut = async () => await signOut();

export const awsConfirmSignIn = async (data: ConfirmSignInInput) => await confirmSignIn(data);

export const awsSocialLogin = async (provider: AuthProviderType) => signInWithRedirect({ provider });

export const awsFetchUserInfo = async () => await fetchUserAttributes();

// 目前重設密碼與忘記密碼採用同個 API
export const awsUpdatePassword = async ({ oldPassword, newPassword }: UpdatePasswordInput) =>
    await updatePassword({ oldPassword, newPassword });
