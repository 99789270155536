import { Paper, type SxProps, type Theme } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./RegularContainer.module.scss";

interface IRegularContainerContextType {
    containerClasses?: string;
    sx?: SxProps<Theme>;
}

const RegularContainer = ({
    children,
    containerClasses,
    sx,
}: IRegularContainerContextType & { children: ReactNode }) => {
    return (
        <Paper sx={sx} className={clsx(styles.wrapper, containerClasses)} data-testid='regular-container'>
            {children}
        </Paper>
    );
};

const Heading = ({
    title,
    actionButtons,
    headingClasses,
}: {
    title?: string;
    actionButtons?: ReactNode;
    headingClasses?: string;
}) => {
    return (
        <div className={clsx(styles.headingBox, { [styles.headingMB]: true }, headingClasses)}>
            {title && <h2 className={styles.headingTitle}>{title}</h2>}
            {actionButtons && <div className={styles.actionWrapper}>{actionButtons}</div>}
        </div>
    );
};

const Content = ({ children, customClass }: { children: ReactNode; customClass?: string }) => {
    return <div className={clsx(customClass)}>{children}</div>;
};

RegularContainer.Heading = Heading;
RegularContainer.Content = Content;
export default RegularContainer;
