import { ChangeEvent, useState } from "react";

import { IFilter, IGetListRequest } from "../types/interfaces";

interface IProps {
    pageLimit: number;
    projectId?: string;
    searchConfig?: IFilter[];
}
const usePageConfig = ({ pageLimit, projectId, searchConfig }: IProps) => {
    const initialSearch: IFilter = { field: "project_id", operator: "=", value: projectId as string };
    const [pageConfig, setPageConfig] = useState<IGetListRequest>({
        page: 1,
        limit: pageLimit,
        filter: (searchConfig && [initialSearch, ...searchConfig]) || [initialSearch],
    });
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPageConfig((prev) => ({
            ...prev,
            page: value,
        }));
    };

    const handleSearch = (searchInput: IFilter | IFilter[]) => {
        setPageConfig((prev) => {
            if (Array.isArray(searchInput)) {
                return {
                    ...prev,
                    page: 1,
                    filter: [initialSearch, ...searchInput],
                };
            }
            return {
                ...prev,
                page: 1,
                filter: [initialSearch, searchInput],
            };
        });
    };

    return {
        handleSearch,
        handlePageChange,
        currentPage: pageConfig?.page,
        currentSearch: pageConfig?.filter,
        currentFilter: pageConfig?.filter,
    };
};

export default usePageConfig;
