import { Controller, useFormContext } from "react-hook-form";

import styles from "./FormRadio.module.scss";

interface IProps {
    name: string;
    label: string;
    radioValue: number;
}

const FormRadio = ({ name, radioValue, label }: IProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value: currentValue }, fieldState }) => {
                return (
                    <>
                        <input
                            type='radio'
                            className={styles.radioInput}
                            checked={radioValue === currentValue}
                            value={radioValue}
                            id={String(radioValue)}
                            onChange={(e) => {
                                onChange(Number(e.target.value));
                            }}
                        />
                        <label htmlFor={String(radioValue)} className={styles.radioLabel}>
                            {label}
                        </label>
                    </>
                );
            }}
        />
    );
};
export default FormRadio;
