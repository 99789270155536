import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../hooks";
import i18n from "../../../locales/i18n";
import { selectLang, setLang } from "../services";
import { ILang } from "../types";

const languages: ILang[] = [
    { value: "en-US", label: "LANGUAGE.ENUS" },
    { value: "zh-TW", label: "LANGUAGE.ZHTW" },
    { value: "zh-CN", label: "LANGUAGE.ZHCN" },
    { value: "ja-JP", label: "LANGUAGE.JAJP" },
];
const SelectLangs = () => {
    const { t } = useTranslation();

    const defaultLang = i18n.language;
    const selectedLang = useAppSelector(selectLang);

    const dispatch = useDispatch();

    const handleLangChange = (event: SelectChangeEvent) => {
        dispatch(setLang(event.target.value));
    };

    useEffect(() => {
        dispatch(setLang(localStorage.getItem("lang") || defaultLang));
    }, [dispatch, defaultLang]);
    return (
        <Select
            variant='standard'
            onChange={handleLangChange}
            value={selectedLang}
            displayEmpty
            sx={{
                "& .MuiSelect-select.MuiInputBase-input": {
                    pr: "36px",
                    pl: "12px",
                },
                "& .MuiSvgIcon-root": {
                    color: "black",
                },
            }}
            renderValue={(value) => {
                const selectedOption = languages.find((option) => option.value === value);
                return (
                    <Box>
                        <Typography sx={{ color: "black", fontSize: 18, fontWeight: 500, display: "inline-block" }}>
                            {selectedOption ? t(selectedOption.label) : "Language"}
                        </Typography>
                    </Box>
                );
            }}
            disableUnderline
            MenuProps={{ sx: { "& > .MuiMenu-paper ": { mt: "19px" } } }}
        >
            {languages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {t(option.label)}
                </MenuItem>
            ))}
        </Select>
    );
};
export default SelectLangs;
