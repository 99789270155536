import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { awsEmailSignIn } from "../../../lib/aws/awsAuth";
import { ILoginInput } from "../types/interfaces";
import { loginSchema } from "../utils/validation";
const defaultValues = {
    password: "",
    email: "",
};
const useLogin = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const methods = useForm<ILoginInput>({
        defaultValues,
        resolver: yupResolver(loginSchema()),
        mode: "all",
    });

    //  ================= HANDLER =================

    const onSubmit: SubmitHandler<ILoginInput> = async (data) => {
        try {
            const { isSignedIn, nextStep } = await awsEmailSignIn({
                username: data.email,
                password: data.password,
            });
            if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
                customToast.error(t("ACCOUNT.NoCompleted_SignUp"));
                return;
            }
            if (isSignedIn) {
                navigate("/");
            }
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error?.message as string);
            }
        }
    };

    return {
        methods,
        onSubmit,
    };
};
export default useLogin;
