import { useEffect } from "react";
import { useLiff } from "react-liff";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import useBindLine from "./useBindLine";

const useLineSettings = () => {
    const { projectId, characterId } = useParams();

    const { isLoggedIn: isLineLoggedIn, liff } = useLiff();
    const { postBindLine, bindLoading, isBindLine, isBindLineLoading, isBindLineSuccess } = useBindLine();

    const handleLoginLine = () => {
        if (projectId && characterId) {
            // * redirectUri must be `https` only
            const redirectUri = `${window.location.origin}/projects/${projectId}/characters/${characterId}/bot-settings/line`;
            liff.login({ redirectUri });
        }
    };
    useEffect(() => {
        const fetchLineProfile = async () => {
            try {
                const { userId } = await liff.getProfile();
                if (userId) {
                    const res = await postBindLine({ uid: userId }).unwrap();
                    if (res?.bind_line) {
                        customToast.success("Bind line successfully");
                        liff.logout();
                    }
                }
            } catch (err) {
                //console.log(err);
            }
        };
        if (isBindLineSuccess && !isBindLine && isLineLoggedIn) {
            fetchLineProfile();
        }
    }, [isBindLineSuccess, isBindLine, postBindLine, isLineLoggedIn, liff]);

    const pageLoading = isBindLineLoading || bindLoading;
    return { pageLoading, isBindLine, handleLoginLine };
};
export default useLineSettings;
