import React from "react";

import { DateProvider } from "../features/analyze/context";
import { EnterprisePermissionControl } from "../features/authentication/components/permissionControl";
import MyAccountPage from "../pages/account/MyAccountPage";

const AccountPages = {
    AccountSetting: React.lazy(() => import("../pages/account/AccountSettingPage")),
    CurrentUserPlan: React.lazy(() => import("../pages/account/billing/CurrentUserPlanPage")),
    PromoteUpgrade: React.lazy(() => import("../pages/account/PromoteUpgradePage")),
    Usage: React.lazy(() => import("../pages/account/usage/UsagePage")),
    ApiKeys: React.lazy(() => import("../pages/account/apiKeys/ApiKeysPage")),
};

const BillingRoutes = [
    { element: <AccountPages.CurrentUserPlan />, path: "current-plan" },
    { element: <AccountPages.Usage />, path: "usage" },
    { element: <AccountPages.ApiKeys />, path: "api-keys" },
];
const withBillingViewGuard = (children: React.ReactNode) => (
    <EnterprisePermissionControl resource='billing' action='view'>
        {children}
    </EnterprisePermissionControl>
);
const AccountRoutes = [
    {
        element: <DateProvider />,
        path: "",
        children: [
            {
                path: "billing",
                element: withBillingViewGuard(<MyAccountPage />),
                children: BillingRoutes,
            },
        ],
    },
    { element: <AccountPages.AccountSetting />, path: "settings" },
    { element: <AccountPages.PromoteUpgrade />, path: "promote-upgrade" },
];

export default AccountRoutes;
