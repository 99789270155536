import { ReactNode } from "react";

import { useAppSelector } from "../../../../hooks";
import Forbidden from "../../../../pages/errorPages/Forbidden";
import { selectCurrentUser } from "../../services";
import { AMAZE_ACTION, ENTERPRISE_RESOURCE } from "../../types";
import { checkEnterprisePermission } from "../../utils";

interface IProps {
    resource: ENTERPRISE_RESOURCE;
    action: AMAZE_ACTION;
    children: ReactNode;
    fallback?: ReactNode;
}
const EnterprisePermissionControl = ({ resource, action, children, fallback }: IProps) => {
    const enterpriseRoles = useAppSelector(selectCurrentUser)?.enterpriseRoles;
    if (!enterpriseRoles) return <>{children}</>;
    const hasPermission = checkEnterprisePermission(enterpriseRoles, resource, action);

    if (!hasPermission) {
        return fallback ? <>{fallback}</> : <Forbidden />;
    }

    return <>{children}</>;
};
export default EnterprisePermissionControl;
