import { useLocation } from "react-router-dom";

import BackPage from "../../components/button/BackPage";
import { Chatroom } from "../../features/characters/components/chatroomSetting";
import useTabTitle from "../../hooks/useTabTitle";
import styles from "./ChatroomPage.module.scss";

const ChatroomPage = ({ needBackButton = true }: { needBackButton?: boolean }) => {
    useTabTitle("Chatroom");
    const location = useLocation();
    const { state } = location;
    const backUrl = state ? `/projects/${state}` : "/projects";
    return (
        <>
            {needBackButton && <BackPage path={backUrl} />}

            <div className={styles.wrapper}>
                <Chatroom chatBoxClass={styles.chatBoxClass} autoFocus={true} />
            </div>
        </>
    );
};
export default ChatroomPage;
