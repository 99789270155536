import Grid from "@mui/material/Grid"; // Grid version 1
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoginImage from "../assets/images/signinBG.png";
import logoWhite from "../assets/logos/logo-white-01.png";
import LogoBtn from "../components/button/LogoBtn";
import Greeting from "../components/greeting/Greeting";
import AssetWithSkeleton from "../components/loading/AssetWithSkeleton";
import Register from "../features/authentication/components/Register";
import { selectIsLogin } from "../features/authentication/services";
import styles from "./RegisterPage.module.scss";

const RegisterPage = () => {
    const navigate = useNavigate();
    const isLogin = useSelector(selectIsLogin);

    useEffect(() => {
        if (isLogin) {
            navigate("/", { replace: true });
        }
    }, [navigate, isLogin]);
    return (
        <main className={styles.main}>
            <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid item xs={12} md={6} lg={8} className={styles.backgroundImg}>
                    {" "}
                    <AssetWithSkeleton
                        src={LoginImage}
                        alt='Login Background'
                        classes={{ img: styles.imageContainer }}
                    />
                    <div className={styles.logoWrapper}>
                        <LogoBtn logo={logoWhite} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <div className={styles.registerForm}>
                        <Grid container columns={6} className={styles.formWrapper}>
                            <Grid item xs={6}>
                                <Greeting greetM='COMMON.WELCOME' greetL='COMMON.Register' />
                            </Grid>

                            <Grid item xs={6}>
                                <Register />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </main>
    );
};
export default RegisterPage;
