const awsConfig = {
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID as string,
            userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID as string,
            // identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
            signUpVerificationMethod: "code" as "code" | "link", // 'code' | 'link'
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN as string,
                    scopes: ["phone", "email", "openid", "aws.cognito.signin.user.admin"],
                    responseType: "code" as "code" | "token",
                    redirectSignIn: [`${process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN}`],
                    redirectSignOut: [`${process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT}`],
                },
            },
        },
    },
};

export default awsConfig;
