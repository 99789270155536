import { Outlet } from "react-router-dom";

import { MessageTabs } from "../../../../pages/tabs";
import { SocialProvider } from "../../context";
import SocialDropdown from "../socialAnalysis/socialDropdown/SocialDropdown";

const MessageDataLayout = () => {
    return (
        <SocialProvider>
            <SocialDropdown />
            <MessageTabs />
            <Outlet />
        </SocialProvider>
    );
};
export default MessageDataLayout;
