import MainWrapper from "../../components/layout/MainWrapper";
import { InitProject } from "../../features/projects/components/InitProject";
import useTabTitle from "../../hooks/useTabTitle";

const CreateProjectPage = () => {
    useTabTitle("Create New Project");
    return (
        <>
            <MainWrapper>
                <InitProject isInit={false} />
            </MainWrapper>
        </>
    );
};
export default CreateProjectPage;
