import { useEffect, useState } from "react";

interface IQuotaLimit {
    count: number;
    expiresOn: number;
    type: QUOTA_LIMIT_TYPE;
}
type QUOTA_LIMIT_TYPE = "FORGOT_PWD" | "REGISTER";

interface IProps {
    expirationMs: number;
    type: QUOTA_LIMIT_TYPE;
}
const useQuotaLimit = ({ expirationMs, type }: IProps) => {
    const [quota, setQuota] = useState<null | IQuotaLimit>(null);

    const localStorageKey = type + "_LIMIT";

    const updateQuota = () => {
        if (!quota) {
            const newQuota = { count: 1, expiresOn: Date.now() + expirationMs, type };
            setQuota(newQuota);
            localStorage.setItem(localStorageKey, JSON.stringify(newQuota));
            return;
        }

        setQuota((prev) => {
            if (prev) {
                const newQuota = { ...prev, count: prev.count++ };
                localStorage.setItem(localStorageKey, JSON.stringify(newQuota));
                return newQuota;
            }
            return prev;
        });
    };

    useEffect(() => {
        if (localStorage.getItem(localStorageKey)) {
            const localQuota: IQuotaLimit = JSON.parse(localStorage.getItem(localStorageKey) as string);
            if (Date.now() > localQuota?.expiresOn) {
                localStorage.removeItem(localStorageKey);
                return;
            }
            setQuota(localQuota);
        }
    }, [quota, localStorageKey]);

    //useEffect(() => {
    //    if (quota) {
    //        localStorage.setItem(localStorageKey, JSON.stringify(quota));
    //    }
    //}, [quota, localStorageKey]);

    return { quota, updateQuota };
};
export default useQuotaLimit;
