import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { CHAT_WIDGET_VERSION } from "../../../../types/enum";
import { IChatWidgetConfig } from "../../../../utils";
import { WIDGET_CDN_URL } from "../../constants";

interface IProps {
    chatWidgetConfig?: IChatWidgetConfig;
    chatWidgetVersion: CHAT_WIDGET_VERSION;
    children: ReactNode;
}
const ChatIFrame = ({ chatWidgetConfig, chatWidgetVersion, children }: IProps) => {
    const contentRef = useRef<HTMLIFrameElement>(null);
    const mountNode = contentRef?.current?.contentWindow?.document?.body;
    const [widgetDocument, setWidgetDocument] = useState<Document | undefined>();

    useEffect(() => {
        const myScript = widgetDocument?.createElement("script") || document.createElement("script");

        const SCRIPT = `
                import { render } from "${WIDGET_CDN_URL}/${chatWidgetVersion}.mjs";
                render(${JSON.stringify(chatWidgetConfig)});
                `.trim();

        myScript.type = "module";
        myScript.id = "load-chat-widget-customRoot";
        myScript.text = SCRIPT;
        myScript.async = true;
        //myScript.defer = true;
        widgetDocument?.body.appendChild(myScript);
        return () => {
            widgetDocument?.getElementById("load-chat-widget-customRoot")?.remove();
        };
    }, [chatWidgetConfig, chatWidgetVersion, widgetDocument]);
    useEffect(() => {
        if (contentRef?.current?.contentWindow?.document) {
            setWidgetDocument(contentRef?.current?.contentWindow?.document);
        }
    }, [contentRef?.current?.contentWindow?.document]);

    return (
        <>
            <iframe id='projectChatroom' title='Project Bot Chatroom' ref={contentRef}>
                {mountNode && createPortal(children, mountNode)}
            </iframe>
        </>
    );
};
export default ChatIFrame;
