import { apiSlice } from "../../../services/api/apiSlice";
import { IGetListRequest, IGetListResponse } from "../../../types/interfaces";
import {
    ICreateCharacterHintReq,
    IDeleteCharacterHIntReq,
    IGetOneCharacterHintReq,
    IGetOneCharacterHintRes,
    IHintRecord,
    IUpdateCharacterHintReq,
} from "../types/interfaces";

export const hintsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //> 建立提示詞
        createHint: builder.mutation<{ success: boolean; projects: string }, ICreateCharacterHintReq>({
            query: (reqData) => ({
                url: "/character_prompt/create_record",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["HintTexts", "ChatWidgetSetting"],
        }),
        //> 編輯提示詞
        updateHint: builder.mutation<{ success: boolean; projects: string }, IUpdateCharacterHintReq>({
            query: (reqData) => ({
                url: "/character_prompt/update_record",
                method: "PUT",
                body: { ...reqData },
            }),
            invalidatesTags: ["HintTexts", "ChatWidgetSetting"],
        }),
        //> 取得該角色所有提示詞
        getAllHints: builder.query<IGetListResponse<IHintRecord[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/character_prompt/list_records",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["HintTexts"],
        }),
        //> 取得選擇的提示詞
        getHint: builder.query<IGetOneCharacterHintRes, IGetOneCharacterHintReq>({
            query: (params) => ({
                url: "/character_prompt/get_record",
                params,
            }),
            providesTags: ["HintText"],
        }),
        //> 刪除提示詞
        deleteHint: builder.mutation<{ success: boolean }, IDeleteCharacterHIntReq>({
            query: (reqData) => ({
                url: "/character_prompt/delete_record",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["HintTexts", "ChatWidgetSetting"],
        }),
    }),
});

export const {
    useCreateHintMutation,
    useUpdateHintMutation,
    useDeleteHintMutation,
    useGetAllHintsQuery,
    useGetHintQuery,
} = hintsApiSlice;
