import { Paper, SxProps, Theme } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import styles from "./DropDownBox.module.scss";
type Styles = {
    wrapper?: string;
    select?: string;
};
interface IProps {
    category: string;
    options: { label: string; value: string }[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    dropdownTitle?: string;
    classes?: Styles;
    sx?: SxProps<Theme>;
}
const DropdownBox = ({ category, options, onChange, dropdownTitle, sx, classes }: IProps) => {
    const { t } = useTranslation();
    return (
        <Paper
            sx={sx}
            className={clsx(classes?.wrapper)}
            classes={{ root: `${styles.filterWrapper} ${styles.noShadow}` }}
        >
            {dropdownTitle ? <div className={styles.selectName}>{t(dropdownTitle)} :</div> : null}
            <div className={styles.filter}>
                <select
                    name='categories'
                    className={clsx(styles.filterInput, classes?.select)}
                    value={category}
                    onChange={onChange}
                >
                    {options?.map((it) => (
                        <option key={it.value} value={it.value}>
                            {it.label}
                        </option>
                    ))}
                </select>
                <div className={styles.arrow}>
                    <ArrowDropDownIcon />
                </div>
            </div>
        </Paper>
    );
};
export default DropdownBox;
