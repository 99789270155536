import React from "react";

import MessageDataLayout from "../features/analyze/components/layout/MessageDataLayout";
import SocialMessageAmount from "../features/analyze/components/socialAnalysis/SocialMessageAmount";
import SocialMessageAnalysis from "../features/analyze/components/socialAnalysis/SocialMessageAnalysis";
import { DateProvider } from "../features/analyze/context";

const DataAnalysisPage = React.lazy(() => import("../pages/projects/DataAnalysisPage"));
const AnalysisRoutes = [
    {
        path: "",
        element: <DateProvider />,
        children: [
            {
                path: "",
                element: <DataAnalysisPage />,
                children: [
                    {
                        path: "",
                        element: <MessageDataLayout />,
                        children: [
                            {
                                path: "message-number",
                                element: <SocialMessageAmount />,
                            },
                            {
                                path: "message-analysis",
                                element: <SocialMessageAnalysis />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AnalysisRoutes;
