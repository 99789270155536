import { PermissionControl } from "../features/authentication/components/permissionControl";

const DemoPermissionPage = () => {
    return (
        <PermissionControl resource='demo' action='view' fallback={<div>沒有權限</div>}>
            DemoPermissionPage
        </PermissionControl>
    );
};
export default DemoPermissionPage;
