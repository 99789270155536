import { useParams } from "react-router-dom";

import { translateLang } from "../../../utils";
import { useGetAllOrgLangsQuery } from "../services";

const useGetSaleLang = (isEditMode = false) => {
    const { orgId } = useParams();
    const { data } = useGetAllOrgLangsQuery({ org_id: orgId ?? "" }, { skip: isEditMode });
    const languages = data?.data.languages || [];
    const locales = languages.map((lang) => lang.language) || [];
    const currentLang = localStorage.getItem("lang") || "zh-TW";
    const names = locales.map((locale) => {
        return { lang: locale, name: translateLang({ locale, currentLang }) };
    });
    return names;
};
export default useGetSaleLang;

export const useGetSaleLangOption = () => {
    const { orgId } = useParams();
    const { data: langData } = useGetAllOrgLangsQuery({ org_id: orgId ?? "" }, { skip: !orgId });

    const languages = langData?.data.languages || [];
    const locales = languages.map((lang) => lang.language) || [];
    const currentLang = localStorage.getItem("lang") || "zh-TW";
    const names = locales.map((locale) => {
        return {
            lang: locale,
            name: translateLang({ locale, currentLang }),
        };
    });
    const langTabRoutes =
        names?.map((row) => ({
            labelKey: row.name,
            href: row.lang,
        })) || [];
    const langs = languages.map((lang) => lang.language);

    return { names, langs, langTabRoutes };
};
