import { Lock, Menu } from "@mui/icons-material";
import { AppBar, Box, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import logoP from "../../assets/logos/logo-primary.svg";
import { selectCurrentUser, selectIsLogin } from "../../features/authentication/services";
import ToggleVideo from "../../features/demovideo/ToggleVideo";
import { SelectLangs } from "../../features/langs/components";
import { useAppSelector } from "../../hooks";
import { INavItem } from "../../types/interfaces";
import LogoBtn from "../button/LogoBtn";
import MButton from "../button/MButton";
import Sidebar from "./Sidebar";
import classes from "./TopNav.module.scss";
import UserNav from "./UserNav";

interface IProps {
    drawerWidth: number;
}

// 目前還沒有需要連結的 link ，暫時先 comment
const navItems: INavItem[] = [
    //{
    //    href: "/projects",
    //    name: "Projects",
    //    items: null,
    //    showInSidebar: true,
    //    icon: <Home />,
    //},
    //{ href: "/characters", name: "Characters", items: null, showInSidebar: true },
    //{
    //  href: "https://chatbot-dev.netlify.app/pricing",
    //  name: "Price",
    //  items: null,
    //  showInSidebar: false,
    //  icon: <AttachMoney />,
    //},
    // { href: "/members", name: "Members", items: null, showInSidebar: true },
];
const TopNav = ({ drawerWidth }: IProps) => {
    const isLogin = useSelector(selectIsLogin);
    const isSaleUser = Boolean(useAppSelector(selectCurrentUser)?.salesAccountId);
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleVideoToggle = () => {
        setShowVideo(!showVideo);
    };

    return (
        <div id='sidebar-parent' className={classes.sidebarParent}>
            <AppBar
                position='fixed'
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                className={classes.appBar}
            >
                <Box className={classes.navBox}>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        onClick={handleDrawerToggle}
                        sx={{
                            mr: 2,
                            display: { md: "none", color: "#fff" },
                        }}
                    >
                        <Menu className={classes.filled} />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <div className={classes.logoWrapper}>
                            <LogoBtn logo={logoP} />
                        </div>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                display: {
                                    xs: "none",
                                    sm: "block",
                                },
                            }}
                        >
                            {navItems.map((item) => {
                                return (
                                    <MButton
                                        variant={undefined}
                                        key={item.href}
                                        component={Link}
                                        to={item.href}
                                        sx={{
                                            color: "#fff",
                                            fontSize: "2rem",
                                        }}
                                        btnText={item.name}
                                    />
                                );
                            })}
                        </Box>
                        <Box>
                            <Stack flexDirection='row' className={classes.actionWrapper}>
                                <SelectLangs />
                                {isLogin ? (
                                    <UserNav />
                                ) : (
                                    <IconButton onClick={() => navigate("/signin")} sx={{ px: 0 }}>
                                        <Lock
                                            sx={{
                                                color: "#fff",
                                                fontSize: 26,
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </AppBar>

            {isSaleUser ? (
                ""
            ) : (
                <Sidebar
                    navItems={navItems}
                    drawerWidth={drawerWidth}
                    handleDrawerToggle={handleDrawerToggle}
                    mobileOpen={mobileOpen}
                    handleVideoToggle={handleVideoToggle}
                />
            )}

            {showVideo && <ToggleVideo handleVideoToggle={handleVideoToggle} />}
        </div>
    );
};
export default TopNav;
