export const MEMORY_OPTIONS = [
    {
        label: "無記憶",
        value: 0,
    },
    {
        label: "短期記憶 - 對話紀錄",
        value: 1,
    },
    // 以下兩個暫不開放
    //{
    //    label: "短期記憶 - 對話摘要",
    //    value: 2,
    //},
    //{
    //    label: "長期記憶 (尚未開放)",
    //    value: 3,
    //},
];
