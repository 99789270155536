import { useState } from "react";

const useToggle = (initialState = false): [boolean, () => void] => {
    const [visible, setVisibility] = useState(initialState);

    const setToggleStatus = () => setVisibility(() => !visible);

    return [visible, setToggleStatus];
};

export default useToggle;
