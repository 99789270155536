import { useSocialContext } from "../context";
import { SOCIAL_CATEGORY_TYPE } from "../types";

const socialOptions = [
    { label: "All", value: "" },
    { label: "Facebook", value: "facebook" },
    { label: "Line", value: "line" },
    { label: "Kiosk", value: "kiosk" },
    { label: "Web", value: "web" },
];

const useMessageSelect = () => {
    const { category, setCategory } = useSocialContext();

    const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value as SOCIAL_CATEGORY_TYPE | null;
        setCategory(selectedValue || SOCIAL_CATEGORY_TYPE.All);
    };

    return { socialOptions, category, handleFilterChange };
};

export default useMessageSelect;
