// import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { customToast } from "../../../components/notify";
import { useGetCharacterQuery, useUpdateCharacterMutation } from "../services";
import { ICharacterSettingInput, IUpdateCharacterReq } from "../types";

interface IProps {
    characterId: string;
    projectId: string;
    methods: UseFormReturn<ICharacterSettingInput>;
}
const useCharacterSettingSelectInput = ({ characterId, projectId, methods }: IProps) => {
    const { t } = useTranslation();
    const { data: characters, isLoading: getCharacterLoading } = useGetCharacterQuery({ id: characterId });
    const [updateCharacter] = useUpdateCharacterMutation();

    const preUpdateCharacter = characters?.data?.character;

    const updateCharacterData = {
        voice_id: preUpdateCharacter?.voice_id ?? "",
        name: preUpdateCharacter?.name ?? "",
        greeting: preUpdateCharacter?.greeting ?? "",
        img_gen_enabled: preUpdateCharacter?.img_gen_enabled ?? false,
        copyable: preUpdateCharacter?.copyable ?? false,
        avatar_file_name: preUpdateCharacter?.avatar_file_name,
        restrict_response: preUpdateCharacter?.restrict_response ?? false,
        description: preUpdateCharacter?.description,
        pitch: preUpdateCharacter?.pitch ?? 0,
        speed: preUpdateCharacter?.speed ?? 0,
        prompt: preUpdateCharacter?.prompt,
    };

    const serviceType = methods.watch("service_type");
    const gptModelId = methods.watch("gpt_model_id");
    const ragModelId = methods.watch("rag_model_id");
    const enableContext = methods.watch("enable_context");
    const maxContextWords = methods.watch("max_context_words");
    const enableDynamicHint = methods.watch("enable_hint");
    const taskType = methods.watch("task_type");
    const hint_number = methods.watch("hint_number");
    const temperature = methods.watch("temperature");
    const primaryLanguage = methods.watch("primary_language");

    const fetchUpdate = async (reqData: IUpdateCharacterReq) => {
        try {
            await updateCharacter(reqData).unwrap();
            customToast.success(t("COMMON.Successfully"));
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error.message);
            } else {
                customToast.error("Failed");
            }
        }
    };

    const handleFormSubmit = () => {
        if ((serviceType || gptModelId) && characterId && projectId) {
            fetchUpdate({
                ...updateCharacterData,
                id: characterId,
                project_id: projectId,
                service_type: Number(serviceType),
                gpt_model: Number(gptModelId) ?? 0,
                rag_model: Number(ragModelId) ?? 0,
                enable_context: enableContext ?? false,
                max_context_words: Number(maxContextWords) ?? 500,
                enable_hint: enableDynamicHint ?? false,
                task_type: Number(taskType) ?? 0,
                hint_number: hint_number ?? 2,
                temperature: temperature ?? 0,
                primary_language: primaryLanguage ?? "auto",
            });
            methods.reset({}, { keepValues: true });
        }
    };

    return { getCharacterLoading, updateCharacterData, fetchUpdate, handleFormSubmit, maxContextWords, enableContext };
};

export default useCharacterSettingSelectInput;
