export enum SOCIAL_CATEGORY_TYPE {
    "All" = "all",
    "Facebook" = "facebook",
    "Line" = "line",
    "Kiosk" = "kiosk",
    "Web" = "web",
}

export const LOCAL_PROJECT_ID_FOR_STATISTICS = "dea04c45-09b6-4c2a-8533-df77c74924ef";
export const DATA_HOUR_TITLE = "hour(24hr)";
