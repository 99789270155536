import { array, object, string } from "yup";

const pattern = new RegExp(
    "^(https:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and pathscratch
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
);
export const ChatRoomSchema = () => {
    const schema = {
        headerTitle: string(),
        headerSubtitle: string(),
        language: string(),
        urlInput: string().test("urlInput", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
        //mobileAvatarUrl: string().test("mobileAvatarUrl", "錯誤訊息", function (value, ctx) {
        //    if (value === "") return true;
        //    if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
        //    return true;
        //}),
        popupIconUrl: string().test("popupIconUrl", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
        idleAvatarURL: string().test("popupIconUrl", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
        mobileAvatarImgUrl: string().test("mobileAvatarImgUrl", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
        talkingAvatarURL: string().test("talkingAvatarURL", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
        languageOptions: array().of(string()),
    };
    return object().shape(schema);
};
