import "react-toastify/dist/ReactToastify.css";

import { Slide, toast, ToastContainer } from "react-toastify";

export const customToast = {
    warn: (message: string) => toast.warn(message),
    success: (message: string) => toast.success(message),
    error: (message: string) => {
        return message && toast.error(message);
    },
};

const NotifyContainer = () => {
    return (
        <ToastContainer
            containerId={"notify-container"}
            position='bottom-left'
            limit={3}
            autoClose={3000}
            hideProgressBar={true}
            closeOnClick
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            transition={Slide}
            theme='colored'
        />
    );
};
export default NotifyContainer;
