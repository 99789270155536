import { USER_PERMISSION_CONFIG } from "../data";
import { ENTERPRISE_ROLE_PERMISSIONS, ROLE_RESOURCE_RESTRICTIONS } from "../data/roleRestrictions";
import { AMAZE_ACTION, AMAZE_RESOURCE, ENTERPRISE_RESOURCE, ENTERPRISE_ROLE_ID, ROLE } from "../types";

const DEFAULT_ROLE: ROLE = "user";

// 沒有在 USER_PERMISSION_CONFIG 裡面的 email 都會回傳 DEFAULT_ROLE
export const getUserRole = (email?: string) => {
    if (!email) return DEFAULT_ROLE;
    const userRole = USER_PERMISSION_CONFIG.find((user) => user.email === email)?.role;
    return userRole || DEFAULT_ROLE;
};

interface PermissionCheckParams {
    role?: ROLE;
    resource: AMAZE_RESOURCE;
    action: AMAZE_ACTION;
}

export const checkHasPermission = ({ role, resource, action }: PermissionCheckParams) => {
    if (!role) {
        return false;
    }
    const isRestricted = ROLE_RESOURCE_RESTRICTIONS.find(
        (policy) => policy.roles.includes(role) && policy.resource === resource && policy.action === action
    );

    return !isRestricted;
};

export const checkEnterprisePermission = (
    enterpriseRoles: ENTERPRISE_ROLE_ID[] | null | undefined,
    resource: ENTERPRISE_RESOURCE,
    action: AMAZE_ACTION
) => {
    if (!enterpriseRoles) return false;
    return enterpriseRoles.some((r) => {
        const rolesPermissions = ENTERPRISE_ROLE_PERMISSIONS[r];
        return rolesPermissions[resource].includes(action);
    });
};
