import { IDataItem, IDataLangItem } from "../types";

const getData = (category: string, data: IDataLangItem[] | undefined) => {
    const mappingObj: Record<string, IDataItem[]> = {
        all: data?.find((item) => item.name === "all")?.data_list ?? [],
        line: data?.find((item) => item.name === "line")?.data_list ?? [],
        facebook: data?.find((item) => item.name === "facebook")?.data_list ?? [],
        web: data?.find((item) => item.name === "web")?.data_list ?? [],
        kiosk: data?.find((item) => item.name === "kiosk")?.data_list ?? [],
    };
    return mappingObj[category] ?? [];
};
export default getData;
