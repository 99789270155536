import clsx from "clsx";
import { useEffect, useRef } from "react";

import { CHAT_WIDGET_VERSION } from "../../types/enum/enum";
import { IChatWidgetConfig } from "../../utils/generateChatWidgetConfig";

const WIDGET_CDN_URL = process.env.REACT_APP_WIDGET_CDN_URL;

interface IProps {
    chatBoxClass?: string;
    chatWidgetConfig: IChatWidgetConfig;
    chatWidgetVersion: CHAT_WIDGET_VERSION;
}

const ChatWidgetRoom = ({ chatBoxClass, chatWidgetConfig, chatWidgetVersion }: IProps) => {
    const chatRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const myScript = document.createElement("script");

        const SCRIPT = `
            import { render } from "${WIDGET_CDN_URL}/${chatWidgetVersion}.mjs";
            render(${JSON.stringify(chatWidgetConfig)});
            `.trim();

        myScript.type = "module";
        myScript.id = "load-chat-widget-customRoot";
        myScript.text = SCRIPT;
        myScript.async = true;
        //myScript.defer = true;
        document.body.appendChild(myScript);
        return () => {
            document.getElementById("load-chat-widget-customRoot")?.remove();
        };
    }, [chatWidgetConfig, chatWidgetVersion]);

    return <div id='chat-widget-mobile' className={clsx(chatBoxClass)} ref={chatRef}></div>;
};
export default ChatWidgetRoom;
