import { useGetChatHistoryRecordsQuery } from "../services";

const PAGE_LIMIT = 100;

interface IProps {
    historyRecordId: string;
}
const useGetHistoryRecords = ({ historyRecordId }: IProps) => {
    const { data: chatData, isLoading } = useGetChatHistoryRecordsQuery(
        {
            page: 1,
            limit: PAGE_LIMIT,
            history_id: historyRecordId,
        },
        { skip: !historyRecordId }
    );
    const chatRecords = chatData?.data;

    return {
        chatRecords,
        chatLoading: isLoading,
    };
};

export default useGetHistoryRecords;
