import { apiSlice } from "../../../services/api/apiSlice";
import { IGeneratePromptReq, IGeneratePromptRes } from "../types/interfaces";

export const generatePromptApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createAiPrompt: builder.mutation<IGeneratePromptRes, IGeneratePromptReq>({
            query: (reqData) => ({
                url: "/generate_prompt",
                method: "POST",
                body: { ...reqData },
            }),
        }),
    }),
});

export const { useCreateAiPromptMutation } = generatePromptApiSlice;
