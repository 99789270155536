import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import MButton from "../../../components/button/MButton";
import { FormInput } from "../../../components/form";
import { MAX_COUNT_SEND_SIGN_UP } from "../constants";
import useRegister from "../hooks/useRegister";
import styles from "./Register.module.scss";

const Register = () => {
    const { t } = useTranslation();

    const { methods, onSubmit, handleResendSignUpCode, submitConfirmCode, isWaitConfirmCode, quota } = useRegister();

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.registerForm}>
                    <div className={styles.signInArea}>
                        <div className={styles.inputBox}>
                            {isWaitConfirmCode ? (
                                <>
                                    <FormInput
                                        name='registerCode'
                                        label={t("COMMON.Confirm_Code")}
                                        className={styles.input}
                                        key='registerCode'
                                    />
                                    <div className={styles.psText}>{t("COMMON.Confirm_Code_Valid24")}</div>
                                    <div className={styles.resentBtn}>
                                        <MButton
                                            btnText={
                                                t("COMMON.Resend_Code") +
                                                `(${quota?.count || 0} / ${MAX_COUNT_SEND_SIGN_UP})`
                                            }
                                            onClick={handleResendSignUpCode}
                                            disabled={(quota?.count || 0) >= MAX_COUNT_SEND_SIGN_UP}
                                            type='button'
                                            color='secondary'
                                            fullWidth
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <FormInput
                                        name='email'
                                        label={t("COMMON.Email")}
                                        required={true}
                                        className={styles.input}
                                    />
                                    <FormInput
                                        name='password'
                                        label={t("COMMON.Password")}
                                        required={true}
                                        className={styles.input}
                                        type='password'
                                    />
                                    <FormInput
                                        name='okPassword'
                                        label={t("COMMON.Ok_Password")}
                                        required={true}
                                        className={styles.input}
                                        type='password'
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className={styles.actionBox}>
                        <div className={styles.submitBox}>
                            {isWaitConfirmCode ? (
                                <MButton
                                    btnText={t("COMMON.To_Complete_Register")}
                                    disabled={!methods.formState.isValid}
                                    onClick={submitConfirmCode}
                                    type='button'
                                />
                            ) : (
                                <div>
                                    <MButton
                                        btnText={t("COMMON.Register_Here")}
                                        disabled={!methods.formState.isValid}
                                        type='submit'
                                    />
                                </div>
                            )}
                        </div>

                        {/*<div className={styles.textLink}>
                            <p className={styles.psText}>{t("COMMON.Register_Agree")}</p>
                            <Link to={AMAZE_WEBSITE_URL}>{t("COMMON.Register_Service")}</Link>
                        </div>*/}
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default Register;
