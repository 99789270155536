import { ILinkTabProps } from "../../types/interfaces";
import classes from "./InnerTabs.module.scss";
import MainTabs from "./MainTabs";

interface IProps {
    currentTab: number;
    handleChange: (newValue: number) => void;
    tabRoutes: ILinkTabProps[];
    tabsOnClick: (tabRoute: ILinkTabProps) => void;
}
const InnerTabs = ({ currentTab, handleChange, tabRoutes, tabsOnClick }: IProps) => {
    return (
        <MainTabs
            currentTab={currentTab}
            handleChange={handleChange}
            tabRoutes={tabRoutes}
            tabsOnClick={tabsOnClick}
            className={classes.innerCustomTab}
        />
    );
};
export default InnerTabs;
