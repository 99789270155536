export enum SOCIAL_TYPES_old {
    none,
    discord,
    facebook,
    line,
    mattermost,
    telegram,
    web,
    kiosk,
    //whatsapp,
    autoTest = 101, // only for amaze 後台使用，其他的必須經過審核才能夠正常使用 amazeKey
}
export enum SOCIAL_TYPES {
    SMNone = 0,
    SMDisord = 1,
    Fb = 2,
    SMLine = 3,
    SMMattermost = 4,
    SMTelegram = 5,
    SMWeb = 6,
    SMKiosk = 7,
    SMWechat = 8,
    SMPhone = 9,
    SMAutoTest = 100, // 100(自動測試使用)
    SMAmazePlatformChat = 101, // 101(amaze後台使用)
}
