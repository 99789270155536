import { Outlet } from "react-router-dom";

import MainWrapper from "../../../../components/layout/MainWrapper";

const SalesLayout = () => {
    return (
        <MainWrapper>
            <Outlet />
        </MainWrapper>
    );
};
export default SalesLayout;
