import * as echarts from "echarts";
import { useEffect, useRef } from "react";

import { IAnalyzeState } from "../types";
// import useEchartResizer from "./useEchartResizer";

interface IProps {
    pieData: IAnalyzeState[] | undefined;
    category: string;
}

const usePieChartCircle = ({ category, pieData }: IProps) => {
    const MPieChart = useRef(null);
    // useEchartResizer({ ref: MPieChart });

    useEffect(() => {
        const pieChart = echarts.init(MPieChart.current);
        const option = {
            title: {
                text: category,
                left: "left",
            },
            grid: {
                top: "2%",
                left: "2%",
                right: "2%",
                bottom: "2%",
                containLabel: true,
            },
            color: ["#7D2148", "#41768C", "#F46424", "#87919A", "#B08BCD", "#769D85"],
            label: {
                fontSize: 16,
            },
            tooltip: {
                trigger: "item",
            },
            itemStyle: {
                borderRadius: 4,
            },
            series: [
                {
                    name: category,
                    startAngle: 90,
                    type: "pie",
                    radius: ["30%", "65%"],
                    data: pieData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 20,
                            shadowOffsetX: 0,
                            borderRadius: 5,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        };
        pieChart.setOption(option);
        return () => {
            pieChart.dispose();
        };
    }, [category, pieData]);

    return MPieChart;
};

export default usePieChartCircle;
