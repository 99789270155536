import { useGetOfficialLineBotQuery } from "../services/lineApiSlice";

interface IProps {
    projectId: string | undefined;
}
const useOfficialLineQuery = ({ projectId }: IProps) => {
    const { data, isLoading } = useGetOfficialLineBotQuery(
        {
            project_id: projectId as string,
        },
        { skip: !projectId }
    );
    return { officialLineBot: data?.data, isLoading };
};
export default useOfficialLineQuery;
