import { Alert, AlertProps } from "@mui/material";
import { ReactNode } from "react";

interface IProps extends AlertProps {
    children: ReactNode;
}

const MAlert = (props: IProps) => {
    const { children, ...otherProps } = props;
    return (
        <Alert severity='warning' {...otherProps}>
            {children}
        </Alert>
    );
};
export default MAlert;
