import { Divider, Drawer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectIsLogin } from "../../features/authentication/services";
import { ProjectList } from "../../features/projects/components/ProjectList";
import { INavItem } from "../../types/interfaces";
import classes from "./Sidebar.module.scss";

//import SidebarMenuItem from "./SidebarMenuItem"; // 有子項目的 sidebar menu item, 目前尚未使用到

interface IProps {
    drawerWidth: number;
    navItems: INavItem[];
    mobileOpen: boolean;
    handleDrawerToggle: () => void;
    handleVideoToggle: () => void;
    // sx:string;
}

const Sidebar = ({ navItems, drawerWidth, mobileOpen, handleDrawerToggle, handleVideoToggle }: IProps) => {
    const { t } = useTranslation();
    const isLogin = useSelector(selectIsLogin);

    const DrawerContent = () => {
        return (
            <div id='sidebar-firstChild' className={classes.drawerContent}>
                {/* <div className={classes.navWrapper}>
                    <List>
                        {navItems.map((listItem) => {
                            return <SidebarMenuItem listItem={listItem} key={listItem.href} />;
                        })}
                    </List>
                </div> */}
                {isLogin && (
                    <div className={classes.projectsNav}>
                        <div className={classes.navContent}>
                            <div className={classes.headingText}>{t("COMMON.MY_PROJECTS")}</div>
                            <Divider sx={{ mt: 1 }} />
                            <ProjectList handleVideoToggle={handleVideoToggle} />
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return (
        <>
            <Drawer
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: false,
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                    marginTop: { xs: "56px", md: "0" },
                }}
            >
                <DrawerContent />
            </Drawer>
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    display: { xs: "none", md: "block" },
                    marginTop: { xs: "0", md: "6rem" },
                }}
                open
            >
                <DrawerContent />
            </Drawer>
        </>
    );
};
export default Sidebar;
