import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import { HINT_A_LIMIT, HINT_Q_LIMIT } from "../../characters/constants";

export const HintsSchema = () => {
    const { t } = useTranslation();

    const schema = {
        hintQ: string()
            .required(`${t("COMMON.Required_Input")}`)
            .min(1)
            .max(HINT_Q_LIMIT, `${t("COMMON.Word_Limit")} ${HINT_Q_LIMIT.toString()} `),
        hintA: string().max(HINT_A_LIMIT, `${t("COMMON.Word_Limit")} ${HINT_A_LIMIT.toString()}`),
    };

    return object().shape(schema);
};
