import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, ButtonProps, DialogActions, Modal, ModalProps } from "@mui/material";
import clsx from "clsx";
import type { TFunction } from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { type FieldValues, FormProvider, type UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

// import useToggle from "../../hooks/useToggle";
import { formatTime } from "../../utils";
import MButton from "../button/MButton";
import styles from "./RefactorRegularModal.module.scss";

/**
 * - title, secondTitle 拉出去，結合 compound pattern 提升可讀性，減少不必要的判斷式
 * - titleContentAlign 類型的東西，既然會給上 center 當作預設值，何不直接在 title class 給上，後續如果有客製化需求，再另外傳 class 進來就好
 * - LoadingButton 一堆 props 可以直接引用 MUI
 * - Modal 再包一層，相關狀態全部以 RefactorRegularModal 往下傳 (正在做)
 * - Timer 獨立出來，包括相關邏輯
 * - 按鈕都獨立出來並分別提取各自的邏輯
 */

type BaseProps<T extends Record<string, string>> = {
    children: React.ReactNode;
    classes?: T;
};
type BaseBtnProps = ButtonProps & {
    text?: string;
    onClick?: () => void;
};
type TitleStyles = {
    title?: string;
};
type SubTitleStyles = TitleStyles & {
    /** 這裡可以放其他的客製化樣式 */
};
type ContentStyles = {
    content?: string;
};
type ActionsStyles = {
    wrapper?: string;
};
interface ICountdown {
    message?: string;
    seconds?: number;
}
interface IConfirmBtn extends BaseBtnProps {
    color: ButtonProps["color"];
    handleOpen?: boolean;
}

interface IProps {
    isForm?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modalMethod?: UseFormReturn<any>;
    children?: React.ReactNode;
}
interface IProvider {
    open: boolean;
    setOpen: (value?: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modalMethods: UseFormReturn<any>;
    isForm?: boolean;
    t: TFunction<"translation", undefined, "translation">;
    formIsValid: boolean;
    formIsDirty: boolean;
}
const RegularContext = createContext({} as IProvider);
const RefactorRegularModal = ({ modalMethod, children, isForm = false }: IProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const modalMethods = modalMethod || ({} as UseFormReturn<FieldValues>);
    const formIsDirty = modalMethods.formState ? modalMethods.formState.isDirty : false;
    const formIsValid = modalMethods.formState ? modalMethods.formState.isValid : false;

    const contextValue: IProvider = {
        open,
        setOpen: (value?: boolean) => {
            setOpen(value !== undefined ? value : (prev) => !prev);
        },
        modalMethods,
        isForm,
        t,
        formIsDirty,
        formIsValid,
    };

    return <RegularContext.Provider value={contextValue}>{children}</RegularContext.Provider>;
};
const Title = ({ children, classes }: BaseProps<TitleStyles>) => (
    <h3 className={clsx(styles.title, classes?.title)}>{children}</h3>
);
const SubTitle = ({ children, classes }: BaseProps<SubTitleStyles>) => {
    return <h4 className={clsx(styles.title, styles.subTitle, classes?.title)}>{children}</h4>;
};
const LoadingBtn = (props: LoadingButtonProps) => {
    const { setOpen } = useContext(RegularContext);
    return (
        <LoadingButton onClick={() => setOpen(true)} {...props} className={styles.loadingBtn}>
            {props.children}
        </LoadingButton>
    );
};
const Content = ({ children, classes }: BaseProps<ContentStyles>) => {
    return <div className={clsx(styles.contentWrapper, classes?.content)}>{children}</div>;
};
const Countdown = ({ message, seconds }: ICountdown) => {
    const [timer, setTimer] = useState<number>(seconds || 10);
    const { open, setOpen, t } = useContext(RegularContext);
    const { getSeconds, getMinutes } = formatTime(timer);
    const time = `${getMinutes()}:${getSeconds()}`;
    const countdownMsg = message ? message : "COMMON.AutoCloseIn";

    useEffect(() => {
        if (!open) return;
        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 0) {
                    clearInterval(countdown);
                    setTimer(seconds || 10);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
        /**
         * setOpen 放在 interval 的 setTimer 裡面會報錯，所以拿出來外面用
         * */
        if (timer <= 0) setOpen();

        return () => clearInterval(countdown);
    }, [open, setOpen, setTimer, seconds, timer]);

    return <p className={styles.countdownMessage}>{t(countdownMsg, { time })}</p>;
};
const Actions = ({ children, classes }: BaseProps<ActionsStyles>) => {
    return <DialogActions className={clsx(styles.actionWrapper, classes?.wrapper)}>{children}</DialogActions>;
};
const Cancel = ({ text, onClick }: BaseBtnProps) => {
    const { setOpen, isForm, modalMethods, t } = useContext(RegularContext);
    const handleClick = () => {
        if (isForm) {
            modalMethods.reset();
            onClick && onClick();
            return;
        }
        onClick && onClick();
    };

    return (
        <MButton
            handleClick={() => {
                setOpen(false);
                handleClick();
            }}
            color='info'
            type='button'
            btnText={text ? text : t("COMMON.Cancel")}
            className='cancelBtn'
        />
    );
};
const Confirm = ({ text, onClick, color, classes }: IConfirmBtn) => {
    const { setOpen, isForm, modalMethods, t, formIsDirty, formIsValid } = useContext(RegularContext);
    const confirmBtnDisabled = isForm ? !formIsDirty || !formIsValid : false;
    const handleClick = () => {
        if (isForm) {
            modalMethods.handleSubmit(() => {
                modalMethods.reset();
            })();
            onClick && onClick();
            return;
        }
        onClick && onClick();
    };

    return (
        <MButton
            handleClick={() => {
                setOpen(false);
                handleClick();
            }}
            disabled={confirmBtnDisabled}
            variant='contained'
            type='button'
            btnText={text ? text : t("COMMON.Confirm")}
            color={color}
            classes={{ root: classes?.root }}
            className='primaryBtn'
        />
    );
};

interface IModalProps extends Omit<ModalProps, "children"> {
    children?: React.ReactNode;
}
//目前用到RefactorRegularModal的地方很多暫時不去改外部結構，ModalProps的children是必填且要求單一元素，所以這邊新增IModalProps
const Container = ({ children, props }: { children?: React.ReactNode; props?: IModalProps }) => {
    const { open, setOpen, modalMethods, isForm } = useContext(RegularContext);

    return (
        <Modal open={open} onClose={() => setOpen(false)} {...props}>
            <div>
                <FormProvider {...modalMethods}>
                    <Box
                        component={isForm ? "form" : "div"}
                        noValidate
                        autoComplete='on'
                        margin={0}
                        className={styles.modal}
                    >
                        {children}
                    </Box>
                </FormProvider>
            </div>
        </Modal>
    );
};

RefactorRegularModal.Title = Title;
RefactorRegularModal.SubTitle = SubTitle;
RefactorRegularModal.LoadingBtn = LoadingBtn;
RefactorRegularModal.Container = Container;
RefactorRegularModal.Countdown = Countdown;
RefactorRegularModal.Content = Content;
RefactorRegularModal.Actions = Actions;
RefactorRegularModal.Cancel = Cancel;
RefactorRegularModal.Confirm = Confirm;

export default RefactorRegularModal;
