import { apiSlice } from "../../../services/api";
import { IApiIdReq, IGetResponse } from "../../../types/interfaces";
import {
    IAmazeKeyRes,
    IBindLineIDReq,
    ICreateLineAccReq,
    IGetOfficialLineBotsReq,
    IOfficialLineBot,
    IUpdateLineAccReq,
} from "../types/interfaces";

export const botSettingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // 確認是否綁定 line id
        getOfficialLineBot: builder.query<IGetResponse<IOfficialLineBot>, IGetOfficialLineBotsReq>({
            query: (params) => ({
                url: "/line_account",
                params,
            }),
            providesTags: ["LineAcc"],
        }),
        isBindLineID: builder.query<{ bind_line: boolean }, void>({
            query: () => ({
                url: "/is_bind_line_id",
            }),
            providesTags: ["IsBindLine"],
        }),
        // 綁定Line帳號
        bindLineID: builder.mutation<{ bind_line: boolean }, IBindLineIDReq>({
            query: (reqData) => ({
                url: "/bind_line_id",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["IsBindLine"],
        }),
        // 綁定line官方帳號
        createLineAcc: builder.mutation<IGetResponse<IOfficialLineBot>, ICreateLineAccReq>({
            query: (reqData) => ({
                url: "/create_line_account",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["LineAcc"],
        }),
        // delete 綁定line官方帳號
        delLineAcc: builder.mutation<IAmazeKeyRes, IApiIdReq>({
            query: (params) => ({
                url: "/delete_line_account",
                method: "DELETE",
                params,
            }),
            invalidatesTags: ["LineAcc"],
        }),
        // update 綁定line官方帳號
        updateLineAcc: builder.mutation<IAmazeKeyRes, IUpdateLineAccReq>({
            query: (reqData) => ({
                url: "/update_line_account",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["LineAcc"],
        }),
    }),
});

export const {
    useIsBindLineIDQuery,
    useBindLineIDMutation,
    useCreateLineAccMutation,
    useUpdateLineAccMutation,
    useDelLineAccMutation,
    useGetOfficialLineBotQuery,
} = botSettingsApiSlice;
