import { Dispatch } from "react";

import { useCreateAiPromptMutation } from "../services";

interface IProps {
    setPrompt: Dispatch<string>;
}
const useAiGeneratePrompt = ({ setPrompt }: IProps) => {
    const [createPrompt, { isLoading, error, ...rest }] = useCreateAiPromptMutation();

    const generatePrompt = async (rawText: string) => {
        try {
            const response = await createPrompt({ instruction: rawText }).unwrap();
            setPrompt(response.data);
        } catch (err) {
            //console.error("Error generating prompt:", err);
        }
    };
    return {
        generatePrompt,
        promptLoading: isLoading,
        error: error,
        ...rest,
    };
};

export default useAiGeneratePrompt;
