import React from "react";
import { Outlet } from "react-router-dom";

import { DateProvider } from "../features/analyze/context";
import { EnterprisePermissionControl } from "../features/authentication/components/permissionControl";
import { CharactersEditLayout } from "../features/knowledges/components/layout";

const CharacterPages = {
    CreateCharacter: React.lazy(() => import("../pages/characters/CreateCharacterPage")),
    CharacterInfo: React.lazy(() => import("../pages/characters/CharacterInfoPage")),
    CVoiceSetting: React.lazy(() => import("../pages/characters/CVoiceSettingPage")),
    ChatroomSetting: React.lazy(() => import("../pages/characters/ChatroomSettingPage")),
    CharacterSettings: React.lazy(() => import("../pages/characters/CharacterSettingsPage")),
    ChatHistory: React.lazy(() => import("../pages/characters/ChatHistoryPage")),
    Chatroom: React.lazy(() => import("../pages/characters/ChatroomPage")),
};

const BotSettingsPages = {
    App: React.lazy(() => import("../pages/botSettings/AppSettingsPage")),
    Fb: React.lazy(() => import("../pages/botSettings/FbSettingsPage")),
    Line: React.lazy(() => import("../pages/botSettings/LineSettingsPage")),
    WebWidget: React.lazy(() => import("../pages/botSettings/WebWidgetSettingsPage")),
};

const EditRoutes = [
    { path: "character-info", element: <CharacterPages.CharacterInfo /> },
    { path: "voice-setting", element: <CharacterPages.CVoiceSetting /> },
    { path: "chatroom-setting", element: <CharacterPages.ChatroomSetting /> },
    { path: "character-setting", element: <CharacterPages.CharacterSettings /> },
    {
        path: "character-chat-history",
        element: <DateProvider />,
        children: [{ path: "", element: <CharacterPages.ChatHistory /> }],
    },
];

const BotSettingsRoutes = [
    { path: "fb", element: <BotSettingsPages.Fb /> },
    { path: "line", element: <BotSettingsPages.Line /> },
    { path: "web-widget", element: <BotSettingsPages.WebWidget /> },
    { path: "app", element: <BotSettingsPages.App /> },
];
const withCharacterPermission = (Component: React.ReactNode) => (
    <EnterprisePermissionControl resource='character' action='view'>
        {Component}
    </EnterprisePermissionControl>
);

const CharacterRoutes = [
    {
        element: withCharacterPermission(<CharacterPages.CreateCharacter />),
        path: "create-character",
    },
    {
        path: ":characterId",
        element: withCharacterPermission(<CharactersEditLayout />),
        children: EditRoutes,
    },

    {
        path: ":characterId",
        children: [
            { path: "chatroom", element: <CharacterPages.Chatroom /> },
            {
                path: "bot-settings",
                element: withCharacterPermission(<Outlet />),
                children: BotSettingsRoutes,
            },
        ],
    },
];

export default CharacterRoutes;
