export const TRENDING_TOP_AMOUNT = 20;

export const MOCK_DEVICE = [
    "822d-0084ba83a473",
    "b510cb48-5c43-4394-8f25-92f13bd85826",
    "b510cb48-5c43-4394-8f25-92f13bd826",
    "113b48-5c43-4394-8f25-92f13bd85826",
    "83ed9cee-7f4e-4e0f-92c6-be113b61ad4b",
    "ef1365f8-6790-483c-bbd1-d3ac5b3813d8",
];
