import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import MButton from "../../../components/button/MButton";
import RegularContainer from "../../../components/container/RegularContainer";
import { FormInput } from "../../../components/form";
import { useResetPassword } from "../hooks";
import classes from "./ResetPassword.module.scss";

const ResetPassword = () => {
    const { t } = useTranslation();

    const { handleCancel, onResetPassword, isReadyResetPassword, onPreResetPassword, methods } = useResetPassword();

    const { isValid } = methods.formState;
    return (
        <>
            <RegularContainer>
                <RegularContainer.Heading title={t("ACCOUNT.Security") || ""} />
                <RegularContainer.Content>
                    {!isReadyResetPassword ? (
                        <MButton btnText={t("ACCOUNT.Password_Reset")} color='primary' onClick={onPreResetPassword} />
                    ) : (
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={methods.handleSubmit(onResetPassword)} className={classes.form}>
                                <FormInput name='newPassword' type='password' label={t("ACCOUNT.Type_Newpassword")} />
                                <FormInput name='okPassword' label={t("ACCOUNT.Type_NewPassword2")} type='password' />
                                <FormInput name='confirmationCode' label={t("COMMON.Confirm_Code")} required={true} />
                                <div className={classes.buttonGroup}>
                                    <MButton
                                        handleClick={handleCancel}
                                        btnText={t("COMMON.Cancel")}
                                        color='error'
                                        className={classes.cancelBtn}
                                    />
                                    <MButton btnText={t("COMMON.SUBMIT")} type='submit' disabled={!isValid} />
                                </div>
                            </form>
                        </FormProvider>
                    )}
                </RegularContainer.Content>
            </RegularContainer>
        </>
    );
};

export default ResetPassword;
