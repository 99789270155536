import { Skeleton, SkeletonProps } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";

import styles from "./AssetWithSkeleton.module.scss";

type ISkeletonVariant = SkeletonProps["variant"];
interface IClasses {
    loading?: string;
    img?: string;
    video?: string;
}

interface IProps {
    src: string;
    alt: string;
    classes?: IClasses;
    isVideo?: boolean;
    variant?: ISkeletonVariant;
}

const AssetWithSkeleton = ({ src, alt, classes, isVideo = false, variant = "rectangular" }: IProps) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isVideo) {
            const video = document.createElement("video");
            video.src = src;
            video.onloadeddata = () => {
                setLoading(false);
            };
        } else {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                setImageSrc(src);
                setLoading(false);
            };
        }
    }, [isVideo, src]);

    if (loading) {
        return (
            <Skeleton
                height='100%'
                variant={variant}
                classes={{ root: clsx(styles.loading, classes?.loading, { [styles.circle]: variant === "circular" }) }}
            />
        );
    }

    return isVideo ? (
        <video className={clsx(styles.video, classes?.video)} controls muted autoPlay playsInline>
            <source src={src} type='video/mp4' />
            <source src={src} type='video/ogg' />
        </video>
    ) : (
        <img
            loading='lazy'
            className={clsx(styles.img, classes?.img, { [styles.circle]: variant === "circular" })}
            src={imageSrc || src}
            alt={alt}
        />
    );
};

export default AssetWithSkeleton;
