import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./ChartsLayout.module.scss";

interface IProps {
    children: ReactNode;
    isRwdNone?: boolean;
    isLastMB0?: boolean;
}

const ChartsLayout = ({ children, isRwdNone, isLastMB0 }: IProps) => {
    return (
        <div
            className={clsx(
                styles.chartsLayout,
                { [styles.rwdDisplayNone]: isRwdNone },
                { [styles.lastMB]: isLastMB0 }
            )}
        >
            {children}
        </div>
    );
};
export default ChartsLayout;
