import { useIsProjectOwner } from "../../projects/hooks";
import { useGetCharacterBotsQuery } from "../services/botsApiSlice";
import { SOCIAL_TYPES } from "../types/enum";

interface IProps {
    projectId: string | undefined;
    characterId: string | undefined;
    socialType?: SOCIAL_TYPES;
}
const useBotQuery = ({ projectId, characterId, socialType }: IProps) => {
    // ==================== API ====================

    const isProjectOwner = useIsProjectOwner({ projectId });

    const { data, isLoading } = useGetCharacterBotsQuery(
        {
            page: 1,
            limit: 100,
            project_id: projectId as string,
            character_id: characterId as string,
            social_type: socialType as SOCIAL_TYPES,
        },
        { skip: !projectId || !characterId || !socialType }
    );

    return { botData: data?.data, botDataLoading: isLoading, isProjectOwner: isProjectOwner };
};
export default useBotQuery;
