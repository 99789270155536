import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { I18nextProvider } from "react-i18next";
import { Outlet, Route, Routes, useRoutes } from "react-router-dom";

import Error from "./components/error/Error";
import PrivateLayout from "./components/layout/PrivateLayout";
import ProjectLayout from "./components/layout/ProjectLayout";
import PageLoader from "./components/loader/PageLoader";
import NotifyContainer from "./components/notify/NotifyContainer";
import { VerifyLogin } from "./features/authentication/components";
import i18n from "./locales/i18n";
import DemoPermissionPage from "./pages/DemoPermissionPage";
import NotFound from "./pages/errorPages/NotFound";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CreateProjectPage from "./pages/projects/CreateProjectPage";
import InitProjectPage from "./pages/projects/InitProjectPage";
import RegisterPage from "./pages/RegisterPage";
import SalesLoginPage from "./pages/sales/SalesLoginPage";
import AccountRoutes from "./routes/AccountRoutes";
import ProjectRoutes from "./routes/ProjectRoutes";
import { SaleRoutes } from "./routes/sale";

const EnterpriseLoginPage = React.lazy(() => import("./pages/EnterpriseLoginPage"));
const TempLoginPage = React.lazy(() => import("./pages/TempLoginPage"));

const SuspenseLayout = () => (
    <Suspense fallback={<PageLoader loading={true} />}>
        <ErrorBoundary fallback={<Error />}>
            <Outlet />
        </ErrorBoundary>
    </Suspense>
);

const PrivateRoutes = () => {
    const routes = useRoutes([
        { element: <InitProjectPage />, path: "init-project" },
        { element: <CreateProjectPage />, path: "create-project" },
        {
            path: "projects",
            element: <ProjectLayout />,
            children: ProjectRoutes,
        },

        {
            path: "account",
            children: AccountRoutes,
        },
        { path: "*", element: <NotFound /> },
        { path: "sale", children: SaleRoutes },
        {
            path: "demo",
            element: <DemoPermissionPage />,
        },
    ]);
    return routes;
};

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <Routes>
                <Route element={<SuspenseLayout />}>
                    <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                    <Route path='/register' element={<RegisterPage />} />
                    <Route element={<VerifyLogin />}>
                        {/* public */}
                        <Route path='/sale/login' element={<SalesLoginPage />} />
                        <Route path='/' element={<HomePage />} />
                        <Route path='/temp-login' element={<TempLoginPage />} />
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/enterprise-login' element={<EnterpriseLoginPage />} />
                        {/* private */}
                        <Route element={<PrivateLayout />}>
                            <Route path='/*' element={<PrivateRoutes />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
            <NotifyContainer />
        </I18nextProvider>
    );
}

export default App;
