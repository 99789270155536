const getBarColor = (category: string) => {
    const mappingObj: Record<string, string> = {
        line: "#6EC48F",
        facebook: "#1785EC",
        web: "#DF6A53",
        kiosk: "#947EDF",
    };
    return mappingObj[category] ?? "#C7A182";
};

export default getBarColor;
