import { Add, Delete } from "@mui/icons-material";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import { Box, Button, IconButton } from "@mui/material";
import clsx from "clsx";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RefactorRegularModal } from "../../../../components/dialog";
import { FormInput, FormSelect, FormSlider } from "../../../../components/form";
import { FormCheckbox } from "../../../../components/form/checkbox";
import { PageLoader } from "../../../../components/loader";
import { PermissionControl } from "../../../authentication/components/permissionControl";
import { ButtonGroup } from "../../../records/components/buttonGroup";
import { LANGUAGE_OPTIONS, LANGUAGE_OPTIONS_AUTO } from "../../constants";
import { AUTO_CLOSE_MIC_SECONDS_RANGE_MARK } from "../../constants/constants";
import { useChatroomSetting } from "../../hooks";
import styles from "./ChatroomSetting.module.scss";
const ChatroomSetting = () => {
    const { t } = useTranslation();
    const {
        methods,
        urlFields,
        handleAddUrl,
        removeUrlField,
        handleCancel,
        handleFormSubmit,
        register,
        defaultValues,
        handleReset,
        isResetLoading,
        isMicManualControl,
        headerAvatarUrlDisabled,
    } = useChatroomSetting();
    const { isDirty, isValid, errors } = methods.formState;
    const imgUrlInputIsDirty = methods.formState.dirtyFields?.urlInput;
    const imgUrlInputDisabled = !imgUrlInputIsDirty || (imgUrlInputIsDirty && !!errors["urlInput"]);
    const { LoadingBtn, Title, Container, Actions, Cancel, Confirm } = RefactorRegularModal;
    return (
        <div>
            <PageLoader loading={false} />
            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='on'
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    margin={0}
                >
                    <div className={styles.formWrapper}>
                        {/* 通用設定 */}
                        <div className={styles.formWrapperCol}>
                            <h2 className='inlineTitle' style={{ marginTop: "0rem", marginBottom: "1rem" }}>
                                {t("CHATROOM.GENERl_SET")}
                            </h2>

                            <div className={styles.inputItem_start}>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.Color_SET")}</h4>
                                <div className={styles.inputColor}>
                                    <input
                                        type='color'
                                        id='primary'
                                        defaultValue={defaultValues.primary}
                                        {...register("primary")}
                                    />
                                </div>
                            </div>

                            <FormSelect
                                variant='outlined'
                                name='language'
                                options={LANGUAGE_OPTIONS_AUTO}
                                label={t("CHATROOM.Language") || "Language"}
                            />
                            {/* openVoiceInput, voice_enabled, openLanguageSelector 暫時不開放給 user 隨意更改 */}
                            <PermissionControl resource='chatWidgetSetting' action='update'>
                                <div className={styles.inputItem_between}>
                                    <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.VOICE_OPEN")}</h4>
                                    <FormCheckbox name='openVoiceInput' checked={defaultValues.openVoiceInput} />
                                </div>
                            </PermissionControl>
                            <PermissionControl resource='chatWidgetSetting' action='update'>
                                <div className={styles.inputItem_between}>
                                    <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.VOICE_RES")}</h4>
                                    <FormCheckbox name='voice_enabled' checked={defaultValues.voice_enabled} />
                                </div>
                            </PermissionControl>
                            <PermissionControl resource='chatWidgetSetting' action='update'>
                                <div className={styles.inputItem_between}>
                                    <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.LANGUAGE_OPEN")}</h4>
                                    <FormCheckbox
                                        name='openLanguageSelector'
                                        checked={defaultValues.openLanguageSelector}
                                    />
                                </div>
                                {methods.watch("openLanguageSelector") && (
                                    <FormSelect
                                        variant='outlined'
                                        name='languageOptions'
                                        options={LANGUAGE_OPTIONS}
                                        SelectProps={{ multiple: true }}
                                        label={t("CHATROOM.Language_Options") || "Language"}
                                    />
                                )}
                            </PermissionControl>
                            <div className={styles.inputItem_between}>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.AutoFocus")}</h4>
                                <FormCheckbox name='autoFocus' checked={defaultValues.autoFocus} />
                            </div>
                            <FormInput
                                variant='outlined'
                                name='inputPlaceholder'
                                required={false}
                                // placeholder={t("CHATROOM.InputPlaceholder") || "輸入訊息..."}
                                label={t("CHATROOM.InputPlaceholder") || "輸入訊息..."}
                            />

                            <div className={styles.inputItem_between}>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.HEADER_OPEN")}</h4>
                                <FormCheckbox name='showHeader' checked={defaultValues.showHeader} />
                            </div>

                            <FormInput
                                variant='outlined'
                                name='headerTitle'
                                required={false}
                                label={t("CHATROOM.HEADER_TITLE") || "HEADER_TITLE"}
                            />
                            <FormInput
                                variant='outlined'
                                name='headerSubtitle'
                                required={false}
                                label={t("CHATROOM.HEADER_SUBTITLE") || "HEADER_SUBTITLE"}
                            />
                            <div className={styles.urlArrFields}>
                                <FormInput
                                    variant='outlined'
                                    label={t("CHATROOM.GREETING_IMG_URL") || "CHATROOM.GREETING_IMG_URL"}
                                    name='urlInput'
                                    required={false}
                                />
                                <Button
                                    className={clsx(styles.addBtn, { [styles.disabled]: imgUrlInputDisabled })}
                                    variant='contained'
                                    startIcon={<Add />}
                                    onClick={handleAddUrl}
                                    disabled={imgUrlInputDisabled}
                                >
                                    {t("COMMON.New")}
                                </Button>
                                <ul className={styles.urlsContainer}>
                                    {urlFields.map((field, index) => (
                                        <li key={field.url} className={styles.urlBox}>
                                            <p className={styles.url}>{field.url}</p>
                                            <div className={styles.deleteBtnBox}>
                                                <IconButton
                                                    className={styles.deleteBtn}
                                                    onClick={() => removeUrlField(index)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className={styles.inputItem_between}>
                                    <FormSelect
                                        variant='outlined'
                                        name='feedback_type'
                                        options={[
                                            { value: "reaction", label: t("CHATROOM.Feedback_Like_Dislike") + "👍" },
                                            { value: "rating", label: t("CHATROOM.Feedback_Ratings") + "⭐️" },
                                        ]}
                                        label={t("CHATROOM.Feedback_Type") ?? ""}
                                    />
                                </div>
                            </div>
                            <div className={styles.inputItem_between}>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.Show_Header_Avatar")}</h4>
                                <FormCheckbox name='showHeaderAvatar' checked={defaultValues.showHeaderAvatar} />
                            </div>
                            <FormInput
                                variant='outlined'
                                name='headerAvatarUrl'
                                label={t("CHATROOM.Header_Avatar_Url") || "Header_Avatar_URL"}
                                required={false}
                                disabled={headerAvatarUrlDisabled}
                            />
                            <div className={styles.inputItem_between}>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.Mic_Manual_Control")}</h4>
                                <FormCheckbox name='isMicManualControl' checked={defaultValues.isMicManualControl} />
                            </div>
                            <div>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.Auto_Close_Mic_Seconds")}</h4>
                                <Box paddingX={2}>
                                    <FormSlider
                                        min={5}
                                        max={30}
                                        step={1}
                                        defaultValue={5}
                                        marks={AUTO_CLOSE_MIC_SECONDS_RANGE_MARK}
                                        name='autoCloseMicSeconds'
                                        disabled={isMicManualControl}
                                        valueLabelDisplay='auto'
                                    />
                                </Box>
                            </div>
                        </div>
                        {/* 各版本設定 */}
                        <div className={styles.formWrapperCol}>
                            {/* mobileAvatarUrl改從角色 api 拿 */}

                            <h3 style={{ fontSize: "1.8rem", marginTop: "0rem", marginBottom: "1rem" }}>
                                {t("CHATROOM.Mobile_SET")}
                            </h3>
                            <div className={styles.inputItem_between}>
                                <h4 style={{ marginBlock: ".2rem" }}>{t("CHATROOM.ShowMobileAvatar")}</h4>
                                <FormCheckbox name='showMobileAvatar' checked={defaultValues.showMobileAvatar} />
                            </div>
                            {/* <FormInput
                                variant='outlined'
                                name='mobileAvatarUrl'
                                label={t("CHATROOM.Mobile_IMG_URL") || "Mobile_IMG_URL"}
                                required={false}
                            /> */}
                            <h3 style={{ fontSize: "1.8rem", marginTop: "0rem", marginBottom: "1rem" }}>
                                {t("CHATROOM.Messenger_SET")}
                            </h3>
                            <FormInput
                                variant='outlined'
                                name='popupIconUrl'
                                label={t("CHATROOM.Messenger_BUTTON_URL") || "Messenger_BUTTON_URL"}
                                required={false}
                            />
                            <h3 style={{ fontSize: "1.8rem", marginTop: "0rem", marginBottom: "1rem" }}>
                                {t("CHATROOM.Avatar_SET")}
                            </h3>
                            <FormInput
                                variant='outlined'
                                name='idleAvatarURL'
                                label={t("CHATROOM.Avatar_BTN_URL") || "Avatar_BTN_URL"}
                                required={false}
                            />
                            <FormInput
                                variant='outlined'
                                name='mobileAvatarImgUrl'
                                label={t("CHATROOM.Avatar_SM_BTN_URL") || "Avatar_SM_BTN_URL"}
                                required={false}
                            />
                            <FormInput
                                variant='outlined'
                                name='talkingAvatarURL'
                                label={t("CHATROOM.Avatar_TALKING_URL") || "Avatar_TALKING_URL"}
                                required={false}
                            />
                        </div>
                    </div>

                    <div className={styles.btnSection}>
                        <div>
                            <RefactorRegularModal>
                                <LoadingBtn
                                    startIcon={<RotateLeftRoundedIcon />}
                                    classes={{ root: styles.resetBtn }}
                                    color='secondary'
                                    loading={isResetLoading}
                                    variant='contained'
                                >
                                    {t("CHATROOM.RESET_FORM")}
                                </LoadingBtn>
                                <Container>
                                    <Title>{t("CHATROOM.RESET_FORM_INFO")}</Title>
                                    <Actions>
                                        <Cancel />
                                        <Confirm color='primary' onClick={handleReset} />
                                    </Actions>
                                </Container>
                            </RefactorRegularModal>
                        </div>
                        <ButtonGroup
                            submitDisabled={!isValid || !isDirty}
                            cancelDisabled={!isDirty}
                            handleCancel={handleCancel}
                        />
                    </div>
                </Box>
            </FormProvider>
            {/* <DevTool control={methods.control} /> */}
        </div>
    );
};

export default ChatroomSetting;
