import { configureStore } from "@reduxjs/toolkit";

import { authReducer, tokenMiddleware } from "../features/authentication/services";
import { langReducer } from "../features/langs/services";
import { apiSlice, rtkQueryErrorMiddleware } from "./api/apiSlice";
import { listenerMiddleware } from "./middlewares/listenerMidderwares";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        lang: langReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            apiSlice.middleware,
            tokenMiddleware,
            rtkQueryErrorMiddleware,
            listenerMiddleware.middleware
        ),
    devTools: process.env.NODE_ENV === "development", // should false for staging and prod
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
