import { apiSlice } from "../../../services/api/apiSlice";
import {
    IChatWidgetSettingReq,
    IChatWidgetSettingRes,
    IUpdateChatWidgetSettingReq,
    IUpdateChatWidgetSettingRes,
} from "../types/interfaces";

export const chatWidgetApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getChatWidgetSetting: builder.query<IChatWidgetSettingRes, IChatWidgetSettingReq>({
            query: (reqData) => ({
                url: "/character/chat_widget_setting",
                method: "GET",
                params: { ...reqData },
            }),
            providesTags: ["ChatWidgetSetting"],
        }),
        updateChatWidgetSetting: builder.mutation<IUpdateChatWidgetSettingRes, IUpdateChatWidgetSettingReq>({
            query: (reqData) => ({
                url: "/character/update_chat_widget_setting",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["ChatWidgetSetting"],
        }),
    }),
});

export const { useGetChatWidgetSettingQuery, useUpdateChatWidgetSettingMutation } = chatWidgetApiSlice;
