import { isProduction } from "../../../utils";
import LoginForm from "./LoginForm";
import QuickLogin from "./QuickLogin";

const Login = () => {
    return (
        <div>
            <LoginForm />
            {!isProduction && <QuickLogin />}
        </div>
    );
};
export default Login;
