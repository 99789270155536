import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import avatar from "../../assets/icons/account-circle.svg";
import { postLogout, selectCurrentUser } from "../../features/authentication/services";
import { useAmzLogoutMutation, useSalesLogoutMutation } from "../../features/authentication/services/authApiSlice";
import { checkEnterprisePermission } from "../../features/authentication/utils";
import { awsSignOut } from "../../lib/aws/awsAuth";
import { apiSlice } from "../../services/api/apiSlice";
import { isProduction } from "../../utils";
import styles from "./UserNav.module.scss";
const prodUserMenuItems = [
    { href: "/account/settings", name: "ACCOUNT.Account_Info", icon: null },
    {
        href: "/account/billing/usage",
        name: "ACCOUNT.My_Account",
        icon: null,
    },
];
const userMenuItems: { href: string; name: string; icon: ReactNode }[] = [
    // { href: "/account/billing", name: "Billing Overview", icon: null }, // closed temporarily
    //{ href: "/account/reset-password", name: "Reset Password", icon: null }, // closed temporarily
];

const currentMenuItems = isProduction ? prodUserMenuItems : [...prodUserMenuItems, ...userMenuItems];
const UserNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currentUser = useSelector(selectCurrentUser);
    const isEnterpriseUser = currentUser?.enterpriseAccountId;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [fetchAmzLogout] = useAmzLogoutMutation();
    const [fetchSalesLogout] = useSalesLogoutMutation();
    const { pathname } = useLocation();
    const isSalesLogin = pathname.split("/")[1] === "sale";

    const handleToggle = async (href: string) => {
        setIsOpen((prev) => !prev);
        if (href === "logout") {
            if (isSalesLogin) {
                await fetchSalesLogout().unwrap();
            }
            await awsSignOut();
            dispatch(postLogout());
            fetchAmzLogout();
            dispatch(apiSlice.util.resetApiState());
            return;
        }
        navigate(href);
    };
    const filteredCurrentMenuItems = currentMenuItems.filter((item) => {
        if (isEnterpriseUser && item.href === "/account/billing/usage") {
            return checkEnterprisePermission(currentUser?.enterpriseRoles, "billing", "view");
        }
        return true;
    });
    return (
        <>
            <img className={styles.avatar} src={avatar} alt='avatar' onClick={() => setIsOpen((prev) => !prev)} />
            {isOpen && (
                <>
                    <ul className={styles.avatarMenu}>
                        <li className={styles.item}>{isEnterpriseUser || currentUser?.email || ""}</li>
                        {/* TODO: style and i18n */}
                        <li className={styles.item}>{currentUser?.role}</li>
                        {filteredCurrentMenuItems.map((item, idx) => (
                            <li className={styles.item} key={item.href} onClick={() => handleToggle(item.href)}>
                                {item.icon && item.icon}
                                <p style={{ margin: 0 }}>{t(item.name)}</p>
                            </li>
                        ))}
                        <li className={styles.item} onClick={() => handleToggle("logout")}>
                            {t("COMMON.Logout")}
                        </li>
                    </ul>
                    <div className={styles.closeMenu} onClick={() => setIsOpen((prev) => !prev)}></div>
                </>
            )}
        </>
    );
};
export default UserNav;
