import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services";
import type {
    IGetAllOrgLangsReq,
    IGetAllOrgLangsRes,
    IGetItemReq,
    IGetItemRes,
    IUpdateItemsOrderReq,
    IUpdateItemsOrderRes,
} from "../types/interfaces";

const itemApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategoryItems: builder.query<IGetItemRes, IGetItemReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/categories/${reqBody.category_id}/items`,
                method: "GET",
            }),
            providesTags: ["SalesItems"],
        }),
        getAllOrgLangs: builder.query<IGetAllOrgLangsRes, IGetAllOrgLangsReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/org/${reqBody.org_id}/languages`,
                method: "GET",
            }),
        }),
        updateItemsOrder: builder.mutation<IUpdateItemsOrderRes<null>, IUpdateItemsOrderReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/categories/${reqBody.category_id}/items_order`,
                method: "PUT",
                body: { new_order: reqBody.order },
            }),
            invalidatesTags: ["SalesItems"],
        }),
    }),
});
export const { useGetCategoryItemsQuery, useGetAllOrgLangsQuery, useUpdateItemsOrderMutation } = itemApiSlice;
