import { Link } from "react-router-dom";

interface IProps {
    logo: string;
}

const LogoBtn = ({ logo }: IProps) => {
    return (
        <Link to={"/login"}>
            <img src={logo} alt='logo' />
        </Link>
    );
};

export default LogoBtn;
