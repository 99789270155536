import styles from "./BouncingDots.module.scss";

const BouncingDots = () => {
    return (
        <div className={styles.bouncingLoader}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
export default BouncingDots;
