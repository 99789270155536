import { Check, Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import styles from "./ButtonGroup.module.scss";

interface IProps {
    cancelDisabled: boolean;
    submitDisabled: boolean;
    handleCancel: () => void;
}
const ButtonGroup = ({ cancelDisabled, submitDisabled, handleCancel }: IProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.buttonsBox}>
            <Button
                disabled={cancelDisabled}
                variant='contained'
                className={styles.cancelBtn}
                startIcon={<Close />}
                onClick={handleCancel}
                color='inherit'
            >
                {t("COMMON.Cancel")}
            </Button>
            <Button
                disabled={submitDisabled}
                variant='contained'
                className={styles.submitBtn}
                startIcon={<Check />}
                type='submit'
                color='info'
            >
                {t("COMMON.SAVE")}
            </Button>
        </div>
    );
};
export default ButtonGroup;
