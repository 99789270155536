import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services/api/apiSlice";
import { IGetResponse } from "../../../types/interfaces";
import {
    IAmazeSignInReq,
    IAmazeSignInRes,
    IApiLoginReq,
    IEnterpriseLoginReq,
    IEnterpriseLoginRes,
    ISalesLoginRes,
    IUserStatusRes,
} from "../types/interfaces";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //! deprecated
        googleSignUp: builder.mutation({
            query: (credential: IApiLoginReq) => ({
                url: "/google_signup",
                method: "POST",
                body: { ...credential },
            }),

            invalidatesTags: ["Auth"],
        }),
        //! deprecated
        facebookSignUp: builder.mutation({
            query: (credential: IApiLoginReq) => ({
                url: "/fb_signup",
                method: "POST",
                body: { ...credential },
            }),
            invalidatesTags: ["Auth"],
        }),
        checkUserStatus: builder.mutation<IUserStatusRes, void>({
            query: () => ({
                url: "/check_user_status",
                method: "POST",
            }),
            invalidatesTags: ["Auth"],
        }),
        amazeSignIn: builder.mutation<IAmazeSignInRes, IAmazeSignInReq>({
            query: (credential: IAmazeSignInReq) => ({
                url: "/signin",
                method: "POST",
                body: { ...credential },
            }),
            invalidatesTags: ["Auth"],
        }),
        enterpriseLogin: builder.mutation<IGetResponse<IEnterpriseLoginRes>, IEnterpriseLoginReq>({
            query: (credential) => ({
                url: "/enterprise/login",
                method: "POST",
                body: { ...credential },
            }),
            invalidatesTags: ["Auth"],
        }),
        amzLogout: builder.mutation<IGetResponse<IEnterpriseLoginRes>, void>({
            query: () => ({
                url: "/logout",
                method: "POST",
            }),
            invalidatesTags: ["Auth"],
        }),
        salesLogout: builder.mutation<IGetResponse<ISalesLoginRes>, void>({
            query: () => ({
                url: `${SALE_API_URL}/panel_logout`,
                method: "POST",
            }),
            invalidatesTags: ["Auth"],
        }),
    }),
});

export const {
    useGoogleSignUpMutation,
    useFacebookSignUpMutation,
    useCheckUserStatusMutation,
    useAmazeSignInMutation,
    useEnterpriseLoginMutation,
    useAmzLogoutMutation,
    useSalesLogoutMutation,
} = authApiSlice;
