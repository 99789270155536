import { useCreateBotMutation, useDeleteBotMutation } from "../services/botsApiSlice";
import { ICreateBotInput } from "../types/interfaces";

const useBotMutation = () => {
    // ==================== API ====================
    const [createBot, { isLoading: createLoading, isSuccess: createSuccess, error }] = useCreateBotMutation();
    const [deleteBot, { isLoading: delLoading, isSuccess: delSuccess }] = useDeleteBotMutation();

    // ==================== HANDLER ====================

    const handleCreate = async (data: ICreateBotInput) => await createBot(data);
    const handleDelete = async (id: string) => await deleteBot({ id });
    return { handleCreate, createLoading, createSuccess, error, handleDelete, delLoading, delSuccess };
};
export default useBotMutation;
