import { ILang } from "../features/langs/types";

// IWidgetBaseConfig, IWidgetUIConfig, IMessengerConfig, IMobileConfig, IAvatarConfig should followed by chat widget project
export interface IWidgetBaseConfig {
    amaze_key: string; //api
    chatApiUrl: string; // api
    voice_enabled: boolean;
    language: string; //語言，預設是AUTO
    openVoiceInput: boolean;
    openLanguageSelector: boolean;
    hintLanguage?: string;
    isMicManualControl: boolean;
    languageOptions: ILang[];
    feedbackType: string;
}

export interface IWidgetUIConfig {
    styleOverrides: IStylesOverrides;
    greetingPrompt: string; //api
    imagesUrl: string[];
    autoFocus: boolean;
    inputPlaceholder: string;
}

export interface IMessengerConfig {
    showHeader: boolean; // messenger、avatar都有，mobile沒有
    headerTitle: string;
    headerSubtitle: string;
    popupIconUrl: string; // toggle button 裡面的圖像
    //popupWrapperClass: string;
}
// > styleOverride 可以參考文件 https://redmine.thebarkingdog.tw/projects/chat-widget/wiki/IWidgetUIConfig/#CSS_VARIABLES
export interface IStylesOverrides {
    [key: string]: string;
}
export interface IMobileConfig {
    mobileAvatarUrl: string;
    showMobileAvatar: boolean;
}
export interface IAvatarConfig {
    idleAvatarURL: string;
    talkingAvatarURL: string;
    mobileAvatarImgUrl: string;
}

export interface IChatWidgetConfig {
    base: Partial<IWidgetBaseConfig> & Pick<IWidgetBaseConfig, "amaze_key">;
    ui?: IWidgetUIConfig;
    messenger?: IMessengerConfig;
    mobile?: IMobileConfig;
    avatar?: IAvatarConfig;
    selectorId?: string;
}

//export type ChatWidgetConfigRawData = Partial<IChatWidgetSetting> & IWidgetConfigFromAPP;
export type ChatWidgetRawData = Pick<IWidgetBaseConfig, "amaze_key" | "chatApiUrl"> &
    Partial<
        Omit<
            IWidgetBaseConfig & IWidgetUIConfig & IMessengerConfig & IMobileConfig & IAvatarConfig,
            "amaze_key" | "chatApiUrl"
        >
    >;

export const DEFAULT_SHOW_HEADER = true;
export const DEFAULT_IDLE_AVATAR_URL = "https://asset.ai-amaze.com/eve/EVE_Idle.webm";
export const DEFAULT_TALKING_AVATAR_URL = "https://asset.ai-amaze.com/eve/EVE_talking.webm";
export const DEFAULT_MESSENGER_POPUP_ICON =
    "https://cdn-staging.ai-amaze.com/w/img/a7000171-f640-4fde-9b5f-881b5d1acf42.png";
export const DEFAULT_MOBILE_IMG_URL = "https://cdn.ai-amaze.com/w/img/ac1910b3-5741-4949-8d08-a1c66662f85e.png";
export const DEFAULT_INPUT_PLACEHOLDER = "輸入訊息...";
