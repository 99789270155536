import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GoogleLoginButton } from "react-social-login-buttons";

import { isDev } from "../../../utils";
import { useQuickLogin } from "../hooks";
import styles from "./QuickLogin.module.scss";
import ToLoginSaleBtn from "./ToLoginSaleBtn";

const QuickLogin = () => {
    const { t } = useTranslation();
    const { handleSocialLogin } = useQuickLogin();

    return (
        <div className={styles.quickLogin}>
            <div className={styles.titleQuickLoginContainer}>
                <span />
                <div className={styles.titleQuickLogin}>{t("COMMON.Quick_Login")}</div>
                <span />
            </div>

            <Grid container className={styles.btnQuickLoginContainer}>
                <Grid className={styles.btnQuickLogin}>
                    <GoogleLoginButton
                        text=''
                        style={{ width: "4.5rem", height: "4.5rem", display: "inline", textAlign: "center" }}
                        onClick={() => handleSocialLogin("Google")}
                    />
                </Grid>
                {isDev && (
                    <Grid className={styles.btnQuickLogin}>
                        <ToLoginSaleBtn />
                    </Grid>
                )}

                {/* Not yet publish */}
                {/*<Grid className={styles.btnQuickLogin}>
                    <FacebookLoginButton
                        text=''
                        style={{ width: "4.5rem", height: "4.5rem", display: "inline", textAlign: "center" }}
                        // onClick={() => handleSocialLogin(SIGN_IN_TYPE.FB)}
                    />
                </Grid>*/}
            </Grid>
        </div>
    );
};

export default QuickLogin;
