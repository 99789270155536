import { useBindLineIDMutation, useIsBindLineIDQuery } from "../services/lineApiSlice";

const useBindLine = () => {
    // ==================== API ====================
    const [postBindLine, { isLoading: bindLoading }] = useBindLineIDMutation();
    const { data, isSuccess, isLoading } = useIsBindLineIDQuery();

    return {
        postBindLine,
        bindLoading,
        isBindLine: data?.bind_line,
        isBindLineLoading: isLoading,
        isBindLineSuccess: isSuccess,
    };
};
export default useBindLine;
